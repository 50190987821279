.identification {
  &__ident {
    thead {
      .text-start:nth-child(2) {
        padding-left: 20px !important;
      }
    }

    tbody td:nth-child(2) {
      padding-left: 20px !important;
    }

    .btn-add-row {
      padding-left: 63px !important;
      padding-right: 57px !important;
    }
  }

  .v-data-table {
    tbody tr {
      cursor: default;

      td {
        min-width: unset;
        padding: 6px 8px;

        &:first-child {
          padding-right: 0;
        }

        @media screen and (max-width: 960px) {
          &:not(:first-child):not(:last-child) {
            min-width: 150px;
          }
        }

        .v-data-table__icon-move {
          margin: 0 !important;
        }
      }
    }

    thead {
      .text-start {
        padding-left: 8px;

        &[aria-label="actions"] {
          min-width: 50px;
        }
      }

      [aria-label="sortable"]:before {
        left: 13px;
      }

    }

    .btn-add-row {
      padding-left: calc(5% + 0.5rem);
      padding-right: calc(5% + .5rem);
    }
  }

  .v-menu__content {
    margin-top: 10px;

    &.v-menu--top {
      margin-top: -15px;
    }
  }

  div[role='dialog'] {
    .v-dialog {
      width: auto;

      .v-date-picker-header {
        button {
          width: auto;
        }
      }

      .v-picker__actions {
        button {
          width: auto;
        }
      }
    }
  }
}