.v-chip {
  background-color: $light-blue;
  color: $primary;
  padding: 1px 8px;
  height: auto;
  text-transform: capitalize;
  font-size: $font-size-sm;

  &__content {
    line-height: 1.5;
  }
}
