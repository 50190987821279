$breakpoints: "xs", "sm", "md", "lg", "xl", "2xl", "3xl";

@mixin generate-columns($size) {
  margin: -$size + px;
  .col {
    padding: $size + px;
  }

  @for $i from 1 through length($breakpoints) {
    @for $j from 1 through 12 {
      // Vuetify comes with a 12 point grid system.
      .col-#{$j},
      .col-#{nth($breakpoints, $i)}-#{$j} {
        padding: $size + px;
      }
    }
  }
}

.row {
  &--sm {
    @include generate-columns(6);
  }

  &--xs {
    @include generate-columns(4);
  }
}

.g {
  &c {
    &-1 {
      column-gap: 0.25rem;
    }
    &-2 {
      column-gap: 0.5rem;
    }
    &-3 {
      column-gap: 0.75rem;
    }
    &-4 {
      column-gap: 1rem;
    }
  }
  &r{
    &-6 {
      row-gap: 1.5rem;
    }
  }
}
