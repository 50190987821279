.v-picker{
  padding: unset;
  &--date{
    box-shadow: none;
    .v-btn{
      min-width: unset;
    }
    .v-date-picker-table{
      height: auto;
      padding: 0;
    }
  }
}