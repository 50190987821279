.export-file {
  // background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 640px;
  padding: 0 41px;

  &__icon {
    .v-icon {
        font-size: 112px;
        color: $primary;
        opacity: 0.1;
    }
  }

  &__title {
    color: $grey-arsenic;
    font-size: $font-size-subtitle;
    font-weight: $font-semibold;
  }

  &__description {
    color: $grey;
    font-size: $font-size-root;
  }
}
