#settings {
  .settings {
    &__list {
      &__content {
        &--row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media screen and (max-width: 960px) {
            flex-direction: column;
            gap: 13px;
          }
        }

        &__title,
        &__form {
          width: 100%;
          .v-btn.v-size--default {
            padding-left: 19px;
            padding-right: 19px;
            letter-spacing: 0.035px;
            @media screen and (max-width: 960px) {
              width: 100%;
            }
          }
        }

        &__title {
          &--title {
            color: $eerie-black;
          }

          &--subtitle {
            font-size: $font-size-sm;
            color: $grey;
            max-width: 250px;
          }
        }

        .v-input {
          input {
            color: $eerie-black;
          }

          .v-text-field__details {
            display: none;
          }

          &__slot {
            margin: 0;
          }

          &--radio-group {
            margin: 0;

            .v-messages {
              display: none;
            }
          }
        }

        &--grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 13px;
        }

        .item-border {
          padding: 12px 16px;
        }

        &__part-title {
          font-size: 20px;
          line-height: 170%; /* 34px */
          font-weight: 500;
          color: $eerie-black;
          max-width: 420px;
        }
      }
      .site-login-settings {
        @media screen and (max-width: 960px) {
          .settings__list__content--grid {
            display: flex;
            flex-direction: column;
            gap: 13px;
            .v-input--selection-controls__input {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }

  .translations {
    .page-header {
      @media screen and (max-width: 960px) {
        top: 50px;
      }
    }
    .v-card {
      padding: 16px;
      // min-height: 577px;
    }
    &__file-information {
      font-size: $font-size-sm;
      line-height: 125%; /* 15px */
      &--text {
        margin: 1px 0 2px 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-self: baseline;
      }
    }
    &__content {
      .v-data-table {
        padding: 0;
        thead {
          th {
            padding: 0 16px 10px;
          }
        }
      }
    }
    &__list {
      .v-data-table {
        padding: 16px;

        &--title {
          color: $eerie-black;
          font-size: 20px;
          font-weight: 500;
          line-height: 170%; /* 34 px */
          letter-spacing: 0.05px;
        }

        thead {
          display: none;
        }

        &--item {
          &__language {
            color: $black;
          }
        }

        td:last-child {
          width: 50px;
        }
      }
    }

    .v-icon {
      font-size: 18px;
      color: $primary;
      transition: none !important;
    }

    &__no-data {
      &--icon {
        width: 108px;
        height: 108px;

        .v-icon {
          font-size: 108px;
          opacity: 0.1;
        }
      }

      &--title {
        font-size: 24px;
        color: $eerie-black;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0.06px;
      }

      &--subtitle {
        font-size: $font-size-root;
        color: $grey;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .v-data-table {
      box-shadow: none;
      thead {
        th:nth-child(3) {
          min-width: 250px;
        }
      }

      tbody {
        tr:first-child {
          td {
            padding-top: 24px;
          }
        }
        tr:last-child {
          td {
            padding-bottom: 24px;
          }
        }
        tr {
          margin: 12px;
          td {
            padding: 12px 16px 11px;
            vertical-align: initial;
            color: $eerie-black;
          }

          &:hover {
            background-color: transparent;
            cursor: default;
          }
        }
      }

      .v-input {
        .v-text-field__details {
          display: none;
        }

        &__slot {
          margin: 0;
        }
      }

      &--title {
        width: 315px;
      }

      &--translation {
        width: 365px;
      }
    }

    .translation-category-selector {
      display: none;
    }
  }

  .v-expansion-panel {
    &-header {
      padding: 16px;
      color: $eerie-black;
      line-height: 24px;
      &--active {
        padding: 0 16px;
      }
    }
    &-content {
      &__wrap {
        margin: 0 16px 16px;
        @media screen and (max-width: 599px) {
          margin: 0;
        }
        .item-border {
          padding: 13px 16px;
        }
        .v-input--selection-controls {
          padding-top: 0;
        }
      }
    }
    &--active>.v-expansion-panel-header {
      min-height: 49px;
      padding-top: 16px;
      padding-bottom: 9px;
    }
  }

  // TODO: @benjamin can you check if it's possible make this global?
  .v-card--app-filter {
    border-radius: 0 8px 8px 0 !important;
    width: 240px !important;
    min-width: 240px;
    padding: 0 !important;
    overflow: hidden;

    &__title {
      color: $eerie-black;
      font-weight: $font-semibold;
      margin: 10px 0 8px 0;
    }

    .v-select {
      height: 40px;

      .v-messages,
      .v-text-field__details {
        display: none;
      }

      .v-input__control,
      .v-input__control button {
        max-height: 40px;
        height: 40px;
      }
    }

    .v-list {
      padding: 0 24px;

      &-item {
        height: 40px !important;

        &__title {
          color: $grey;
          font-weight: $font-semibold;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover {
          .v-list-item__title {
            color: $primary;
          }
        }
      }

      .v-item--active {
        .v-list-item__title {
          color: $primary;
        }
      }

    }

    .v-list-item-group {
      .v-list-group__header {
        padding: 8px;
        min-height: initial;
        border-radius: 8px;

        .v-list-item__icon .v-icon {
          font-size: 25px;
        }

        &:hover {
          color: $primary;

          &:before {
            background-color: $primary;
            border-radius: 8px;
            opacity: 0.12;
          }

          .v-icon {
            color: $primary;
          }
        }

        .v-list-item__icon {
          &:first-child {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 0.75rem;
          }
        }
      }

      &.v-list-group--active .v-list-group__header {
        &:hover {
          &:before {
            opacity: 0;
          }
        }
      }

      .v-list-item {
        padding: 8px 6px 8px 8px;
        height: 46px;
        border-radius: 8px;
        min-height: initial;
        margin: 4px 0;
        color: $grey;
        flex-wrap: wrap;
        align-content: center;
        font-weight: $font-semibold;

        &:last-child {
          margin-bottom: 10px;
        }

        &:before {
          border-radius: 8px;
        }

        &:hover {
          color: $primary;
          // background-color: red !important;
          background-color: rgba($secondary, 0.1);

          &:before {
            background-color: $primary;
            opacity: 0.12;
          }

          .v-icon {
            color: $primary;
          }
        }

        &__title {
          font-size: $font-size-root;
          line-height: normal;
        }

        &__content {
          padding: 0;
        }

        &__icon {
          margin-right: 3px;
        }

        .v-input .v-input__slot {
          min-height: initial !important;
        }

        &-active {
          color: $primary;
        }
      }
    }

    .v-list-group__items {
      padding-left: 1rem;

      .v-list-item__action {
        margin: 0 0.75rem 0 0;
        min-width: initial;
      }
    }
  }

  .v-card--app-filter {
    .language-selector {
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 960px) {
    .page-header {
      padding: 16px 0;
      &__title {
        margin-top: 3px;
        h1 {
          text-align: center;
          width: 100%;
        }
      }
    }

    .settings__list {
      margin-top: 12px;
    }

    .translation-page-content {
      .translations {
        flex-direction: column;
        // justify-content: center;
        .translation-category-selector {
          display: inline-flex;
          margin: 0 12px;
          width: calc(100% - 24px);
        }
      }
      .v-card--app-filter {
        display: none;
      }
    }

    .translations-edit {
      .translations {
        .v-card {
          padding: 1.25rem 0;
        }
      }
    }
    .translations {
      .v-card {
        padding: 1.25rem 1rem;
      }
      &__file-information {
        padding: 0 12px;
      }
    }
  }

  .translations-edit {
    @media screen and (max-width: 599px) {
      .page-header {
        padding: 18px 5px 0px;
      }
    }
    @media screen and (min-width: 600px) and (max-width: 960px) {
      .page-header {
        padding: 18px 32px 0px;
      }
    }
    @media screen and (min-width: 961px) {
      .page-header {
        padding-left: 272px;
      }
    }
  }
  .dialog-category-selector {
    height: 100%;
    max-height: calc(100vh - 88px) !important;
    padding: 14px 0;
    margin: 88px 0 0 !important;
    border-radius: 0;
    .v-list-item {
      padding: 0 8px;
      min-height: 40px;
      &:hover {
        background-color: $light-blue;
        border-radius: 8px;
      }
      .v-list-item__title {
        font-size: $font-size-root;
        font-weight: $font-semibold;
        line-height: normal;
      }
    }
    .v-btn {
      position: fixed;
      bottom: 0;
      width: calc(100% - 32px);
      margin: 10px 16px;
    }
  }
}
