.v-main {
  &__wrap {
    padding: 0 0 2rem;

    @media screen and (max-width: 960px) {
      padding: 0px 8px 18px;
      margin-top: 24px;
    }
  }

  &:not(.none-wrapper) .v-main__wrap {
    margin: 0 auto;
    padding: 0 2rem 2rem;

    @media screen and (max-width: 960px) {
      padding: 0px 8px 18px;
    }
  }
}