.data-table-paginator .v-pagination {
  gap: 9.6px;

  .v-pagination__item,
  .v-pagination__navigation {
    background: transparent;
    box-shadow: none;
  }

  .v-pagination__navigation {
    margin: 0;
    &:not(.v-pagination__navigation--disabled) {
      i.v-icon {
        color: $primary;
      }

      // color: $primary;
    }
  }

  .v-pagination__item {
    padding: 0;
    margin: 0;

    &:hover,
    &.v-pagination__item--active {
      color: $white;
      background: $primary;
    }
  }
}
