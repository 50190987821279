#templates {
  $pop-hover-min-width: 22.1rem;

  .side-content {
    padding-bottom: 1rem;
    padding-left: 1rem !important;
  }

  .templates {
    &--tabs {
      background-color: $alice-blue;
      position: sticky;
      top: 142px;
      z-index: 9;
      padding-bottom: 10px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 2rem;
        height: 100%;
        background-color: $alice-blue;
        z-index: 400;
      }

      &::before {
        left: -2rem;
      }

      &::after {
        right: -2rem;
      }

      @media screen and (max-width: 960px) {
        top: 173px !important;
      }
    }

    &--mobile__type {
      width: 100% !important;
      height: calc(100vh - 87px);
      position: fixed;
      top: 87px;
      left: 0;
      z-index: 600;
      background-color: $alice-blue;
      padding: 16px 12px;

      &--list {
        height: calc(100vh - 87px - 80px);
        overflow: auto;

        .v-list {
          background-color: $alice-blue;

          &-item {
            &__title {
              color: $grey;
              font-weight: $font-semibold;
            }

            &:hover {
              .v-list-item__title {
                color: $primary;
              }
            }

            &-active,
            &--active {
              .v-list-item__title {
                color: $primary;
              }

              .v-list-item__icon i {
                color: $primary;
              }

              &::after {
                display: none;
              }
            }
          }
        }
      }

      &--buttons {
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: 12px;
      }
    }
  }

  // TODO: @benjamin can you check is it possible to move to the global?
  .v-card--app-filter {
    border-radius: 0 8px 8px 0 !important;
    // margin-top: -56px;
    width: 300px !important;
    padding: 0 !important;
    overflow: hidden;
    margin-left: -2rem;

    &__title {
      color: $grey-arsenic;
      font-weight: $font-bold;
      height: 40px;
      padding: 0 32px 0 32px !important;
      margin-top: 10px;
    }

    .v-list {
      padding: 0;
      padding: 0 24px;

      &-item {
        &__title {
          color: $grey;
          font-weight: $font-semibold;
        }

        &:hover {
          .v-list-item__title {
            color: $primary;
          }
        }

        &-active,
        &--active {
          .v-list-item__title {
            color: $primary;
          }

          .v-list-item__icon i {
            color: $primary;
          }
        }
      }
    }

    .v-list-item-group {
      .v-list-group__header {
        padding: 8px;
        min-height: initial;
        border-radius: 8px;

        .v-list-item__icon .v-icon {
          font-size: 25px;
        }

        &:hover {
          color: $primary;

          &:before {
            background-color: $primary;
            border-radius: 8px;
            opacity: 0.12;
          }

          .v-icon {
            color: $primary;
          }
        }

        .v-list-item__icon {
          &:first-child {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 0.75rem;
          }
        }
      }

      &.v-list-group--active .v-list-group__header {
        &:hover {
          &:before {
            opacity: 0;
          }
        }
      }

      .v-list-item {
        padding: 8px;
        height: 46px;
        border-radius: 8px;
        min-height: initial;
        margin: 4px 0;
        color: $grey;
        flex-wrap: wrap;
        align-content: center;
        font-weight: $font-semibold;

        &:last-child {
          margin-bottom: 10px;
        }

        &:before {
          border-radius: 8px;
        }

        &:hover {
          color: $primary;
          // background-color: red !important;
          background-color: rgba($secondary, 0.1);

          &:before {
            background-color: $primary;
            opacity: 0.12;
          }

          .v-icon {
            color: $primary;
          }
        }

        /* FIXME:: if it already have css classed in vuetify, please use class from vuetify2 and remove this classes */
        &__title {
          font-size: $font-size-root;
        }

        &__content {
          padding: 0;
        }

        &__icon {
          margin-right: 3px;
        }

        .v-input .v-input__slot {
          min-height: initial !important;
        }

        &-active {
          color: $primary;
        }
      }
    }

    .v-list-group__items {
      padding-left: 1rem;

      .v-list-item__action {
        margin: 0 0.75rem 0 0;
        min-width: initial;
      }
    }
  }

  .v-data-table {
    padding: 16px 12px 20px 12px;

    &--title {
      color: $black;
      font-size: 20px;
      font-weight: 500;
    }

    &>.v-data-table__wrapper>table>thead>tr:last-child>th {
      border-color: $primary-lighter;
    }

    th {
      padding: 10px 16px 20px 16px;
    }

    td {
      padding: 10px 16px;
    }

    &--item {
      color: $grey-arsenic;

      &__default {
        /* FIXME:: if it already have css classed in vuetify, please use class from vuetify2 */
        display: flex;

        .v-input {
          margin: 0 auto;
        }

        .v-messages {
          display: none;
        }
      }

      &__type {
        color: $grey-arsenic;
        font-size: $font-size-root;
      }

      &__title {
        /* FIXME:: @benjamin if it already have css classed in vuetify, please use class from vuetify2 */
        color: $black;
        font-size: $font-size-root;
      }

      &__code {
        /* FIXME:: @benjamin if it already have css classed in vuetify, please use class from vuetify2 */
        color: $grey;
        font-size: $font-size-sm;
      }

      &__language,
      &__version,
      &__updated {
        /* FIXME:: @benjamin if it already have css classed in vuetify, please use class from vuetify2 */
        color: $grey;
        font-size: $font-size-root;
      }

      &__language {
        /* FIXME:: @benjamin if it already have css classed in vuetify, please use class from vuetify2 */
        text-transform: uppercase;
      }
    }
  }

  .container {
    padding-right: 0;
  }

  @media screen and (max-width: 960px) {
    .container {
      padding: 0;
    }

    .v-card--app-filter {
      display: none;
    }

    .mobile__btn {
      position: absolute;
      top: 27px;
      right: 6px;
    }
  }
}

#templates-page {
  .v-input {
    &--switch {
      label {
        color: rgba($black, 0.6);
        font-size: $font-size-subtitle !important;
      }

      &.v-input--is-label-active {
        label {
          color: $black;
        }
      }
    }
  }

  .show-information {
    .templates-elements {
      &--information {
        margin-top: -44px;

        .v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
          border-color: $primary-lighter !important;
        }
      }
    }
  }

  .v-input--selection-controls.v-input--switch {
    margin-top: 0;
    padding-top: 0;

    .v-input__slot {
      min-height: initial !important;
      margin-bottom: 0;
    }

    .v-messages {
      display: none;
    }
  }

  @media screen and (max-width: 960px) {
    .page-header__title {
      H1 {
        width: 100%;
        text-align: center;
      }
    }
  }
}

#templates-field {
  .tabs {
    &--badge {
      &--full {
        background-color: $primary;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        margin-right: 5px;
      }

      &--bordered {
        position: relative;
        background-color: $white;
        border: 1px solid $primary;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        margin-right: 5px;

        &::after {
          position: absolute;
          content: "";
          background-color: $primary;
          border: 1px solid $white;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .card {
    &__actions {
      textarea {
        max-height: inherit;
      }
    }
  }

  .v-data-table__checkbox.v-simple-checkbox {
    display: none !important;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
  }

  .v-input--checkbox {
    margin-top: 0 !important;
    padding-top: 0 !important;

    .v-input {
      &--selection-controls__input {
        .v-icon::before {
          color: $black;
        }
      }
    }

    &.v-input--is-label-active {
      .v-input {
        &--selection-controls__input {
          .v-icon::before {
            color: $primary;
          }
        }
      }
    }

    .v-messages {
      display: none;
    }

    .v-label {
      color: $black;
    }

    .v-input__slot {
      padding: 8px;
      margin: 4px 0;
    }
  }

  .options {
    gap: 24px;
    margin-left: -10px;

    @media screen and (max-width: 960px) {
      margin-left: 0;
    }
  }

  .controlled-vocabulary .v-data-table .v-data-table__icon-move {
    margin-right: 21px !important;
  }

  table {
    thead {
      height: auto !important;

      tr {
        height: auto !important;
      }

      th {
        height: auto !important;
        padding: 10px 10px 10px 0;
      }
    }

    tbody {
      tr {
        td {
          padding: 12px 0;

          &:first-child {
            min-width: inherit !important;
            padding: 0;
            width: 25px;
          }

          &:last-child {
            min-width: inherit !important;
            padding: 0;
            width: 25px;
          }
        }
      }

      @media screen and (max-width: 960px) {
        tr {
          td {
            min-width: 180px;
          }
        }
      }
    }
  }

  .rules {
    border: 1px solid $light-grey;
    border-radius: 8px;
    padding: 24px 10px 0 10px;

    &__checkbox {
      max-width: 820px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 55px;
      row-gap: 12px;

      .v-input--checkbox {
        width: 120px;

        .v-input {
          &__slot {
            margin: 2px 0;
          }

          &--selection-controls__input {
            .v-icon::before {
              color: $dark-grey;
            }
          }
        }

        &.v-input--is-label-active {
          .v-input {
            &--selection-controls__input {
              .v-icon::before {
                color: $primary;
              }
            }
          }
        }
      }
    }

    &__title {
      color: $primary;
    }

    &__description {
      font-size: $font-size-sm;
      color: $grey;
    }

    &--true {
      color: $black;
    }

    table {
      td {
        padding: 12px 0 !important;
      }

      tr:hover {
        background: transparent !important;
        cursor: default;
      }

      td.text-start,
      th.text-start {
        display: none;
      }
    }

    .v-input {
      &__slot {
        align-items: center;
      }

      .v-label {
        transform: inherit;
        color: $dark-grey;
      }

      &--selection-controls {
        margin-top: 0;
        padding-top: 0;
        height: 40px;
      }

      &--is-label-active {
        label {
          color: $primary !important;
        }
      }
    }
  }

  .v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border-color: $primary-lighter !important;
  }

  .btn-add-row.v-btn {
    width: calc(100% - 129px) !important;
    margin-left: -16px;

    @media screen and (max-width: 960px) {
      width: 100% !important;
      margin-left: 0;
    }
  }

  .metadata-producers {

    &__content {
      &__container {
        display: flex;
        gap: 16px;
      }

      &__choices {
        width: 274px !important;

        .v-list {
          width: 274px;

          .v-item-group {
            margin: 0 12px;

            .v-list-item {
              height: 40px;
              margin: 4px 0;
              border-radius: 8px;
              padding: 8px 8px 8px 14px;
              overflow: hidden;
              transition: 0.4s all;

              &__icon {
                color: $grey;
                margin-right: 12px;
                min-width: 20px;
                display: initial;
                align-self: initial;

                i {
                  font-size: 20px;
                }
              }

              &__title {
                font-size: $font-size-root;
                color: $grey;
              }

              &--active,
              &:hover {
                color: $primary !important;
                background-color: $secondary;
                transition: 0.4s all;

                .v-list-item__title,
                .v-list-item__icon,
                .v-btn.v-btn--icon {
                  color: $primary !important;
                }
              }
            }
          }

          &-item {
            &--active {
              &:after {
                content: none !important;
              }
            }
          }
        }
      }

      &__data {
        width: 100%;

        .tabs {
          min-width: 100%;
          max-width: 0;
        }
      }

      &__available-items {
        margin: 0 12px;
        padding: 16px 12px;
        color: $primary;
      }
    }

    &__options {
      table {
        tbody {
          tr {
            td {
              padding-right: 16px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 960px) {
      &__content {
        &__container {
          flex-direction: column;
        }

        &__choices {
          width: 100% !important;
          padding-bottom: 24px;
          border-bottom: 1px solid rgba($light-grey, 0.30);

          .v-list {
            width: 100%;

            .v-item-group {
              margin: 0;
            }
          }
        }

        &__data {
          padding-top: 8px;
        }

        &__available-items {
          margin: 0;
          padding: 11px 12px;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    .page-header__title {
      H1 {
        width: 100%;
        text-align: center;
      }
    }
  }
}