.publish {
  .v-text-field {
    color: $black;
  }

  .border-lighter-blue{
    border-color: $lighter-blue;
  }

  .resources-list {
    max-height: 284px;
    overflow-y: scroll;

    .v-input--checkbox {
      margin-top: 0;
      padding-top: 0;

      .v-input--selection-controls__input {
        margin-right: 0;
      }
    }

    .document {
      min-width: 300px;
      margin-left: auto;

      @media screen and (max-width: 1080px) {
        min-width: 150px;
      }


    }

    .w-100 {
      @media screen and (max-width: 500px) {
        min-width: 200px;
        max-width: 200px;
      }
    }
  }

  .w-50 {
    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
}