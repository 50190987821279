.project-description {
	h1 {
		color: $eerie-black;
	}

	// .v-file-input {
	.file_modal {
		margin: 0;
		justify-content: flex-end;
		position: absolute;
		bottom: 13px;
		right: 13px;
		background-color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
		width: 32px;
		height: 32px;
		z-index: 100;
		cursor: pointer;

		.v-input__control {
			display: none;
		}

		.v-input__prepend-outer {
			margin: 0;
		}

		.v-input__icon--prepend {
			.v-icon {
				color: white;
				font-size: 2rem;
			}
		}
	}

	.information-image {
		position: relative;
		width: 238px;
		margin-right: 24px;

		@media screen and (max-width: 1264px) {
			margin: 0 0 24px;
		}

		@media screen and (max-width: 390px) {
			width: 100%;

			.v-image {
				width: 100% !important;
			}
		}
	}

	.information-content {
		width: calc(100% - 262px);

		@media screen and (max-width: 1264px) {
			width: 100%;
		}
	}

	.information-box {
		background-color: $lilac;
		padding: 8px 12px;
		border-radius: $border-radius-root;
	}

	.user-list {
		max-height: 200px;
		overflow-y: scroll;
		padding-right: 18px;
		margin-right: -4px;
		position: relative;

		&::after {
			content: "";
			width: 100%;
			height: 75px;
			background: linear-gradient(180deg,
					rgba(255, 255, 255, 0) 0%,
					#ffffff 100%);
			position: absolute;
			bottom: 0;
			pointer-events: none;
		}

		&.hide-overlay {
			&::after {
				display: none;
			}
		}

		.role-selector {
			max-width: 85px;
			max-height: 40px;
			border-color: transparent;

			.v-input__slot {
				max-height: 36px !important;

				&:before {
					display: none;
				}
			}

			.v-text-field__details {
				display: none;
			}
		}
	}

	.collection-card {
		height: calc(100% - 300px);

		@media screen and (max-width: 1264px) {
			height: auto;
		}
	}

	.collection-list {
		gap: 4px;
	}

	h1 {
		max-width: 636px;
	}

	.alert-box {
		min-height: 40px;
	}

	@media screen and (max-width: 390px) {

		.page-header__title,
		h4.main-information__title {
			text-align: center;
		}
	}
}