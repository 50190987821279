#users {
    .page-header {
        padding: 32px 0 24px;
        @media screen and (max-width: 960px) {
            top: 64px;
        }
    }
    .users {
        &__list {
            &__search {
                .v-message,
                .v-text-field__details {
                    display: none;
                }

                label {
                    color: $grey;
                }

                fieldset:not(:hover){
                    border: 0 !important;
                }
                .v-input__slot {
                    &:hover {
                        fieldset {
                            border: 1px solid $primary !important;
                        }
                    }
                }
            }

            .v-data-table {
                table {
                    border-collapse: separate;
                    border-spacing: 0 4px;

                    thead {
                        th {
                            &:last-child {
                                width: 20px;
                                min-width: 20px;
                                margin: 0;
                                padding: 8px;
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                line-height: 20px;

                                &:last-child {
                                    width: 20px;
                                    min-width: 20px;
                                    margin: 0;
                                    padding: 8px;
                                    height: 56px;
                                }
                            }
                        }
                    }
                }
                &__checkbox {
                    .v-icon {
                        &.mdi-checkbox-marked {
                            color: $primary
                        }
                    }
                }

                &--item {
                    &__username,
                    &__role {
                        color: $black;
                    }

                    &__role {
                        text-transform: capitalize;
                    }

                    &__status {
                        color: $grey;
                        position: relative;
                        text-transform: capitalize;

                        &::before {
                            content: "";
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            margin-right: 8px;
                            border-radius: 100%;
                            background-color: $grey;
                        }

                        &.status {
                            &--blocked {
                                &::before {
                                    background-color: $red;
                                }
                            }

                            &--active {
                                &::before {
                                    background-color: $green;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__create {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            &__content {
                width: 800px;
            }

            &__title {
                color: $black;
                font-size: $font-size-subtitle;
                font-weight: $font-semibold;
                margin-bottom: 16px;
            }

            &__form {
                &--grid {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 24px;
                }

                .v-select {
                    .v-messages,
                    .v-text-field__details {
                        display: none;
                    }
                }

                .v-input--checkbox {
                    margin: 0;
                    padding: 0;

                    .v-input__slot {
                        max-height: 32px;
                        margin-bottom: 0;
                        .v-label {
                            transform: none;
                        }
                    }

                    .v-messages {
                        display: none;
                    }
                }

                .item-border {
                    padding: 12px 16px;
                }
            }
        }
    }
    .v-dialog {
        .v-textarea {
            .v-input-slot {
                height: 100px !important;
            }
        }
        .v-btn {
            letter-spacing: 0.035px;
        }
    }
    .dialog-button-group {
        gap: 12px;
    }
    .v-tabs {
        // scroll if tabs are too wide on small screen
        overflow-x: auto;
    }
    @media screen and (max-width: 960px) {
        .page-header {
            padding: 18px 5px 0px;
			section {
				position: absolute;
				top: 27px;
			}
            &__title {
				margin-top: 13px;
			}
        }
    }
}
