.v-tooltip__content--pop-hover {
  pointer-events: initial;
  opacity: 1;
  padding: 10px;
  background-color: $white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  color: $black;

  @media screen and (max-width: 600px) {
    width: calc(100% - 64px);
    left: 32px !important;
  }
  // background-color: $white;

  .pop-hover {
    &--information {
      min-width: 26.25rem;
      cursor: default;

      @media screen and (max-width: 600px) {
        min-width: unset;
      }

      &__header {
        &__title {
          max-width: $pop-hover-width;
        }

        &__close {
          cursor: pointer;
        }
      }

      &__content {
        max-width: $pop-hover-width;
      }

      &__details {
        &-list__item {
          display: flex;
          align-items: center;

          .v-icon {
            color: $grey-arsenic;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
