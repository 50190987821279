.splitpanes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}

.splitpanes--vertical {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.splitpanes--horizontal {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.splitpanes--dragging * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splitpanes__pane {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.splitpanes--vertical .splitpanes__pane {
  -webkit-transition: width 0.2s ease-out;
  -o-transition: width 0.2s ease-out;
  transition: width 0.2s ease-out;
}

.splitpanes--horizontal .splitpanes__pane {
  -webkit-transition: height 0.2s ease-out;
  -o-transition: height 0.2s ease-out;
  transition: height 0.2s ease-out;
}

.splitpanes--dragging .splitpanes__pane {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.splitpanes__splitter {
  -ms-touch-action: none;
  touch-action: none;
}

.splitpanes--vertical>.splitpanes__splitter {
  min-width: 1px;
  cursor: col-resize;
}

.splitpanes--horizontal>.splitpanes__splitter {
  min-height: 1px;
  cursor: row-resize;
}

.splitpanes.default-theme .splitpanes__pane {}

.splitpanes.default-theme .splitpanes__splitter {
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  transition: all 0.5s ease-in-out;
}

.splitpanes.default-theme .splitpanes__splitter:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: all 0.5s ease-in-out;
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9502 18C11.9502 19.1 11.0502 20 9.9502 20C8.8502 20 7.9502 19.1 7.9502 18C7.9502 16.9 8.8502 16 9.9502 16C11.0502 16 11.9502 16.9 11.9502 18ZM9.9502 10C8.8502 10 7.9502 10.9 7.9502 12C7.9502 13.1 8.8502 14 9.9502 14C11.0502 14 11.9502 13.1 11.9502 12C11.9502 10.9 11.0502 10 9.9502 10ZM9.9502 4C8.8502 4 7.9502 4.9 7.9502 6C7.9502 7.1 8.8502 8 9.9502 8C11.0502 8 11.9502 7.1 11.9502 6C11.9502 4.9 11.0502 4 9.9502 4ZM15.9502 8C17.0502 8 17.9502 7.1 17.9502 6C17.9502 4.9 17.0502 4 15.9502 4C14.8502 4 13.9502 4.9 13.9502 6C13.9502 7.1 14.8502 8 15.9502 8ZM15.9502 10C14.8502 10 13.9502 10.9 13.9502 12C13.9502 13.1 14.8502 14 15.9502 14C17.0502 14 17.9502 13.1 17.9502 12C17.9502 10.9 17.0502 10 15.9502 10ZM15.9502 16C14.8502 16 13.9502 16.9 13.9502 18C13.9502 19.1 14.8502 20 15.9502 20C17.0502 20 17.9502 19.1 17.9502 18C17.9502 16.9 17.0502 16 15.9502 16Z' fill='%23181C1E' style='fill:%23181C1E;fill:color(display-p3 0.094118 0.109804 0.117647);fill-opacity:1;'/%3E%3C/svg%3E%0A");
}

.splitpanes.default-theme .splitpanes__splitter:hover {
  transition: all 0.5s ease-in-out;
  background: radial-gradient(33.53% 50% at 50% 50%, color(display-p3 .6353 .6902 .8824/0.6) 0, color(display-p3 1 1 1/0) 100%), color(display-p3 1 1 1/0);

  &:before {
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9502 18C11.9502 19.1 11.0502 20 9.9502 20C8.8502 20 7.9502 19.1 7.9502 18C7.9502 16.9 8.8502 16 9.9502 16C11.0502 16 11.9502 16.9 11.9502 18ZM9.9502 10C8.8502 10 7.9502 10.9 7.9502 12C7.9502 13.1 8.8502 14 9.9502 14C11.0502 14 11.9502 13.1 11.9502 12C11.9502 10.9 11.0502 10 9.9502 10ZM9.9502 4C8.8502 4 7.9502 4.9 7.9502 6C7.9502 7.1 8.8502 8 9.9502 8C11.0502 8 11.9502 7.1 11.9502 6C11.9502 4.9 11.0502 4 9.9502 4ZM15.9502 8C17.0502 8 17.9502 7.1 17.9502 6C17.9502 4.9 17.0502 4 15.9502 4C14.8502 4 13.9502 4.9 13.9502 6C13.9502 7.1 14.8502 8 15.9502 8ZM15.9502 10C14.8502 10 13.9502 10.9 13.9502 12C13.9502 13.1 14.8502 14 15.9502 14C17.0502 14 17.9502 13.1 17.9502 12C17.9502 10.9 17.0502 10 15.9502 10ZM15.9502 16C14.8502 16 13.9502 16.9 13.9502 18C13.9502 19.1 14.8502 20 15.9502 20C17.0502 20 17.9502 19.1 17.9502 18C17.9502 16.9 17.0502 16 15.9502 16Z' fill='%23526BC7' style='fill:%23526BC7;fill:color(display-p3 0.321569 0.419608 0.780392);fill-opacity:1;'/%3E%3C/svg%3E%0A");
    ;
  }
}

.splitpanes.default-theme .splitpanes__splitter:first-child {
  cursor: auto;
}

.default-theme.splitpanes .splitpanes .splitpanes__splitter {
  z-index: 1;
}

.default-theme.splitpanes--vertical>.splitpanes__splitter,
.default-theme .splitpanes--vertical>.splitpanes__splitter {
  width: 24px;
  background-color: transparent;
}

.default-theme.splitpanes--vertical>.splitpanes__splitter:before,
.default-theme .splitpanes--vertical>.splitpanes__splitter:before,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
}

.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
  height: 24px;
  background-color: transparent;
}

.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  -ms-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}