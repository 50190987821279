.data-variable {
  h2 {
    line-height: unset;
  }

  .page-header__title {
    @media screen and (max-width: 960px) {
      text-align: center;

      h1 {
        width: 100%;
      }

      .page-header__subtitle {
        max-width: 100%;
      }
    }
  }

  .vue-icon.xx-small {
    height: 15px;
    width: 15px;

    svg,
    path {
      fill: $black !important;
    }
  }

  .v-input.search {
    max-width: fit-content;

    .v-input__control {
      max-width: fit-content;

      .v-input__slot {
        width: auto;
        max-width: 300px;
        min-width: 130px;
      }
    }

    .v-input__control .v-input__slot fieldset {
      border: 1px solid $light-grey !important;
    }

    &:hover .v-input__control .v-input__slot fieldset {
      border-color: $primary !important;
    }

    &.v-input--is-focused .v-input__control .v-input__slot fieldset {
      border-color: $primary !important;
    }
  }

  .splitpanes-container {
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    background-color: transparent;

    .variable-list {
      height: calc(100% - 54px);

      @media screen and (max-width: 1350px) and (min-width: 960px) {
        height: calc(100% - 92px);
      }

      &.get-data {
        tbody tr {
          cursor: pointer;
        }
      }
    }

    .categories-list {
      height: calc(100% - 46px);

      thead th {
        &:nth-child(2) {
          padding-left: 0;
        }

        &:last-child {
          min-width: 52px;
        }
      }

      tbody {
        td {
          .v-data-table__icon-move {
            margin-right: 0 !important;
          }

          &:nth-child(2) {
            padding-left: 0;
          }

          &:not(:first-child):not(:last-child) {
            min-width: 150px;
          }
        }
      }
    }

    .weights-content {
      tbody td {
        min-width: 150px;

        &:last-child {
          padding: 0;
          min-width: unset;
        }
      }
    }

    .frequencies-content {
      tbody td {
        min-width: 150px;
      }
    }

    .variable-information {
      height: calc(100% - 45px);
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .pane-content {
      border-radius: 8px;
      border: 1px solid rgba($primary, 0.2);
      padding: 12px 12px 0;
      background-color: $white;

      .v-window {
        height: calc(100% - 92px);
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .v-tab {
      padding: 0 24px;
    }

    .v-tabs-items {
      .w-33 {
        @media screen and (max-width: 600px) {
          width: 50%;
        }
      }
    }

    .v-data-table table {
      tbody tr td {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      thead {
        [aria-label="actions"] {
          min-width: 50px;
        }
      }
    }

    .v-input.v-input--checkbox .v-input__slot .v-label {
      transform: unset;
    }
  }

  .slot-top {
    &.has-search {
      @media screen and (max-width: 1350px) and (min-width: 960px) {
        flex-wrap: wrap;

        >div:nth-child(2) {
          order: 3;
          max-width: 100%;
          width: 100%;
          margin-left: 0 !important;
          margin-top: 8px;

          .v-input__control {
            max-width: 100%;
            width: 100%;

            .v-input__slot {
              width: 100%;
              max-width: unset;
              min-width: unset;
            }
          }
        }

        >div:nth-child(3) {
          order: 2;
        }
      }

      @media screen and (max-width: 600px) {
        flex-wrap: wrap;

        >div:nth-child(2) {
          order: 3;
          max-width: 100%;
          width: 100%;
          margin-left: 0 !important;
          margin-top: 8px;

          .v-input__control {
            max-width: 100%;
            width: 100%;

            .v-input__slot {
              width: 100%;
              max-width: unset;
              min-width: unset;
            }
          }
        }

        >div:nth-child(3) {
          order: 2;
        }
      }
    }

    .v-btn--icon {
      width: 28px !important;
      height: 28px !important;

      .vue-icon {
        width: 20px;
        height: 20px;
      }

      .vue-icon-trash-dark:after {
        width: 28px;
        height: 28px;
      }
    }
  }

  .v-data-table {
    tbody tr {
      cursor: default;

      td {
        min-width: unset;

        @media screen and (max-width: 960px) {
          &:not(:first-child):not(:last-child) {
            min-width: 150px;
          }
        }
      }
    }
  }

  .v-expansion-panel {
    .v-expansion-panel-header {
      min-height: unset;
    }

    &.no-shadow {
      &::before {
        box-shadow: none;
      }
    }

    .v-expansion-panel-content__wrap {
      background-color: unset;
      border-radius: unset;
      padding: unset;
      margin: unset;
    }
  }

  .missing-table {
    tbody {
      td:last-child {
        width: 5%;
        padding-left: 0;
      }
    }
  }
}


#variables {
  .variable-groups {
    &__content {
      &--form {
        width: 100%;
      }
    }

    .v-select,
    .v-input {

      .v-messages,
      .v-text-field__details {
        display: none;
      }

      &--selection-controls {
        margin-top: 0;
        padding-top: 0;
      }
    }

    &--concepts {
      border-top: 1px solid $silver;
      margin-top: 14px;
      padding-top: 14px;

      .v-data-table:not(.v-data-table--selectable) {
        tbody {
          .v-data-table__icon-move {
            margin-right: 0 !important;
          }
        }
      }

      .btn-add-row {
        padding-left: 31px !important;
        padding-right: 46px !important;
      }

      .v-data-table__checkbox.v-simple-checkbox {
        display: none !important;
      }

      table {
        thead {
          height: auto !important;

          tr {
            height: auto !important;
          }

          th {
            height: auto !important;
            padding: 10px 4px 20px;

            &[aria-label="sortable"]:before {
              top: 40%;
            }
          }
        }

        tbody {
          tr {
            td {
              padding-right: 4px;
              padding-left: 4px;

              &:first-child {
                min-width: 27px !important;
                padding: 0;
                width: 27px;
              }

              &:last-child {
                min-width: 42px !important;
                padding: 0;
                width: 42px;
              }
            }
          }
        }
      }
    }

    &--dialog {
      &--selection {
        max-height: 300px;
        overflow-y: auto;

        &--group {
          color: $grey-arsenic;
          font-size: $font-size-root;
          font-weight: $font-bold;
          // padding-left: 20px;
        }

        .v-input--selection-controls {
          margin: 0;
          padding: 0;
          height: 48px;
        }

        .v-messages {
          display: none;
        }

        .v-input {
          width: calc(100% - 60px);

          &__slot {
            min-height: 48px !important;
          }

          label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .btn-add-row {
      padding-left: calc(5% + 4px);
      padding-right: calc(5% + 4px);

      button {
        background-color: $white !important;
      }
    }
  }
}