.variable-group-tree {
  $font-color-grey: $grey;
  $font-color-active-primary: $primary;
  color: $font-color-grey;
  min-width: 270px;

  .v-treeview-node {
    &__root {
      position: relative;
      border-radius: 8px;
      padding: 0 6px !important;
      min-height: 36px !important;
      margin: 4px 0;
      // background-color: $secondary;
      color: $font-color-active-primary;
      cursor: pointer;

      &:has(.v-treeview-node__toggle--open) {

        .v-treeview-node__content,
        .v-icon {
          color: $font-color-active-primary;
        }
      }

      &::before {
        display: none;
      }
    }

    &__toggle {
      position: absolute;
      right: 0;
      transform: none;

      &:focus {
        &::after {
          display: none;
        }
      }

      &--open {
        transform: rotate(-180deg);
      }
    }

    &__prepend {
      display: flex;
      margin-right: 0;

      &:has(.no-icon) {
        display: none;
      }
    }

    &__label {
      max-width: 170px;
    }

    &__level {
      width: 10px;
      height: 50%;
      position: absolute;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 0;
      top: 2px;
      // left: -1px;
      left: -16px;
      /* border: 0.8px solid $font-color-grey; */
      border-right: 0 !important;

      &::after {
        content: "";
        height: calc(50% + 1px);
        width: 10px;
        min-height: unset;
        position: absolute;
        top: -2px;
        left: 0;
        border-bottom-left-radius: 4px;
        border-right: 0;
        border-top: 0;
        background-color: $white;
      }

      &:not(:first-of-type) {
        display: none;
      }
    }

    &__children {
      margin-left: 4px;

      .v-treeview-node {
        margin-left: 17px;

        &__content {
          font-weight: 400;
          margin-left: 8px;

          .v-icon {
            font-size: 18px;
          }
        }

        &__root {
          position: relative;
          padding: 0;
          margin-left: 15px;
        }

        &__level {
          // border: 0.8px solid $font-color-grey;
          border: 1px solid $font-color-grey;
        }

        &__children {
          // .v-treeview-node__level:not(:last-child) {
          //   left: -0.8px;
          // }

          margin-left: 21px;
        }
      }
    }

    &__root {
      position: relative;
      color: $font-color-grey;

      &:hover {
        background-color: $secondary;
        color: $font-color-active-primary;
        transition: 0.3s ease-in-out;
      }
    }
  }

  .v-treeview>.v-treeview-node>.v-treeview-node__children>.v-treeview-node:not(:last-child) {
    border-left: 1px solid $font-color-grey;

    .v-treeview-node__children {
      .v-treeview-node:not(:last-child) {
        border-left: 1px solid $font-color-grey;
      }
    }
  }

  .v-treeview-node__children>.v-treeview-node:last-child {
    .v-treeview-node__level {
      // left: 0;
      // top: 0;
      left: -15px;
    }
  }

  .v-treeview-node {
    position: relative;
  }

  .v-treeview>.v-treeview-node>.v-treeview-node__children>.v-treeview-node:not(:last-child):after {
    content: "";
    background: $font-color-grey;
    width: 1px;
    height: 120%;
    position: absolute;
    left: -1px;
    top: 0;
  }

  .v-treeview>.v-treeview-node>.v-treeview-node__children>.v-treeview-node:not(:last-child) .v-treeview-node__children .v-treeview-node:not(:last-child)::after {
    content: "";
    background: $font-color-grey;
    width: 1px;
    height: 120%;
    position: absolute;
    left: -1px;
    top: 0;
  }

  .v-treeview-node__children .v-treeview-node__children:last-child .v-treeview-node:not(:only-child):last-child::after {
    background: $font-color-grey;
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: -30px;
  }

  // .v-treeview-node__children:not(:only-child)>.v-treeview-node:not(:only-child):last-child::after {
  //   background: $font-color-grey;
  //   content: "";
  //   width: 1px;
  //   height: 100%;
  //   position: absolute;
  //   top: -30px;
  // }

}
