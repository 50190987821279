.container {
  @media screen and (max-width: 599px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (min-width: 600px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
