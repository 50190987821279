.v-menu-switch-language {
  $button-size: 1.5rem;
  padding: 8px 0;
  cursor: pointer;

  &__arrow {
    width: $button-size;
    height: $button-size;
    padding: 6px;
    display: flex;
    align-items: center;
  }
}

.v-menu__content {
  border-radius: $border-radius-root;
  background-color: $white;
  padding: 8px;
  margin-top: 10px;

  &:not(.v-menu__content--language) {
    .v-list {
      background-color: transparent;
      padding: 0;

      .v-list-item {
        min-height: initial;
      }
    }
  }

  &--account-menu,
  &--language,
  &--user-select {
    margin-top: 0.7rem;

    .v-list {
      padding: 0;

      .v-list-item {
        margin-top: 4px;
        overflow: hidden;
        border-radius: $border-radius-root;

        &__title {
          font-size: $list-item-title-font-size;
        }

        &:first-child {
          margin-top: 0;
        }

        .v-list-item__icon {
          margin-right: 0.5rem;
        }
      }

      .v-list-item--link {

        &:hover,
        &:focus {
          &:before {
            opacity: 0.12;
            border-radius: $border-radius-root;
            background-color: $primary;
          }
        }
      }
    }
  }

  &--data-table {

    // right: 0 !important;
    // left: auto !important;
    .v-list .v-list-item {
      padding: 0.81rem 1rem;
      min-height: initial;
    }
  }

  &--tree-selection-menu {
    padding: 10px 0;

    .v-select-list {
      padding: 0;
      box-shadow: none;
    }

    .v-treeview {
      .v-treeview-node__level {
        width: 20px;
      }
    }
  }

  &--user-select {
    min-width: 204px !important;

    .v-list {
      &-item {
        padding: 16px 12px;
        margin-bottom: 8px;

        &.selected-item {
          position: relative;

          &::after {
            content: '';
            width: 18px;
            height: 18px;
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.24998 13.0633L4.64748 10.4608C4.35498 10.1683 3.88248 10.1683 3.58998 10.4608C3.29748 10.7533 3.29748 11.2258 3.58998 11.5183L6.72498 14.6533C7.01748 14.9458 7.48998 14.9458 7.78248 14.6533L15.7175 6.71828C16.01 6.42578 16.01 5.95328 15.7175 5.66078C15.425 5.36828 14.9525 5.36828 14.66 5.66078L7.24998 13.0633Z' fill='%23757575' style='fill:%23757575;fill:color(display-p3 0.4588 0.4588 0.4588);fill-opacity:1;'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat no-repeat;
            background-position: center center;
            background-size: cover;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
          }
        }
      }
    }

    .remove {
      cursor: pointer;
      border-radius: $border-radius-root;
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: $soft-red;
      }
    }
  }
}

// User Options
.v-menu__switch {
  height: 24px;

  .vue-icon path,
  .vue-icon svg {
    fill: $black !important;
  }
}