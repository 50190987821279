@import "variables.scss";
@import "vue-icon.scss";
@import "splitpanes.scss";

* {
  box-sizing: border-box;
}

::-webkit-scrollbar-track {
  background-color: $secondary;
  border-radius: 2px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: $secondary;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $primary;
}

// @media screen and (min-width: 1025px) {
//   ::-webkit-scrollbar {
//     width: 9px !important;
//     background-color: transparent;
//     border-radius: 16px;
//   }
// }

html {
  overflow-y: auto !important;
}

body {
  background-color: $alice-blue;
  color: $grey;
  font-size: 0.875rem;
  line-height: 1.3;
}

// @import "@mdi/font/css/materialdesignicons.css";
#app {
  font-family: $body-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $alice-blue;

  .v-application {
    // background: $alice-blue;
    background: transparent;
  }

  &.has-side-filter {
    .app-header {
      .v-toolbar__content {
        justify-content: space-between;
      }

      .mobile-header__btn--navigation {
        display: none !important;
      }
    }
  }

  // import vuetify styles
  @import "vuetify/index.scss";
  @import "page/index.scss";
  @import "typography.scss";
  @import "sizing.scss";
  @import "app-header.scss";
  @import "app-filter.scss";
}

.LayoutDefault {
  //   height: 100%;
  min-height: 100%;
  // display: flex;
  //   padding-top: 64px; // $header-height is 64px;
}

.side-content {
  width: calc(100% - 270px);
  padding: 2rem 0;
  margin-left: 270px;
  background-color: $alice-blue;

  @media screen and (max-width: 960px) {
    width: 100%;
    margin: 24px 0 0;
  }
}

.page-header {
  position: sticky;
  top: 50px;
  padding: 32px 0 24px;
  margin: 0;
  background-color: $alice-blue;
  z-index: 500;

  h1 {
    letter-spacing: 0.085px;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 2rem;
    height: 100%;
    background-color: $alice-blue;
    z-index: 400;
  }

  &.has-alert {
    padding: 32px 0 12px;
  }

  &::before {
    left: -2rem;
  }

  &::after {
    right: -2rem;
  }

  @media screen and (max-width: 960px) {
    padding: 18px 5px;
    top: 77px;
    gap: 12px;
    h1 {
      text-align: center;
    }
    &__subtitle {
      text-align: center;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: bold;
    width: calc(100% - 185px);
    max-width: 636px;

    @media screen and (max-width: 1264px) {
//        margin-bottom: 8px;
    }

    @media screen and (max-width: 960px) {
      min-width: 100%;
    }
  }

  &__subtitle {
    max-width: 372px;
  }

  &.scrolling {
    .page-header {
      &__title {

        h1 {
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          transition: 0.3s ease-in-out;
        }
      }

      &__subtitle {
        max-width: 636px;
      }
    }
  }

  .mobile__btn {
    background-color: $primary;

    .vue-icon {
      color: $white;
    }
  }
}

.full-page-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  justify-content: center;
  align-items: center;
  display: none;
  transition: all 0.5s linear;

  &__spinner {
    img {
      object-fit: cover;
    }
  }

  &--active {
    display: flex;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.page-header-menu {
  &.v-menu__content {
    position: fixed;
    top: 135px !important;
  }
}
