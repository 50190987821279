#variable-groups {
  .main {
    align-items: flex-start;
    // todo: @benjamin: recheck and change, it's weird
    gap: var(--16px, 16px);
  }

  .variable-tree {
    align-self: stretch;
    flex-direction: column;
    width: 336px;
    padding: 0 24px;
    background: $white;
    box-shadow: none;
  }

  .variable-form {
    width: calc(100% - 400px);
    min-width: 300px;
    // todo: @benjamin: recheck and change, it's weird
    border-radius: var(--12px, 12px);
    background: $white;

    label,
    .item-border__title {
      font-size: $font-size-sm !important;
    }

    .v-input.v-text-field {
      margin: 24px 0;
    }

    .subtitle-1 {
      line-height: 24px;
    }

    table {
      table-layout: fixed;

      thead {
        tr {
          th {
            padding-bottom: 10px;
            padding-top: 0;
          }

          th:first-child,
          th:last-child {
            width: 18px;
          }
        }
      }

      tbody {
        tr {
          margin-bottom: 18px;

          td:not(:first-child) {
            padding: 8px 4px;
          }

          td {
            .v-input.v-text-field {
              margin: 0;
            }
          }
        }

        tr {
          td {
            padding: 32px 4px 8px 4px;
          }
        }
      }
    }

    .variables-field {
      position: relative;
      height: 162px;
    }
  }

  .v-data-table__checkbox.v-simple-checkbox {
    display: none !important;
  }

  .variable-card {
    padding: 0;
    overflow-y: scroll;
    box-shadow: none;
    right: 7px;
  }

  #variables-available-table {
    table {
      table-layout: fixed;

      thead {
        display: none;
      }

      tbody {
        tr {
          td {
            padding: 12px 0;
            text-overflow: ellipsis;
            line-height: 20px;

            .v-input {
              margin: 0;
              padding: 0;
            }

            .no-wrap {
              white-space: nowrap;
            }
          }

          td:first-child {
            width: 32px;
          }

          td:nth-child(3) {
            width: 36px;
          }

          td:has(> .primary--text)+td>div {
            color: $primary !important;
          }
        }
      }
    }

    .dark-grey-overrideable-text {
      color: $dark-grey;
    }

    .darker-grey--text {
      color: #616161 !important;
    }

    .variable-name {
      width: 420px;
    }

    .variable-group {
      width: 32px;
    }

    .v-input--checkbox .v-input__slot {
      min-height: 24px !important;
    }
  }

  .v-dialog {
    padding: 0;

    .v-data-table {
      padding: 0 24px;
    }
  }

  .dialog-header {
    height: 60px;
    padding: 14px 16px 10px 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    h2 {
      width: 497px;
    }
  }

  .dialog-footer {
    padding: 20px 16px 8px 16px;

    button {
      .v-icon {
        height: 24px;
        width: 24px;
      }
    }
  }

  .rotate {
    transform: rotate(90deg);
  }

}