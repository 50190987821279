.theme--light.v-list-item:not(.v-list-item--active):not(
		.v-list-item--disabled
	) {
	color: $grey;
}

.v-list-item--mark {
	.v-list-item--active {
		&::after {
			content: '';
			width: 18px;
			height: 18px;
			background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.24998 13.0633L4.64748 10.4608C4.35498 10.1683 3.88248 10.1683 3.58998 10.4608C3.29748 10.7533 3.29748 11.2258 3.58998 11.5183L6.72498 14.6533C7.01748 14.9458 7.48998 14.9458 7.78248 14.6533L15.7175 6.71828C16.01 6.42578 16.01 5.95328 15.7175 5.66078C15.425 5.36828 14.9525 5.36828 14.66 5.66078L7.24998 13.0633Z' fill='%23526bc7' style='fill:color(display-p3 0.321569 0.419608 0.780392);fill-opacity:1;'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat no-repeat;
			background-position: center center;
			background-size: cover;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 16px;
		}
	}
}
