#project {
  .user-list {
    max-height: 200px;
    overflow-y: auto;
    padding-right: 10px;
    position: relative;
    font-size: $font-size-subtitle;

    .v-input.v-select--input {
      max-width: 90px;
      border-color: transparent;

      .v-input__slot {
        max-height: 36px !important;
        min-height: initial !important;

        &:before {
          display: none;
        }
      }

      .v-text-field__details {
        display: none;
      }

      .v-select__selection {
        color: $black;
      }
    }
  }

  .app-filter {
    position: relative;

    &__side-bar {
      left: -2rem;
    }
  }

  .navigation-tabs {
    background-color: $alice-blue;
    position: sticky;
    top: 140px;
    z-index: 510;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 2rem;
      height: 100%;
      background-color: $alice-blue;
      z-index: 400;
    }

    &::before {
      left: -2rem;
    }

    &::after {
      right: -2rem;
    }

    &--filters {
      top: 75px;

      &::before {
        display: none;
      }
    }
  }

  .filter-container {
    display: flex;
    // flex-wrap: wrap;
    gap: 12px;

    @media screen and (max-width: 960px) {
      flex-wrap: wrap;
    }
  }

  .filter-input {
    width: 100%;

    @media screen and (max-width: 960px) {
      order: 2;
      width: 100%;
    }
  }

  .filter-button {
    width: 120px;

    @media screen and (max-width: 960px) {
      .v-btn--filter {
        height: 44px;
      }
    }
  }

  .filter-dropdown {
    .v-input:not(.v-input--is-focused):not(.v-input--has-state) fieldset {
      border: 0 !important;
    }

    .v-input__slot {
      width: 150px;
    }

    @media screen and (max-width: 960px) {
      .v-input__slot {
        width: calc(100vw - 166px);

        @media (hover: none) and (pointer: coarse) {
          width: calc(100vw - 160px);
        }
      }
    }
  }

  .filter-chip {
    .v-chip {
      padding: 8px 8px 8px 12px !important;
      border-radius: 48px;

      .v-chip__content {
        font-size: $font-size-root;
        line-height: 17px;
      }
    }
  }

  .container {
    @media screen and (max-width: 960px) {
      padding: 0;
      width: calc(100% - 12px) !important;
    }
  }

  .v-data-table {
    // padding: 0;

    &--selectable {
      thead {
        tr {
          th {
            padding: 20px 8px;
          }
        }
      }

      thead tr th:first-child,
      tbody tr td:first-child {
        max-width: 41px;
      }

      thead tr th:first-child {
        padding-left: 16px;
      }
    }
  }

  @media screen and (max-width: 960px) {
    .page-header {
      padding: 18px 5px 0px;

      section {
        position: absolute;
        top: 27px;
      }

      &__title {
        margin-top: 13px;
      }
    }

    .navigation-tabs {
      top: 155px;
    }
  }
}