.dropdown {
  &-title {
    color: $primary;
    font-weight: $font-semibold;
    font-size: $font-size-subtitle;
  }
}

.v-menu {
	&__content {
		.v-select-list {
			.v-list-item {
				padding: 0;
				margin: 1px 0;

				&__content {
					padding: 8px;
				}

				&:hover {
					&::before {
						opacity: 0.16;
					}
				}
				&::before {
					border-radius: $border-radius-root;
					background-color: $primary;
				}
			}
		}
	}
}
