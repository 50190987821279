.v-data-table {
  @media screen and (max-width: 599px) {
    padding: 12px 0;
  }

  @media screen and (min-width: 600px) {
    padding: 12px;
  }

  border-radius: 12px;

  &[shadow] {
    box-shadow:
      0px 35px 10px 0px color(display-p3 0.2941 0.4235 0.8078 / 0),
      0px 23px 9px 0px color(display-p3 0.2941 0.4235 0.8078 / 0.01),
      0px 13px 8px 0px color(display-p3 0.2941 0.4235 0.8078 / 0.05),
      0px 6px 6px 0px color(display-p3 0.2941 0.4235 0.8078 / 0.09),
      0px 1px 3px 0px color(display-p3 0.2941 0.4235 0.8078 / 0.1);
  }

  .v-btn.v-btn--icon {
    background-color: transparent;

    &:hover {
      // border-radius: 50% !important;
    }
  }

  &.border {
    border: 1px solid $primary-lighter;
  }

  &__icon-move {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  table {

    // min-width: 800px;
    // overflow-x: scroll;
    thead {
      tr {
        &:last-child {
          th {
            border-bottom: thin solid $primary-lighter !important;
          }
        }

        th {
          padding-top: 1.25rem;
          padding-bottom: 1.25rem;
          font-weight: $font-semibold;
          color: $grey-arsenic;
          font-size: $font-size-root;
        }
      }
    }

    tbody {
      tr {
        transition: all 0.25s;
        border-color: $silver;
        overflow: hidden;
        cursor: pointer;

        &[draggable="true"].sortable-ghost {
          cursor: grabbing;
        }

        &:hover {
          background-color: $secondary;

          td {
            &:first-child {
              border-top-left-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
            }

            &:last-child {
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }
          }
        }

        td {
          padding-top: 18px;
          padding-bottom: 18px;
          border-color: transparent;
          min-width: 100px;
        }
      }
    }
  }

  &--project {
    table {
      tr {
        td {
          vertical-align: top;
        }
      }
    }

    &__description {
      i.vue-icon {
        margin-right: 4px;
        font-size: 16px;
      }
    }

    &__img {
      width: 54px;
      border-radius: 6px;

      * {
        width: 100%;
      }
    }

    &__tag {
      margin-top: 8px;
      margin-left: 4px;

      &:first-child {
        margin-left: 0;
      }
    }

    overflow-x: auto;

    .v-data-table__wrapper {
      min-width: 1103px;
    }
  }

  &--selectable {
    .v-data-table__checkbox {
      i.v-icon {
        color: black;
        font-size: 1.25rem;
        &.mdi-checkbox-marked {
          color: $primary;
        }
      }
    }

    tbody {
      tr td {
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        &:first-child {
          padding: 1.25rem 0 1.25rem 1rem;
          min-width: unset;
        }
      }
    }
  }

  &--default {
    padding: 0;
  }

  &--draggable {
    padding: 0;

    thead {
      th {
        padding-right: 0;

        &:first-child {
          padding-left: 41px;
          position: relative;

          &::before {
            content: "";
            width: 13.5px;
            height: 16.5px;
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.75 11.75L10 15.5L6.25 11.75L7.3 10.6812L9.25 12.6312V1.25H10.75V12.6312L12.7 10.6812L13.75 11.75ZM7.75 4.25L6.7 5.31875L4.75 3.36875L4.75 14.75H3.25L3.25 3.36875L1.3 5.31875L0.25 4.25L4 0.499999L7.75 4.25Z' fill='%23BDBDBD' style='fill:%23BDBDBD;fill:color(display-p3 0.7412 0.7412 0.7412);fill-opacity:1;'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat no-repeat;
            background-position: center center;
            background-size: cover;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          line-height: 1.5 !important;

          &:first-child {
            padding: 12px 0;
          }

          .v-input__slot {
            min-height: unset !important;
          }
        }
      }
    }
  }

  &-header th.sortable .v-data-table-header__icon {
    opacity: 1;

    &::before {
      content: "";
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEwNjNfNDEwMDQpIj4KPHBhdGggZD0iTTEyLjA2ODcgMTIuNzU4MVY4LjI1MDYyQzEyLjA2ODcgNy44MzgxMiAxMS43MzEyIDcuNTAwNjIgMTEuMzE4NyA3LjUwMDYyQzEwLjkwNjIgNy41MDA2MiAxMC41Njg3IDcuODM4MTIgMTAuNTY4NyA4LjI1MDYyVjEyLjc1ODFIOS4yMjYyNUM4Ljg4ODc1IDEyLjc1ODEgOC43MjM3NSAxMy4xNjMxIDguOTYzNzUgMTMuMzk1NkwxMS4wNTYyIDE1LjQ4MDZDMTEuMjA2MiAxNS42MjMxIDExLjQzODcgMTUuNjIzMSAxMS41ODg3IDE1LjQ4MDZMMTMuNjgxMiAxMy4zOTU2QzEzLjkyMTIgMTMuMTYzMSAxMy43NDg3IDEyLjc1ODEgMTMuNDE4NyAxMi43NTgxSDEyLjA2ODdaTTYuNTU2MjUgMi41MTMxMkw0LjQ2Mzc1IDQuNjA1NjJDNC4yMjM3NSA0LjgzODEyIDQuMzg4NzUgNS4yNDMxMiA0LjcyNjI1IDUuMjQzMTJINi4wNjg3NVY5Ljc1MDYyQzYuMDY4NzUgMTAuMTYzMSA2LjQwNjI1IDEwLjUwMDYgNi44MTg3NSAxMC41MDA2QzcuMjMxMjUgMTAuNTAwNiA3LjU2ODc1IDEwLjE2MzEgNy41Njg3NSA5Ljc1MDYyVjUuMjQzMTJIOC45MTEyNUM5LjI0ODc1IDUuMjQzMTIgOS40MTM3NSA0LjgzODEyIDkuMTczNzUgNC42MDU2Mkw3LjA4MTI1IDIuNTEzMTJDNi45Mzg3NSAyLjM3MDYzIDYuNjk4NzUgMi4zNzA2MyA2LjU1NjI1IDIuNTEzMTJaIiBmaWxsPSIjMzIzMjMyIiBzdHlsZT0iZmlsbDojMzIzMjMyO2ZpbGw6Y29sb3IoZGlzcGxheS1wMyAwLjE5NjEgMC4xOTYxIDAuMTk2MSk7ZmlsbC1vcGFjaXR5OjE7Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMTA2M180MTAwNCI+CjxyZWN0IHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0id2hpdGUiIHN0eWxlPSJmaWxsOndoaXRlO2ZpbGwtb3BhY2l0eToxOyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wNjgzNTk0KSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=");
      width: 18px;
      height: 18px;
    }
  }
}

.theme--light.v-data-table {
  color: $grey;

  .v-data-table__wrapper>table>thead>tr:last-child>th {
    border-bottom: thin solid rgba(82, 107, 199, 0.1);
  }
}
