.v-alert {
	margin-bottom: 0;
	border-radius: 0.5rem;
	font-weight: $font-bold;
	font-size: $font-size-root;
	display: inline-block;
	min-height: 40px;
	padding: 8px 20px;

	&__content {
		display: flex;
		align-items: center;

		svg,
		path {
			fill: currentColor !important;
		}
	}

	@media screen and (max-width: 960px) {
		min-height: 44px;
		min-width: 44px;
		padding: 12px;
	}
}