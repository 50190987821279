.v-btn {
  transition: 0.3s ease-in-out;
  border-radius: 0.5rem !important;
  letter-spacing: $btn-letter-spacing;
  line-height: 20px;

  &:not(.v-btn--round) {
    padding: 0.875rem 1.25rem;
    font-weight: $font-bold;
    min-height: 40px !important;
    min-width: 120px;
    box-shadow: none !important;
    text-transform: none;
  }

  &__content {
    line-height: inherit;
  }

  &.v-size {
    &--default {
      height: 52px;

      @media screen and (max-width: 960px) {
        height: 44px !important;
      }
    }

    &--small {
      padding: 0.5rem 1.25rem;
      height: 40px;
    }

    &--x-small {
      min-height: 36px !important;
      height: 36px;
    }
  }

  &.secondary {
    background-color: $light-blue !important;
    color: $primary;
    // border: 1px solid $primary;
    // outline: 1px solid $primary !important;

    .vue-icon {
      svg {
        fill: $primary;
      }
    }

    &:hover,
    &:focus {
      outline-color: $light-blue !important;
    }
  }

  &.white {
    background-color: $white !important;
    color: $primary;
    outline: 1px solid $primary;

    &:hover,
    &:focus {
      outline-color: $light-blue;
    }
  }

  &--filter {
    width: 100%;
  }

  span>span {
    font-size: 14px;
  }

  &.btn-save {
    background-color: $primary;
    color: $white;
    transition: none !important;

    @media screen and (max-width: 960px) {
      min-width: unset;
      padding: 0.625rem;
      height: 44px;
      width: 44px;

      .v-btn__content {
        .vue-icon {
          margin-right: 0 !important;
        }

        span {
          display: none;
        }
      }
    }

    &.saved {
      background-color: rgb($green, 0.10);
      color: $green;
      transition: none !important;

      &--error {
        background-color: $primary;
        color: $white;
      }
    }
  }

  &.btn-primary {
    &:hover {

      svg,
      path {
        fill: $primary !important;
      }

      i:after {
        background-color: unset;
      }

      &:after {
        background-color: $primary-lighter !important;
      }
    }
  }
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg.v-size--default {
  background-color: $primary-lighter !important;
  color: $silver !important;
}

.theme--light.v-btn.v-btn--disabled .v-icon.v-icon--left,
.theme--light.v-btn.v-btn--disabled .v-icon.v-icon--right,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: $silver !important;
}

//Outlined

.v-btn--outlined {
  color: $primary !important;
  border: 1px solid $primary !important;

  &:hover,
  &:focus {
    background-color: $light-blue;
  }
}

.theme--light.v-btn--outlined.v-btn.v-btn--disabled {
  background-color: $transparent-grey !important;
  color: $grey !important;
  border-color: transparent !important;
}

.theme--light.v-btn--outlined.v-btn.v-btn--disabled .v-icon.v-icon--left,
.theme--light.v-btn--outlined.v-btn.v-btn--disabled .v-icon.v-icon--right,
.theme--light.v-btn--outlined.v-btn.v-btn--disabled .v-btn__loading {
  color: $grey !important;
}

//Icon Button
.v-btn.v-btn--icon {
  width: 2.25rem;
  /* 36px */
  height: 2.25rem;
  color: $grey !important;

  @media screen and (max-width: 960px) {
    width: 44px;
    height: 44px;
  }

  &:hover {
    background-color: $light-blue !important;
    color: $primary !important;
  }

  &:active,
  &:focus {
    color: $primary !important;
  }

  &.danger {
      color: $grey;
    &:hover, &:active, &:focus {
      background-color: $pink !important;
      color: $red !important;
    }
  }

  &.v-size {
    &--large {
      width: 40px !important;
      height: 40px !important;
    }
  }


  &.x-small {
    width: 1.75rem;
    height: 1.75rem;

    .v-icon {
      width: 1rem;
      height: 1rem;
      font-size: 1rem;
    }
  }

  &.delete-btn {
    &:hover {
      background-color: $pink !important;
      color: $red !important;
    }
  }
}

.btn-add-row {
  width: 100%;
  padding-left: calc(5% + 1rem);
  padding-right: calc(5% + 1rem);

  @media screen and (max-width: 960px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
