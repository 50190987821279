.app-header {
  z-index: 1000;

  @media screen and (max-width: 960px) {
    padding-top: 24px;
    background-color: $primary-dark;
  }

  .v-app-bar.v-app-bar--fixed {
    z-index: 1000;
  }

  .v-toolbar__content {
    background-color: $dark-blue;
    color: $alice-blue;
    justify-content: space-between;
    padding: 0 2rem;

    @media screen and (max-width: 959px) {
      justify-content: center;
      padding: 0 1rem;
    }

    .page-link {
      border-right: 1px solid $alice-blue;

      a {
        font-size: 1rem;
        color: $white !important;
      }
    }
  }

  a {
    color: $white;
  }

  .v-btn.v-btn--icon:hover {
    background-color: $sky-blue !important;
  }

  .mobile-header {
    &-menu {
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: $primary-dark;
      padding: 0px 8px 24px;
      margin-top: 24px;

      &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90%;
        overflow-y: auto;

        .v-list {
          background-color: $primary-dark;

          &-item {
            border-radius: $border-radius-root;
            margin-bottom: 8px;
            padding: 8px 16px;

            &:hover,
            &.active {
              background-color: rgba($alice-blue, 0.15);
            }

            &__icon {
              margin: 14px 8px 0 0;
              min-width: 18px;

              svg {
                width: 18px;
                height: 18px;

                path {
                  fill-opacity: 1 !important;
                }
              }
            }

            &__title,
            span {
              color: $white;
              font-size: 14px;
              font-weight: 400 !important;
            }

            .language-flag {
              width: 18px !important;
              height: 18px !important;
              border-radius: 100%;
              margin-right: 8px;
            }
          }
        }
      }
    }

    &__btn {
      position: absolute;
      transition: none;

      i {
        color: $white !important;
      }

      &--setting {
        @extend .mobile-header__btn;
        right: 13px;

        &.close {
          z-index: 1005;
        }
      }

      &--navigation {
        @extend .mobile-header__btn;
        left: 16px;
      }

      &.close {
        i {
          width: 30px;
          height: 30px;
          background-image: url("data:image/svg+xml,%3Csvg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.875 7.63594C22.3875 7.14844 21.6 7.14844 21.1125 7.63594L15 13.7359L8.88746 7.62344C8.39996 7.13594 7.61246 7.13594 7.12496 7.62344C6.63746 8.11094 6.63746 8.89844 7.12496 9.38594L13.2375 15.4984L7.12496 21.6109C6.63746 22.0984 6.63746 22.8859 7.12496 23.3734C7.61246 23.8609 8.39996 23.8609 8.88746 23.3734L15 17.2609L21.1125 23.3734C21.6 23.8609 22.3875 23.8609 22.875 23.3734C23.3625 22.8859 23.3625 22.0984 22.875 21.6109L16.7625 15.4984L22.875 9.38594C23.35 8.91094 23.35 8.11094 22.875 7.63594Z' fill='%23F3F5FB' style='fill:%23F3F5FB;fill:color(display-p3 0.9547 0.9612 0.9853);fill-opacity:1;'/%3E%3C/svg%3E");
          background-repeat: no-repeat no-repeat;
          background-position: center center;
          background-size: cover;
        }

        svg {
          display: none;
        }

      }
    }
  }
}