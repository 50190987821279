#resources {
  .external-resources {

    .v-select,
    .v-input {

      .v-messages,
      .v-text-field__details {
        display: none;
      }

      &--selection-controls {
        margin-top: 0;
        padding-top: 0;
      }
    }

    &__content {
      .v-data-table {
        &--item {
          &__name {
            &--title {
              color: $black;
              font-size: $font-size-root;
              width: 595px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &--description {
              color: $grey;
              font-size: $font-size-sm;
              width: 595px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &__type {
            white-space: nowrap;
          }
        }

        tbody {
          .text-start {
            padding: 10px 16px;
          }
        }

        .v-btn:hover {
          border-radius: 8px !important;
        }
      }
    }

    &--attachment {
      border-top: 1px solid $silver;
      margin-top: 14px;
      padding-top: 14px;

      &--grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
      }

      .item-border {
        padding: 12px 16px;
        &.v-active{
          border-color: $primary-light;
        }
      }

      .upload--selected {
        margin: 0 !important;
      }
    }
  }
}


// #external-resources,
// .resource-edit {
//   h2 {
//     line-height: 170%;
//     letter-spacing: 0.05px;
//   }

//   .subheading {
//     line-height: 20px;
//     letter-spacing: 0.035px;
//   }

//   .v-data-table {
//     th {
//       color: $eerie-black;
//       font-weight: $font-semibold;
//     }

//     th:first-child>div {
//       gap: 8px;
//       align-items: center;
//     }

//     &--resource__uri {
//       font-size: $font-size-sm;
//       line-height: 125%;
//       letter-spacing: 0.048px;
//       width: 533px;
//     }

//     &--resource__title {
//       width: 533px;
//     }

//     td:first-child {
//       display: flex;
//       padding: 10px 16px;
//       align-items: flex-start;
//       gap: 8px;
//       flex: 1 0 0;
//       text-overflow: ellipsis;
//     }

//     td:nth-child(2) {
//       padding: 14px 16px, 16px;
//     }
//   }

//   .v-input.v-text-field {
//     margin: 24px 0;
//   }

//   .resource__form--grid {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     column-gap: 24px;
//   }

//   .item-border {
//     padding: 14px 16px;
//   }

//   .vue-icon-article {
//     width: revert;
//     height: revert;
//   }

//   #resource-edit-form {
//     padding: 24px;
//   }

//   .v-list {
//     &-item {
//       &__title {
//         color: $grey;
//       }
//     }
//   }
// }