.v-select {
  &.v-select--input {
    max-width: 100px;
    .v-input__slot {
      padding-right: 0;
      padding-left: 5px;

      &:before,
      &:after {
        content: none;
      }
    }
  }
  &__slot {
    .v-input__append-inner {
      button {
        pointer-events: none;
      }
    }
  }
}

.v-tree-selection__select-inner {
  .v-input__icon--prepend-inner,
  .v-input__icon--clear {
    margin-top: 15px;
    @media screen and (max-width: 960px) {
      margin-top: 5px;
    }
  }

  .v-select.v-tree-selection {
    .v-select-list {
      padding: 0;
    }

    .v-select__slot {
      .v-input__append-inner {
        align-items: flex-start;
        @media screen and (max-width: 960px) {
          align-items: center;
        }
      }
    }

    .v-input__slot {
      min-height: 52px !important;

      .v-chip--select {
        background-color: $alice-blue;
        margin-top: 2px;
        margin-bottom: 2px;
      }
    }

    .v-select--input {
      .v-select__slot {
        .v-input__append-inner {
          align-items: center;
        }
        .v-select__selections input {
          min-width: initial;
        }
      }
    }
  }
}
