.v-expansion-panels {
  row-gap: 24px;

  .v-expansion-panel {
    border-radius: 8px;

    &::before {
      box-shadow:
        0 2px 0px -2px rgba(0, 0, 0, 0.2),
        0 1px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 0px 0 rgba(0, 0, 0, 0.12);
    }

    &::after {
      display: none;
    }

    &-header {
      font-size: $font-size-subtitle;
      font-weight: $font-semibold;
      color: $black;

      &__icon {
        i {
          color: $black;
        }
      }
    }

    &-content {
      &__wrap {
        background-color: $soft-grey;
        border-radius: 24px;
        padding: 24px;
        margin: 16px;
      }

      @media screen and (max-width: 960px) {
        padding: 16px 14px;
      }
    }
    &--active {
      + .v-expansion-panel,
      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }
}
