.v-input {
  transition: 0.3s ease-in-out;

  &.search {
    .v-input__control .v-input__slot fieldset {
      border: 1px solid $white !important;
    }
  }

  input {
    max-height: initial;
    color: $black;
  }

  textarea {
    color: $black;
    margin-top: 1rem;

    &::placeholder {
      line-height: 20px;
      font-size: $font-size-root;
      color: $grey;
    }
  }

  .v-select__selection {
    color: $black;
    font-size: $font-size-root;
  }

  &.v-file-input {
    .v-input__prepend-outer {
      margin-top: 12px;
    }

    .v-input__icon--prepend,
    .v-input__icon--append {
      .v-icon {
        color: $primary;
      }
    }

    // .v-text-field__details {
    //   margin-top: 9px;
    // }
  }

  &.v-text-field:not(.v-textarea):not(.v-select--chips) {
    .v-input__slot {
      @media screen and (max-width: 960px) {
        min-height: 44px !important;
        height: 44px !important;
      }
    }
  }

  .v-input__slot {
    min-height: 42px !important;
    border-radius: 0.5rem !important;
    // Please use with specific class global button don't have bg color
    // background-color: $white;

    &:hover {
      fieldset {
        border: 1px solid $primary !important;
      }
    }

    .v-text-field {
      &__slot {
        font-size: $font-size-root !important;
      }

      &:not(.v-textarea--auto-grow) textarea {
        max-height: 88px;
      }

      &__suffix {
        color: $black;
        font-size: $font-size-root;
        font-weight: 400;
      }
    }

    input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:placeholder-shown {
        width: calc(100% - 20px);
      }
    }

    .v-label {
      color: $grey;
    }

    :not(.v-input--checkbox):not(.v-radio)>.v-label {
      font-size: $font-size-root !important;
      top: 50%;
      transform: translateY(-50%);

      &.v-label--active {
        top: 0;
        transform: translateY(-7px) scale(0.75);
      }
    }
  }

  &:not(.v-input--is-focused):not(.v-input--has-state):not(:hover) fieldset {
    border: 1px solid $light-grey !important;
  }

  &.v-input--is-focused {
    fieldset {
      border: 1px solid $primary !important;
    }

    .v-messages {
      color: $grey !important;
    }

    &:not(.v-file-input) {
      .v-icon {
        color: #0000008a !important;
      }
    }
  }

  .v-label {
    font-size: $font-size-root !important;
    top: 50%;
    transform: translateY(-50%);

    &.v-label--active {
      top: 0;
      transform: translateY(-7px) scale(0.75);
    }
  }

  .v-input__prepend-inner,
  .v-input__append-inner {
    margin-top: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &--checkbox {
    &.v-input--is-label-active {
      label {
        color: $primary !important;
      }

      .v-input {
        &--selection-controls__input {
          .v-icon::before {
            color: $primary;
          }
        }
      }
    }

    .v-input {
      &__slot {
        align-items: center;
        min-height: 40px !important;
      }

      &--selection-controls__input {
        width: 20px;
        height: 20px;
      }
    }
    .v-input__control {
      .v-input__slot {
        .v-label {
          color: $dark-grey;
        }
      }
    }

    .v-label {
      transform: inherit;
      color: $dark-grey;
    }
  }

  .v-radio {

    &.v-item--active,
    &:hover {
      label {
        color: $primary !important;
      }

      .v-input {
        &--selection-controls__input {
          .v-icon::before {
            color: $primary;
          }
        }
      }
    }

    &.v-item--active:hover {
      label {
        color: $primary !important;
      }
    }

    .v-input {
      &__slot {
        align-items: center;
        min-height: 40px !important;
      }

      &--selection-controls__input {
        width: 20px;
        height: 20px;

        .v-icon {
          transform: scale(0.8);
        }
      }
    }

    .v-label {
      transform: inherit;
      color: $dark-grey;
    }
  }

  &__details {
    display: flex;
    gap: 15px;
    margin-left: 12px;
    margin-top: 10px;
    font-size: $font-size-sm;
    color: $grey;

    &--title {
      color: $black;
    }

    @media screen and (max-width: 960px) {
      // display: none;
      flex-wrap: wrap;
    }
  }

  &--is-disabled {
    fieldset {
      background-color: $silver;
    }

    .v-select__selection {
      color: $black;
    }
  }

  &.v-textarea {
    .v-text-field__details {
      display: none;
    }

    textarea {
      line-height: inherit;
    }
  }

  .v-input__prepend-inner,
  .v-input__append-inner {
    margin-top: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }


  &.filter-search {
    &:not(.v-input--is-focused):not(.v-input--has-state) fieldset {
      border: 0 !important;
    }
  }
}

.v-input--selection-controls__ripple {
  &::before {
    display: none;
  }
}

.v-data-table .v-input--selection-controls__input {
  width: 20px;
  height: 20px;

  .v-icon {
    font-size: 20px;
    color: $onyx;
  }
}

// Upload component
.upload {
  .drop-zone {
    padding: 24px 41px;
    text-align: center;
    border-radius: 8px;
    background-color: rgba($secondary, 0.1);

    &__icon {
      .v-icon {
        font-size: 54px;
        color: $primary;
        opacity: 0.1;
      }
    }

    &__title {
      font-size: $font-size-subtitle;
      color: $grey-arsenic;
      font-weight: $font-semibold;
      pointer-events: none;
    }

    &__format {
      margin-top: 8px;
      font-size: $font-size-root;
      color: $grey;
      font-weight: 400;
      pointer-events: none;
    }

    &__or {
      margin: 16px 0;
      font-size: $font-size-subtitle;
      color: $grey-arsenic;
      font-weight: $font-semibold;
      pointer-events: none;
    }

    &__button {
      button {
        margin-left: -5px !important;
      }
    }
  }

  .drop-zone p {
    margin: 0;
  }

  &--selected {
    padding: 0;
    margin: 24px 0 0 0;

    li {
      margin: 24px 0;
      display: block;
    }

    &__title {
      font-size: $font-size-subtitle;
      font-weight: 400;
      color: $black;
    }

    &__size {
      font-size: $font-size-root;
      font-weight: 400;
      color: $grey;
    }

    &__format {
      font-size: $font-size-root;
      font-weight: 400;
      color: $grey;
    }

    .delete-btn {
      .vue-icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .image-zone {
    &--image {
      width: 100px;
      height: 100px;
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--title {
      font-size: $font-size-subtitle;
      font-weight: $font-semibold;
      color: $grey-arsenic;
    }

    &--size {
      font-size: $font-size-root;
      color: $grey;
    }
  }
}

.v-text-field--outlined.v-input--has-state fieldset,
.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid;
}

@media screen and (max-width: 600px) {
  .v-input {
    &--checkbox {
      .v-input {
        &__slot {
          align-items: flex-start;
        }
      }
    }
  }
}
