.v-card {
  padding: 1.25rem 1.5rem;
  color: $grey;
  border-radius: 0.5rem;
  box-shadow:
    0px 35px 10px 0px color(display-p3 0.2941 0.4235 0.8078 / 0),
    0px 23px 9px 0px color(display-p3 0.2941 0.4235 0.8078 / 0.01),
    0px 13px 8px 0px color(display-p3 0.2941 0.4235 0.8078 / 0.05),
    0px 6px 6px 0px color(display-p3 0.2941 0.4235 0.8078 / 0.09),
    0px 1px 3px 0px color(display-p3 0.2941 0.4235 0.8078 / 0.1);

  &--app-filter {
    &__close {
      cursor: pointer;
      border-radius: $border-radius-root;
      transition: all 0.25s;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .v-list {
      border-bottom: 1px solid $silver;
      padding-bottom: 10px;
    }

    .v-list-item {
      &:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: $grey;
      }

      &__title {
        font-weight: $font-semibold;
        font-size: $font-size-root;
      }

      &__content {
        .v-list-item__title {
          font-weight: 400;
          color: $eerie-black;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    padding: 1.25rem 0.8rem;
  }
}

.v-card--app-filter {
  .v-list {
    padding: 0;
  }

  .v-list-group {
    padding: 5px 0;

    .v-list-group__header {
      padding: 8px;
      min-height: initial;
      border-radius: $border-radius-root;

      .v-list-item__icon .v-icon {
        font-size: 25px; // TODO: @TOP will change to width, because we need to use vue-icon instead
      }

      &:hover {
        color: $primary;

        &:before {
          background-color: $primary;
          border-radius: $border-radius-root;
          opacity: 0.12;
        }

        .v-icon {
          color: $primary;
        }
      }

      .v-list-item__icon {
        &:first-child {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 0.75rem;
        }
      }
    }

    &.v-list-group--active .v-list-group__header {
      &:hover {
        &:before {
          opacity: 0;
        }
      }
    }
  }

  .v-list-group__items {
    padding-left: 1rem;

    .v-list-item__action {
      margin: 0 0.75rem 0 0;
      min-width: initial;
    }

    .v-list-item {
      padding: 8px 0.5rem;
      border-radius: $border-radius-root;
      min-height: initial;
      margin: 0.5rem 0;

      &:before {
        border-radius: $border-radius-root;
      }

      &:hover {

        // color: $white;
        &:before {
          background-color: $primary;
          opacity: 0.12;
        }
      }

      &__title {
        font-size: $font-size-root;
      }

      &__content {
        padding: 0;
      }

      .v-input .v-input__slot {
        min-height: initial !important;
      }
    }
  }
}

// .alert .v-card {
//   background-color: rgba(82, 107, 199, 0.2);
//   padding: 0;
//   border-radius: 0;
//   .v-card__text {
//     padding: 5px 6px;
//   }
// }