.templates-elements {
    position: relative;
    padding: 20px 15px 40px 15px;
    border: 1px solid $light-grey;
    border-radius: 8px;

    &__content {
        overflow: hidden;
    }

    &__item {
        padding: 8px 6px;
        border-radius: 8px;
        color: $grey;
    }

    &__title {
        top: -1px;
        left: 10px;
        position: absolute;
        color: $grey;
        font-size: .875rem !important;
        transform-origin: top left;
        transform: translateY(-7px) scale(.75);
        background-color: $white;
        padding: 0 4px;
    }

    &__info {
        color: $primary;
        font-size: $font-size-root;
    }

    &--active {
        color: $primary;
    }

    &--info {
        background-color: $secondary;
        color: $primary;
    }

    &__list {
        .v-treeview-node {
            font-weight: $font-semibold;
            padding: 0;

            &__prepend {
                margin: 0;
                width: 20px;
                min-width: 20px;

                .v-icon.v-icon {
                    font-size: 20px;
                }
            }

            &__label {
                .v-input--checkbox {
                    margin: 0;
                    padding: 0;
                    margin-right: 8px;

                    .v-input {
                        &__slot {
                            min-height: initial !important;
                            margin: 0;
                            width: 20px;
                        }

                        &--selection-controls__input {
                            width: 20px;
                            height: 20px;

                            .v-icon {
                                transform: scale(0.8);
                            }
                        }
                    }

                    .v-messages {
                        display: none;
                    }
                }
            }

            &__content {
                padding: 0;
                margin: 0;
            }

            &__root {
                flex-direction: row-reverse;
                min-height: 44px;
            }

            &__toggle {
                transform: none;
                width: 20px;
                height: 20px;
                transform: scale(0.9);
                color: $primary;

                &--open {
                    transform: rotate(-180deg) scale(0.9);
                }
            }

            &__children {
                padding: 0 10px 0 20px;
                position: relative;
                border-left: 1px solid $primary;
                margin-left: 17px;

                .v-treeview-node {
                    font-weight: 400;

                    &__root {
                        position: relative;
                        padding-left: 0;

                        &::before {
                            content: "";
                            height: 50%;
                            width: 10px;
                            background-color: $white;
                            position: absolute;
                            left: -20px;
                            top: 2px;
                            right: unset;
                            opacity: 1 !important;
                            border-bottom-left-radius: 4px;
                            border-top-left-radius: 4px;
                            border: 1px solid $primary;
                            border-right: 0;
                        }

                        &::after {
                            content: "";
                            height: calc(50% + 1px);
                            width: 10px;
                            min-height: unset;
                            background-color: white;
                            position: absolute;
                            left: -20px;
                            top: 0;
                            border-bottom-left-radius: 4px;
                            border-right: 0;
                            border-top: 0;
                            opacity: 1;
                        }
                    }

                    &__content {
                        margin-left: -25px;
                    }

                    .d-flex {
                        height: 40px;
                    }
                }
            }

            &__level {
                width: 0;
            }
        }
    }

    &--information {
        &__title {
            color: $black;
            font-size: $font-size-subtitle;
            margin-bottom: 16px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                border-bottom: 1px solid $lighter-blue;

                &:last-of-type {
                    border-bottom: 0;
                }
            }
        }

        &__content {
            &__title {
                width: 151px;
                padding: 10px 16px;
                font-size: $font-size-root;
                font-weight: $font-semibold;
                color: $black;

                @media screen and (max-width: 960px) {
                    padding: 10px 0px;
                    width: 100%;
                }
            }

            &__value {
                width: calc(100% - 151px);
                padding: 10px 16px;
                font-size: $font-size-root;
                color: $dark-grey;

                @media screen and (max-width: 960px) {
                    margin-top: -15px;
                    padding: 10px 0px;
                    width: 100%;
                }
            }
        }

        &__array {
            margin-top: 40px;

            .v-data-table {
                padding: 0;
                border-radius: 0;

                table {
                    thead {
                        th {
                            padding: 10px 0;
                            height: auto;
                        }
                    }

                    tbody {
                        tr {
                            &:hover {
                                background-color: transparent;
                                cursor: default;
                            }

                            td {
                                padding: 10px 0;
                                height: auto;
                                // min-width: initial;
                            }
                        }
                    }
                }

                @media screen and (max-width: 960px) {
                    // overflow-x: scroll;

                    td {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &__wrapper {
                        // min-width: 1103px;
                        overflow-x: auto;
                    }
                }
            }
        }
    }

    &__tree {
        display: flex;
        gap: 16px;
    }

    &__items {
        width: 270px !important;
        padding: 0 24px;
    }

    &__mobile {
        position: fixed;
        top: 88px;
        left: 0;
        width: 100%;
        height: calc(100vh - 88px);
        background-color: $alice-blue;
        padding: 16px 12px;
        z-index: 500;

        .v-treeview-node__children .v-treeview-node__root,
        .v-treeview-node__root {
            &::after {
                background-color: $alice-blue;
            }
        }

        &--items {
            height: calc(100vh - 88px - 80px - 50px);
            overflow: auto;
        }

        &--button {
            position: absolute;
            left: 16px;
            right: 16px;
            bottom: 12px;

            .v-btn {
                width: 100%;
            }
        }
    }

    .larger {
        .templates-elements {
            &__items {
                width: 336px !important;
            }
        }
    }
}