.item-border {
    position: relative;
    padding: 20px 15px 20px 15px;
    border: 1px solid $light-grey;
    border-radius: 8px;

    &__title {
        top: -1px;
        left: 10px;
        position: absolute;
        color: $grey;
        font-size: .875rem !important;
        transform-origin: top left;
        transform: translateY(-7px) scale(.75);
        background-color: $white;
        padding: 0 4px;
    }

    &:has(.v-radio.theme--light.v-item--active):hover {
        background-color: $secondary;
    }
    &:has(.v-radio.theme--light.v-item--active) {
        border-color: $primary;
    }
    &:has(.v-radio.theme--light):hover {
        border-color: $primary;
    }
}
