h1,
.h1 {
	font-family: $title-font;
	font-size: 34px;
	line-height: 40px;
	font-weight: $font-medium;
	color: $grey-arsenic;
}

h2,
.h2 {
	font-family: $title-font;
	font-size: 1.25rem;
	font-weight: $font-medium;
	line-height: 1.5;
	color: $black;
}

h3,
.h3 {
	font-family: $title-font;
	font-size: 24px;
	font-weight: $font-medium;
	line-height: 32px;
	color: $grey-arsenic;
}

h4,
.h4 {
	font-family: $title-font;
	font-size: 20px;
	font-weight: $font-medium;
	line-height: 34px;
	color: $eerie-black;
}

h5,
.h5 {
	font-family: $title-font;
	font-size: 16px;
	font-weight: $font-medium;
	line-height: 1.2;
	color: $eerie-black;
}

h6,
.h6 {
	font-family: $title-font;
	font-size: 14px;
	font-weight: $font-medium;
	line-height: 1.2;
	color: $eerie-black;
}

small,
.small {
	font-family: $title-font;
	font-size: 0.75rem;
	line-height: 1.4;
}

a {
	text-decoration: none;
}

p {
	line-height: 1.3;
}

.subtitle-1 {
	font-weight: $font-semibold;
}

.subtitle-2 {
	font-weight: $font-semibold;
}

/* On figma guild lien typography detail */
.text-detail {
	font-size: $font-size-sm;
	line-height: 1.25;
	color: $grey;
}

.font-weight-semibold {
	font-weight: $font-semibold;
}

.font-size-subtitle {
	font-size: $font-size-subtitle;
}

.light-grey--text {
	color: rgba(0, 0, 0, 0.37);
}

.onyx-light--text {
	color: rgba(0, 0, 0, 0.87);
}

.hr {
  border-top: 2px solid $silver;
}
