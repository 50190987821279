:root {
  --icon-cursor: pointer;
  --icon-size: 24px;
  --icon-size-small: 20px;
  --icon-size-x-small: 15px;
  --icon-margin: 0;
  --icon-color: transparent;
  --icon-stroke-width: 1px;
}

.vue-icon {
  display: inline-flex;
  align-items: center;
}
.vue-icon.small {
  height: var(--icon-size-small, 20px);
  width: var(--icon-size-small, 20px);
}
.vue-icon.small svg {
  height: 20px;
  width: 20px;
}
.vue-icon.x-small {
  height: var(--icon-size-x-small, 15px);
  width: var(--icon-size-x-small, 15px);
}
.vue-icon.x-small svg {
  height: 15px;
  width: 15px;
}
.vue-icon svg,
.vue-icon path {
  transition: fill 0.1s;
  fill: currentColor !important;
}
.vue-icon.vue-icon-source rect {
  fill: transparent !important;
}
.vue-icon.vue-icon-trash-dark {
  position: relative;
  cursor: pointer;
}
.vue-icon.vue-icon-trash-dark svg {
  z-index: 1;
}
.vue-icon.vue-icon-trash-dark::after {
  content: "";
  position: absolute;
  width: 180%;
  height: 180%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  transition: backgroundColor 0.25s;
  border-radius: 8px;
  z-index: 0;
}
.vue-icon.vue-icon-trash-dark:hover svg,
.vue-icon.vue-icon-trash-dark:hover path {
  fill: #fa586c !important;
}
.vue-icon.vue-icon-trash-dark:hover:after {
  background-color: #fee3e7;
}
.vue-icon.vue-icon-close {
  cursor: pointer;
}
.vue-icon.vue-icon-sync-alt.draggable {
  transform: rotate(90deg);
}
.vue-icon.vue-icon-sync-alt.draggable svg,
.vue-icon.vue-icon-sync-alt.draggable path {
  fill: #bdbdbd !important;
}
.vue-icon.vue-icon-cloud-upload svg,
.vue-icon.vue-icon-cloud-upload path {
  fill: #ffffff !important;
}
.vue-icon.vue-icon-add {
  cursor: pointer;
}

.splitpanes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}

.splitpanes--vertical {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.splitpanes--horizontal {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.splitpanes--dragging * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splitpanes__pane {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.splitpanes--vertical .splitpanes__pane {
  -webkit-transition: width 0.2s ease-out;
  -o-transition: width 0.2s ease-out;
  transition: width 0.2s ease-out;
}

.splitpanes--horizontal .splitpanes__pane {
  -webkit-transition: height 0.2s ease-out;
  -o-transition: height 0.2s ease-out;
  transition: height 0.2s ease-out;
}

.splitpanes--dragging .splitpanes__pane {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.splitpanes__splitter {
  -ms-touch-action: none;
  touch-action: none;
}

.splitpanes--vertical > .splitpanes__splitter {
  min-width: 1px;
  cursor: col-resize;
}

.splitpanes--horizontal > .splitpanes__splitter {
  min-height: 1px;
  cursor: row-resize;
}

.splitpanes.default-theme .splitpanes__splitter {
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  transition: all 0.5s ease-in-out;
}

.splitpanes.default-theme .splitpanes__splitter:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: all 0.5s ease-in-out;
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9502 18C11.9502 19.1 11.0502 20 9.9502 20C8.8502 20 7.9502 19.1 7.9502 18C7.9502 16.9 8.8502 16 9.9502 16C11.0502 16 11.9502 16.9 11.9502 18ZM9.9502 10C8.8502 10 7.9502 10.9 7.9502 12C7.9502 13.1 8.8502 14 9.9502 14C11.0502 14 11.9502 13.1 11.9502 12C11.9502 10.9 11.0502 10 9.9502 10ZM9.9502 4C8.8502 4 7.9502 4.9 7.9502 6C7.9502 7.1 8.8502 8 9.9502 8C11.0502 8 11.9502 7.1 11.9502 6C11.9502 4.9 11.0502 4 9.9502 4ZM15.9502 8C17.0502 8 17.9502 7.1 17.9502 6C17.9502 4.9 17.0502 4 15.9502 4C14.8502 4 13.9502 4.9 13.9502 6C13.9502 7.1 14.8502 8 15.9502 8ZM15.9502 10C14.8502 10 13.9502 10.9 13.9502 12C13.9502 13.1 14.8502 14 15.9502 14C17.0502 14 17.9502 13.1 17.9502 12C17.9502 10.9 17.0502 10 15.9502 10ZM15.9502 16C14.8502 16 13.9502 16.9 13.9502 18C13.9502 19.1 14.8502 20 15.9502 20C17.0502 20 17.9502 19.1 17.9502 18C17.9502 16.9 17.0502 16 15.9502 16Z' fill='%23181C1E' style='fill:%23181C1E;fill:color(display-p3 0.094118 0.109804 0.117647);fill-opacity:1;'/%3E%3C/svg%3E%0A");
}

.splitpanes.default-theme .splitpanes__splitter:hover {
  transition: all 0.5s ease-in-out;
  background: radial-gradient(33.53% 50% at 50% 50%, color(display-p3 0.6353 0.6902 0.8824/0.6) 0, color(display-p3 1 1 1/0) 100%), color(display-p3 1 1 1/0);
}
.splitpanes.default-theme .splitpanes__splitter:hover:before {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9502 18C11.9502 19.1 11.0502 20 9.9502 20C8.8502 20 7.9502 19.1 7.9502 18C7.9502 16.9 8.8502 16 9.9502 16C11.0502 16 11.9502 16.9 11.9502 18ZM9.9502 10C8.8502 10 7.9502 10.9 7.9502 12C7.9502 13.1 8.8502 14 9.9502 14C11.0502 14 11.9502 13.1 11.9502 12C11.9502 10.9 11.0502 10 9.9502 10ZM9.9502 4C8.8502 4 7.9502 4.9 7.9502 6C7.9502 7.1 8.8502 8 9.9502 8C11.0502 8 11.9502 7.1 11.9502 6C11.9502 4.9 11.0502 4 9.9502 4ZM15.9502 8C17.0502 8 17.9502 7.1 17.9502 6C17.9502 4.9 17.0502 4 15.9502 4C14.8502 4 13.9502 4.9 13.9502 6C13.9502 7.1 14.8502 8 15.9502 8ZM15.9502 10C14.8502 10 13.9502 10.9 13.9502 12C13.9502 13.1 14.8502 14 15.9502 14C17.0502 14 17.9502 13.1 17.9502 12C17.9502 10.9 17.0502 10 15.9502 10ZM15.9502 16C14.8502 16 13.9502 16.9 13.9502 18C13.9502 19.1 14.8502 20 15.9502 20C17.0502 20 17.9502 19.1 17.9502 18C17.9502 16.9 17.0502 16 15.9502 16Z' fill='%23526BC7' style='fill:%23526BC7;fill:color(display-p3 0.321569 0.419608 0.780392);fill-opacity:1;'/%3E%3C/svg%3E%0A");
}

.splitpanes.default-theme .splitpanes__splitter:first-child {
  cursor: auto;
}

.default-theme.splitpanes .splitpanes .splitpanes__splitter {
  z-index: 1;
}

.default-theme.splitpanes--vertical > .splitpanes__splitter,
.default-theme .splitpanes--vertical > .splitpanes__splitter {
  width: 24px;
  background-color: transparent;
}

.default-theme.splitpanes--vertical > .splitpanes__splitter:before,
.default-theme .splitpanes--vertical > .splitpanes__splitter:before,
.default-theme.splitpanes--horizontal > .splitpanes__splitter:before,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
}

.default-theme.splitpanes--horizontal > .splitpanes__splitter,
.default-theme .splitpanes--horizontal > .splitpanes__splitter {
  height: 24px;
  background-color: transparent;
}

.default-theme.splitpanes--horizontal > .splitpanes__splitter:before,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:before {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  -ms-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar-track {
  background-color: rgba(82, 107, 199, 0.1);
  border-radius: 2px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(82, 107, 199, 0.1);
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #526bc7;
}

html {
  overflow-y: auto !important;
}

body {
  background-color: #f3f5fb;
  color: #858585;
  font-size: 0.875rem;
  line-height: 1.3;
}

#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f5fb;
  /* On figma guild lien typography detail */
}
#app .v-application {
  background: transparent;
}
#app.has-side-filter .app-header .v-toolbar__content {
  justify-content: space-between;
}
#app.has-side-filter .app-header .mobile-header__btn--navigation {
  display: none !important;
}
#app .v-alert {
  margin-bottom: 0;
  border-radius: 0.5rem;
  font-weight: 700;
  font-size: 0.875rem;
  display: inline-block;
  min-height: 40px;
  padding: 8px 20px;
}
#app .v-alert__content {
  display: flex;
  align-items: center;
}
#app .v-alert__content svg,
#app .v-alert__content path {
  fill: currentColor !important;
}
@media screen and (max-width: 960px) {
  #app .v-alert {
    min-height: 44px;
    min-width: 44px;
    padding: 12px;
  }
}
#app .v-btn {
  transition: 0.3s ease-in-out;
  border-radius: 0.5rem !important;
  letter-spacing: 0.02em;
  line-height: 20px;
}
#app .v-btn:not(.v-btn--round) {
  padding: 0.875rem 1.25rem;
  font-weight: 700;
  min-height: 40px !important;
  min-width: 120px;
  box-shadow: none !important;
  text-transform: none;
}
#app .v-btn__content {
  line-height: inherit;
}
#app .v-btn.v-size--default {
  height: 52px;
}
@media screen and (max-width: 960px) {
  #app .v-btn.v-size--default {
    height: 44px !important;
  }
}
#app .v-btn.v-size--small {
  padding: 0.5rem 1.25rem;
  height: 40px;
}
#app .v-btn.v-size--x-small {
  min-height: 36px !important;
  height: 36px;
}
#app .v-btn.secondary {
  background-color: #dce3f7 !important;
  color: #526bc7;
}
#app .v-btn.secondary .vue-icon svg {
  fill: #526bc7;
}
#app .v-btn.secondary:hover, #app .v-btn.secondary:focus {
  outline-color: #dce3f7 !important;
}
#app .v-btn.white {
  background-color: #ffffff !important;
  color: #526bc7;
  outline: 1px solid #526bc7;
}
#app .v-btn.white:hover, #app .v-btn.white:focus {
  outline-color: #dce3f7;
}
#app .v-btn--filter {
  width: 100%;
}
#app .v-btn span > span {
  font-size: 14px;
}
#app .v-btn.btn-save {
  background-color: #526bc7;
  color: #ffffff;
  transition: none !important;
}
@media screen and (max-width: 960px) {
  #app .v-btn.btn-save {
    min-width: unset;
    padding: 0.625rem;
    height: 44px;
    width: 44px;
  }
  #app .v-btn.btn-save .v-btn__content .vue-icon {
    margin-right: 0 !important;
  }
  #app .v-btn.btn-save .v-btn__content span {
    display: none;
  }
}
#app .v-btn.btn-save.saved {
  background-color: rgba(0, 200, 83, 0.1);
  color: #00c853;
  transition: none !important;
}
#app .v-btn.btn-save.saved--error {
  background-color: #526bc7;
  color: #ffffff;
}
#app .v-btn.btn-primary:hover svg,
#app .v-btn.btn-primary:hover path {
  fill: #526bc7 !important;
}
#app .v-btn.btn-primary:hover i:after {
  background-color: unset;
}
#app .v-btn.btn-primary:hover:after {
  background-color: #a2b0e1 !important;
}
#app .theme--light.v-btn.v-btn--disabled.v-btn--has-bg.v-size--default {
  background-color: #a2b0e1 !important;
  color: #ededed !important;
}
#app .theme--light.v-btn.v-btn--disabled .v-icon.v-icon--left,
#app .theme--light.v-btn.v-btn--disabled .v-icon.v-icon--right,
#app .theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: #ededed !important;
}
#app .v-btn--outlined {
  color: #526bc7 !important;
  border: 1px solid #526bc7 !important;
}
#app .v-btn--outlined:hover, #app .v-btn--outlined:focus {
  background-color: #dce3f7;
}
#app .theme--light.v-btn--outlined.v-btn.v-btn--disabled {
  background-color: rgba(118, 123, 134, 0.1019607843) !important;
  color: #858585 !important;
  border-color: transparent !important;
}
#app .theme--light.v-btn--outlined.v-btn.v-btn--disabled .v-icon.v-icon--left,
#app .theme--light.v-btn--outlined.v-btn.v-btn--disabled .v-icon.v-icon--right,
#app .theme--light.v-btn--outlined.v-btn.v-btn--disabled .v-btn__loading {
  color: #858585 !important;
}
#app .v-btn.v-btn--icon {
  width: 2.25rem;
  /* 36px */
  height: 2.25rem;
  color: #858585 !important;
}
@media screen and (max-width: 960px) {
  #app .v-btn.v-btn--icon {
    width: 44px;
    height: 44px;
  }
}
#app .v-btn.v-btn--icon:hover {
  background-color: #dce3f7 !important;
  color: #526bc7 !important;
}
#app .v-btn.v-btn--icon:active, #app .v-btn.v-btn--icon:focus {
  color: #526bc7 !important;
}
#app .v-btn.v-btn--icon.danger {
  color: #858585;
}
#app .v-btn.v-btn--icon.danger:hover, #app .v-btn.v-btn--icon.danger:active, #app .v-btn.v-btn--icon.danger:focus {
  background-color: #fee3e7 !important;
  color: #fa586c !important;
}
#app .v-btn.v-btn--icon.v-size--large {
  width: 40px !important;
  height: 40px !important;
}
#app .v-btn.v-btn--icon.x-small {
  width: 1.75rem;
  height: 1.75rem;
}
#app .v-btn.v-btn--icon.x-small .v-icon {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}
#app .v-btn.v-btn--icon.delete-btn:hover {
  background-color: #fee3e7 !important;
  color: #fa586c !important;
}
#app .btn-add-row {
  width: 100%;
  padding-left: calc(5% + 1rem);
  padding-right: calc(5% + 1rem);
}
@media screen and (max-width: 960px) {
  #app .btn-add-row {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
#app .v-card {
  padding: 1.25rem 1.5rem;
  color: #858585;
  border-radius: 0.5rem;
  box-shadow: 0px 35px 10px 0px color(display-p3 0.2941 0.4235 0.8078/0), 0px 23px 9px 0px color(display-p3 0.2941 0.4235 0.8078/0.01), 0px 13px 8px 0px color(display-p3 0.2941 0.4235 0.8078/0.05), 0px 6px 6px 0px color(display-p3 0.2941 0.4235 0.8078/0.09), 0px 1px 3px 0px color(display-p3 0.2941 0.4235 0.8078/0.1);
}
#app .v-card--app-filter__close {
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.25s;
}
#app .v-card--app-filter__close:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
#app .v-card--app-filter .v-list {
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
}
#app .v-card--app-filter .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #858585;
}
#app .v-card--app-filter .v-list-item__title {
  font-weight: 600;
  font-size: 0.875rem;
}
#app .v-card--app-filter .v-list-item__content .v-list-item__title {
  font-weight: 400;
  color: #161c1e;
}
@media screen and (max-width: 960px) {
  #app .v-card {
    padding: 1.25rem 0.8rem;
  }
}
#app .v-card--app-filter .v-list {
  padding: 0;
}
#app .v-card--app-filter .v-list-group {
  padding: 5px 0;
}
#app .v-card--app-filter .v-list-group .v-list-group__header {
  padding: 8px;
  min-height: initial;
  border-radius: 8px;
}
#app .v-card--app-filter .v-list-group .v-list-group__header .v-list-item__icon .v-icon {
  font-size: 25px;
}
#app .v-card--app-filter .v-list-group .v-list-group__header:hover {
  color: #526bc7;
}
#app .v-card--app-filter .v-list-group .v-list-group__header:hover:before {
  background-color: #526bc7;
  border-radius: 8px;
  opacity: 0.12;
}
#app .v-card--app-filter .v-list-group .v-list-group__header:hover .v-icon {
  color: #526bc7;
}
#app .v-card--app-filter .v-list-group .v-list-group__header .v-list-item__icon:first-child {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.75rem;
}
#app .v-card--app-filter .v-list-group.v-list-group--active .v-list-group__header:hover:before {
  opacity: 0;
}
#app .v-card--app-filter .v-list-group__items {
  padding-left: 1rem;
}
#app .v-card--app-filter .v-list-group__items .v-list-item__action {
  margin: 0 0.75rem 0 0;
  min-width: initial;
}
#app .v-card--app-filter .v-list-group__items .v-list-item {
  padding: 8px 0.5rem;
  border-radius: 8px;
  min-height: initial;
  margin: 0.5rem 0;
}
#app .v-card--app-filter .v-list-group__items .v-list-item:before {
  border-radius: 8px;
}
#app .v-card--app-filter .v-list-group__items .v-list-item:hover:before {
  background-color: #526bc7;
  opacity: 0.12;
}
#app .v-card--app-filter .v-list-group__items .v-list-item__title {
  font-size: 0.875rem;
}
#app .v-card--app-filter .v-list-group__items .v-list-item__content {
  padding: 0;
}
#app .v-card--app-filter .v-list-group__items .v-list-item .v-input .v-input__slot {
  min-height: initial !important;
}
#app .v-chip {
  background-color: #dce3f7;
  color: #526bc7;
  padding: 1px 8px;
  height: auto;
  text-transform: capitalize;
  font-size: 0.75rem;
}
#app .v-chip__content {
  line-height: 1.5;
}
#app .v-data-table {
  border-radius: 12px;
}
@media screen and (max-width: 599px) {
  #app .v-data-table {
    padding: 12px 0;
  }
}
@media screen and (min-width: 600px) {
  #app .v-data-table {
    padding: 12px;
  }
}
#app .v-data-table[shadow] {
  box-shadow: 0px 35px 10px 0px color(display-p3 0.2941 0.4235 0.8078/0), 0px 23px 9px 0px color(display-p3 0.2941 0.4235 0.8078/0.01), 0px 13px 8px 0px color(display-p3 0.2941 0.4235 0.8078/0.05), 0px 6px 6px 0px color(display-p3 0.2941 0.4235 0.8078/0.09), 0px 1px 3px 0px color(display-p3 0.2941 0.4235 0.8078/0.1);
}
#app .v-data-table .v-btn.v-btn--icon {
  background-color: transparent;
}
#app .v-data-table.border {
  border: 1px solid #a2b0e1;
}
#app .v-data-table__icon-move {
  cursor: grab;
}
#app .v-data-table__icon-move:active {
  cursor: grabbing;
}
#app .v-data-table table thead tr:last-child th {
  border-bottom: thin solid #a2b0e1 !important;
}
#app .v-data-table table thead tr th {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-weight: 600;
  color: #424242;
  font-size: 0.875rem;
}
#app .v-data-table table tbody tr {
  transition: all 0.25s;
  border-color: #ededed;
  overflow: hidden;
  cursor: pointer;
}
#app .v-data-table table tbody tr[draggable=true].sortable-ghost {
  cursor: grabbing;
}
#app .v-data-table table tbody tr:hover {
  background-color: rgba(82, 107, 199, 0.1);
}
#app .v-data-table table tbody tr:hover td:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
#app .v-data-table table tbody tr:hover td:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
#app .v-data-table table tbody tr td {
  padding-top: 18px;
  padding-bottom: 18px;
  border-color: transparent;
  min-width: 100px;
}
#app .v-data-table--project {
  overflow-x: auto;
}
#app .v-data-table--project table tr td {
  vertical-align: top;
}
#app .v-data-table--project__description i.vue-icon {
  margin-right: 4px;
  font-size: 16px;
}
#app .v-data-table--project__img {
  width: 54px;
  border-radius: 6px;
}
#app .v-data-table--project__img * {
  width: 100%;
}
#app .v-data-table--project__tag {
  margin-top: 8px;
  margin-left: 4px;
}
#app .v-data-table--project__tag:first-child {
  margin-left: 0;
}
#app .v-data-table--project .v-data-table__wrapper {
  min-width: 1103px;
}
#app .v-data-table--selectable .v-data-table__checkbox i.v-icon {
  color: black;
  font-size: 1.25rem;
}
#app .v-data-table--selectable .v-data-table__checkbox i.v-icon.mdi-checkbox-marked {
  color: #526bc7;
}
#app .v-data-table--selectable tbody tr td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
#app .v-data-table--selectable tbody tr td:first-child {
  padding: 1.25rem 0 1.25rem 1rem;
  min-width: unset;
}
#app .v-data-table--default {
  padding: 0;
}
#app .v-data-table--draggable {
  padding: 0;
}
#app .v-data-table--draggable thead th {
  padding-right: 0;
}
#app .v-data-table--draggable thead th:first-child {
  padding-left: 41px;
  position: relative;
}
#app .v-data-table--draggable thead th:first-child::before {
  content: "";
  width: 13.5px;
  height: 16.5px;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.75 11.75L10 15.5L6.25 11.75L7.3 10.6812L9.25 12.6312V1.25H10.75V12.6312L12.7 10.6812L13.75 11.75ZM7.75 4.25L6.7 5.31875L4.75 3.36875L4.75 14.75H3.25L3.25 3.36875L1.3 5.31875L0.25 4.25L4 0.499999L7.75 4.25Z' fill='%23BDBDBD' style='fill:%23BDBDBD;fill:color(display-p3 0.7412 0.7412 0.7412);fill-opacity:1;'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
}
#app .v-data-table--draggable tbody tr td {
  line-height: 1.5 !important;
}
#app .v-data-table--draggable tbody tr td:first-child {
  padding: 12px 0;
}
#app .v-data-table--draggable tbody tr td .v-input__slot {
  min-height: unset !important;
}
#app .v-data-table-header th.sortable .v-data-table-header__icon {
  opacity: 1;
}
#app .v-data-table-header th.sortable .v-data-table-header__icon::before {
  content: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEwNjNfNDEwMDQpIj4KPHBhdGggZD0iTTEyLjA2ODcgMTIuNzU4MVY4LjI1MDYyQzEyLjA2ODcgNy44MzgxMiAxMS43MzEyIDcuNTAwNjIgMTEuMzE4NyA3LjUwMDYyQzEwLjkwNjIgNy41MDA2MiAxMC41Njg3IDcuODM4MTIgMTAuNTY4NyA4LjI1MDYyVjEyLjc1ODFIOS4yMjYyNUM4Ljg4ODc1IDEyLjc1ODEgOC43MjM3NSAxMy4xNjMxIDguOTYzNzUgMTMuMzk1NkwxMS4wNTYyIDE1LjQ4MDZDMTEuMjA2MiAxNS42MjMxIDExLjQzODcgMTUuNjIzMSAxMS41ODg3IDE1LjQ4MDZMMTMuNjgxMiAxMy4zOTU2QzEzLjkyMTIgMTMuMTYzMSAxMy43NDg3IDEyLjc1ODEgMTMuNDE4NyAxMi43NTgxSDEyLjA2ODdaTTYuNTU2MjUgMi41MTMxMkw0LjQ2Mzc1IDQuNjA1NjJDNC4yMjM3NSA0LjgzODEyIDQuMzg4NzUgNS4yNDMxMiA0LjcyNjI1IDUuMjQzMTJINi4wNjg3NVY5Ljc1MDYyQzYuMDY4NzUgMTAuMTYzMSA2LjQwNjI1IDEwLjUwMDYgNi44MTg3NSAxMC41MDA2QzcuMjMxMjUgMTAuNTAwNiA3LjU2ODc1IDEwLjE2MzEgNy41Njg3NSA5Ljc1MDYyVjUuMjQzMTJIOC45MTEyNUM5LjI0ODc1IDUuMjQzMTIgOS40MTM3NSA0LjgzODEyIDkuMTczNzUgNC42MDU2Mkw3LjA4MTI1IDIuNTEzMTJDNi45Mzg3NSAyLjM3MDYzIDYuNjk4NzUgMi4zNzA2MyA2LjU1NjI1IDIuNTEzMTJaIiBmaWxsPSIjMzIzMjMyIiBzdHlsZT0iZmlsbDojMzIzMjMyO2ZpbGw6Y29sb3IoZGlzcGxheS1wMyAwLjE5NjEgMC4xOTYxIDAuMTk2MSk7ZmlsbC1vcGFjaXR5OjE7Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMTA2M180MTAwNCI+CjxyZWN0IHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0id2hpdGUiIHN0eWxlPSJmaWxsOndoaXRlO2ZpbGwtb3BhY2l0eToxOyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wNjgzNTk0KSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=");
  width: 18px;
  height: 18px;
}
#app .theme--light.v-data-table {
  color: #858585;
}
#app .theme--light.v-data-table .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: thin solid rgba(82, 107, 199, 0.1);
}
#app .v-dialog {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 12px;
}
#app .v-dialog button.v-icon:focus:after {
  content: none;
}
#app .v-dialog button.v-icon.v-btn.primary:not(.v-btn--outlined) i.v-icon {
  color: #ffffff;
}
@media screen and (max-width: 600px) {
  #app .v-dialog button.v-btn {
    width: 100%;
  }
}
#app .v-dialog__content {
  z-index: 1000 !important;
}
@media screen and (max-width: 960px) {
  #app .v-dialog__content {
    z-index: 998 !important;
  }
}
#app .v-dialog .dialog-button-group button:nth-child(2) {
  margin-left: 1.25rem;
}
@media screen and (max-width: 600px) {
  #app .v-dialog .dialog-button-group button:nth-child(2) {
    margin-top: 1.25rem;
    margin-left: 0;
  }
}
#app .v-dialog .dialog-button-group.half-width {
  gap: 20px;
}
#app .v-dialog .dialog-button-group.half-width button:nth-child(2) {
  margin-left: inherit !important;
}
@media screen and (max-width: 600px) {
  #app .v-dialog .dialog-button-group.half-width button:nth-child(2) {
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 600px) {
  #app .v-dialog .dialog-button-group.half-width button {
    width: calc(50% - 10px);
  }
}
#app .v-dialog .vue-icon-close {
  cursor: pointer;
}
#app .v-dialog.alert {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 14px 10px !important;
  border-radius: 8px;
  background-color: #dce3f7;
  box-shadow: none;
}
#app .v-dialog.alert .v-card {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
}
#app .v-dialog.alert .v-card .v-card__text {
  padding: 0;
}
#app .dialog-import-project .button-list .v-btn {
  justify-content: flex-start;
  height: 48px !important;
  padding: 8px 14px;
}
#app .dialog-import-project .button-list .v-btn * {
  font-weight: normal;
  color: #858585;
}
#app .dialog-import-project .button-list .v-btn--active {
  border: 1px solid #526bc7;
}
#app .dialog-import-project .button-list .v-btn--active * {
  color: #526bc7;
}
@media screen and (max-width: 600px) {
  #app .dialog-login.v-dialog.alert {
    max-width: calc(100% - 64px) !important;
    left: 32px;
    margin: 0;
  }
}
#app .dialog-add-collection--select {
  margin-top: 16px;
  display: flex;
  gap: 8px;
}
#app .dialog-add-collection--select--input {
  width: calc(100% - 120px) !important;
}
#app .dialog-add-collection--select--button {
  width: 120px !important;
}
@media screen and (max-width: 600px) {
  #app .dialog-add-collection--select {
    flex-direction: column;
  }
  #app .dialog-add-collection--select--input {
    width: 100% !important;
  }
  #app .dialog-add-collection--select--button {
    width: 100% !important;
  }
}
@media screen and (min-width: 601px) and (max-width: 960px) {
  #app .dialog-add-collection--select .v-btn.v-size--default {
    height: 52px !important;
  }
}
#app .dialog-add-collection--menu {
  margin-top: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
#app .dialog-data-rile-error--content--item {
  padding: 2px 0;
}
#app .dialog-data-rile-error--content--item span {
  line-height: normal;
}
#app .dialog-share-project__email {
  width: calc(100% - 100px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#app .v-overlay--active {
  z-index: 600 !important;
}
@media screen and (max-width: 960px) {
  #app .v-dialog {
    max-height: calc(100vh - 220px) !important;
    margin-top: 110px !important;
  }
  #app .v-tree-selection__select-inner .v-select.v-tree-selection .v-input__slot {
    min-height: 44px !important;
  }
  #app .v-tree-selection__select-inner .v-input__icon--prepend-inner {
    margin-top: 0;
    height: 44px;
  }
}
#app .dropdown-title {
  color: #526bc7;
  font-weight: 600;
  font-size: 1rem;
}
#app .v-menu__content .v-select-list .v-list-item {
  padding: 0;
  margin: 1px 0;
}
#app .v-menu__content .v-select-list .v-list-item__content {
  padding: 8px;
}
#app .v-menu__content .v-select-list .v-list-item:hover::before {
  opacity: 0.16;
}
#app .v-menu__content .v-select-list .v-list-item::before {
  border-radius: 8px;
  background-color: #526bc7;
}
#app .templates-elements {
  position: relative;
  padding: 20px 15px 40px 15px;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
}
#app .templates-elements__content {
  overflow: hidden;
}
#app .templates-elements__item {
  padding: 8px 6px;
  border-radius: 8px;
  color: #858585;
}
#app .templates-elements__title {
  top: -1px;
  left: 10px;
  position: absolute;
  color: #858585;
  font-size: 0.875rem !important;
  transform-origin: top left;
  transform: translateY(-7px) scale(0.75);
  background-color: #ffffff;
  padding: 0 4px;
}
#app .templates-elements__info {
  color: #526bc7;
  font-size: 0.875rem;
}
#app .templates-elements--active {
  color: #526bc7;
}
#app .templates-elements--info {
  background-color: rgba(82, 107, 199, 0.1);
  color: #526bc7;
}
#app .templates-elements__list .v-treeview-node {
  font-weight: 600;
  padding: 0;
}
#app .templates-elements__list .v-treeview-node__prepend {
  margin: 0;
  width: 20px;
  min-width: 20px;
}
#app .templates-elements__list .v-treeview-node__prepend .v-icon.v-icon {
  font-size: 20px;
}
#app .templates-elements__list .v-treeview-node__label .v-input--checkbox {
  margin: 0;
  padding: 0;
  margin-right: 8px;
}
#app .templates-elements__list .v-treeview-node__label .v-input--checkbox .v-input__slot {
  min-height: initial !important;
  margin: 0;
  width: 20px;
}
#app .templates-elements__list .v-treeview-node__label .v-input--checkbox .v-input--selection-controls__input {
  width: 20px;
  height: 20px;
}
#app .templates-elements__list .v-treeview-node__label .v-input--checkbox .v-input--selection-controls__input .v-icon {
  transform: scale(0.8);
}
#app .templates-elements__list .v-treeview-node__label .v-input--checkbox .v-messages {
  display: none;
}
#app .templates-elements__list .v-treeview-node__content {
  padding: 0;
  margin: 0;
}
#app .templates-elements__list .v-treeview-node__root {
  flex-direction: row-reverse;
  min-height: 44px;
}
#app .templates-elements__list .v-treeview-node__toggle {
  transform: none;
  width: 20px;
  height: 20px;
  transform: scale(0.9);
  color: #526bc7;
}
#app .templates-elements__list .v-treeview-node__toggle--open {
  transform: rotate(-180deg) scale(0.9);
}
#app .templates-elements__list .v-treeview-node__children {
  padding: 0 10px 0 20px;
  position: relative;
  border-left: 1px solid #526bc7;
  margin-left: 17px;
}
#app .templates-elements__list .v-treeview-node__children .v-treeview-node {
  font-weight: 400;
}
#app .templates-elements__list .v-treeview-node__children .v-treeview-node__root {
  position: relative;
  padding-left: 0;
}
#app .templates-elements__list .v-treeview-node__children .v-treeview-node__root::before {
  content: "";
  height: 50%;
  width: 10px;
  background-color: #ffffff;
  position: absolute;
  left: -20px;
  top: 2px;
  right: unset;
  opacity: 1 !important;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid #526bc7;
  border-right: 0;
}
#app .templates-elements__list .v-treeview-node__children .v-treeview-node__root::after {
  content: "";
  height: calc(50% + 1px);
  width: 10px;
  min-height: unset;
  background-color: white;
  position: absolute;
  left: -20px;
  top: 0;
  border-bottom-left-radius: 4px;
  border-right: 0;
  border-top: 0;
  opacity: 1;
}
#app .templates-elements__list .v-treeview-node__children .v-treeview-node__content {
  margin-left: -25px;
}
#app .templates-elements__list .v-treeview-node__children .v-treeview-node .d-flex {
  height: 40px;
}
#app .templates-elements__list .v-treeview-node__level {
  width: 0;
}
#app .templates-elements--information__title {
  color: #181c1e;
  font-size: 1rem;
  margin-bottom: 16px;
}
#app .templates-elements--information ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
#app .templates-elements--information ul li {
  border-bottom: 1px solid #9fb1e5;
}
#app .templates-elements--information ul li:last-of-type {
  border-bottom: 0;
}
#app .templates-elements--information__content__title {
  width: 151px;
  padding: 10px 16px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #181c1e;
}
@media screen and (max-width: 960px) {
  #app .templates-elements--information__content__title {
    padding: 10px 0px;
    width: 100%;
  }
}
#app .templates-elements--information__content__value {
  width: calc(100% - 151px);
  padding: 10px 16px;
  font-size: 0.875rem;
  color: #757575;
}
@media screen and (max-width: 960px) {
  #app .templates-elements--information__content__value {
    margin-top: -15px;
    padding: 10px 0px;
    width: 100%;
  }
}
#app .templates-elements--information__array {
  margin-top: 40px;
}
#app .templates-elements--information__array .v-data-table {
  padding: 0;
  border-radius: 0;
}
#app .templates-elements--information__array .v-data-table table thead th {
  padding: 10px 0;
  height: auto;
}
#app .templates-elements--information__array .v-data-table table tbody tr:hover {
  background-color: transparent;
  cursor: default;
}
#app .templates-elements--information__array .v-data-table table tbody tr td {
  padding: 10px 0;
  height: auto;
}
@media screen and (max-width: 960px) {
  #app .templates-elements--information__array .v-data-table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #app .templates-elements--information__array .v-data-table__wrapper {
    overflow-x: auto;
  }
}
#app .templates-elements__tree {
  display: flex;
  gap: 16px;
}
#app .templates-elements__items {
  width: 270px !important;
  padding: 0 24px;
}
#app .templates-elements__mobile {
  position: fixed;
  top: 88px;
  left: 0;
  width: 100%;
  height: calc(100vh - 88px);
  background-color: #f3f5fb;
  padding: 16px 12px;
  z-index: 500;
}
#app .templates-elements__mobile .v-treeview-node__children .v-treeview-node__root::after,
#app .templates-elements__mobile .v-treeview-node__root::after {
  background-color: #f3f5fb;
}
#app .templates-elements__mobile--items {
  height: calc(100vh - 88px - 80px - 50px);
  overflow: auto;
}
#app .templates-elements__mobile--button {
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 12px;
}
#app .templates-elements__mobile--button .v-btn {
  width: 100%;
}
#app .templates-elements .larger .templates-elements__items {
  width: 336px !important;
}
#app .v-expansion-panels {
  row-gap: 24px;
}
#app .v-expansion-panels .v-expansion-panel {
  border-radius: 8px;
}
#app .v-expansion-panels .v-expansion-panel::before {
  box-shadow: 0 2px 0px -2px rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 0px 0 rgba(0, 0, 0, 0.12);
}
#app .v-expansion-panels .v-expansion-panel::after {
  display: none;
}
#app .v-expansion-panels .v-expansion-panel-header {
  font-size: 1rem;
  font-weight: 600;
  color: #181c1e;
}
#app .v-expansion-panels .v-expansion-panel-header__icon i {
  color: #181c1e;
}
#app .v-expansion-panels .v-expansion-panel-content__wrap {
  background-color: #F9F9F9;
  border-radius: 24px;
  padding: 24px;
  margin: 16px;
}
@media screen and (max-width: 960px) {
  #app .v-expansion-panels .v-expansion-panel-content {
    padding: 16px 14px;
  }
}
#app .v-expansion-panels .v-expansion-panel--active + .v-expansion-panel, #app .v-expansion-panels .v-expansion-panel--active:not(:first-child) {
  margin-top: 0;
}
#app .export-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 640px;
  padding: 0 41px;
}
#app .export-file__icon .v-icon {
  font-size: 112px;
  color: #526bc7;
  opacity: 0.1;
}
#app .export-file__title {
  color: #424242;
  font-size: 1rem;
  font-weight: 600;
}
#app .export-file__description {
  color: #858585;
  font-size: 0.875rem;
}
#app .row--sm {
  margin: -6px;
}
#app .row--sm .col {
  padding: 6px;
}
#app .row--sm .col-1,
#app .row--sm .col-xs-1 {
  padding: 6px;
}
#app .row--sm .col-2,
#app .row--sm .col-xs-2 {
  padding: 6px;
}
#app .row--sm .col-3,
#app .row--sm .col-xs-3 {
  padding: 6px;
}
#app .row--sm .col-4,
#app .row--sm .col-xs-4 {
  padding: 6px;
}
#app .row--sm .col-5,
#app .row--sm .col-xs-5 {
  padding: 6px;
}
#app .row--sm .col-6,
#app .row--sm .col-xs-6 {
  padding: 6px;
}
#app .row--sm .col-7,
#app .row--sm .col-xs-7 {
  padding: 6px;
}
#app .row--sm .col-8,
#app .row--sm .col-xs-8 {
  padding: 6px;
}
#app .row--sm .col-9,
#app .row--sm .col-xs-9 {
  padding: 6px;
}
#app .row--sm .col-10,
#app .row--sm .col-xs-10 {
  padding: 6px;
}
#app .row--sm .col-11,
#app .row--sm .col-xs-11 {
  padding: 6px;
}
#app .row--sm .col-12,
#app .row--sm .col-xs-12 {
  padding: 6px;
}
#app .row--sm .col-1,
#app .row--sm .col-sm-1 {
  padding: 6px;
}
#app .row--sm .col-2,
#app .row--sm .col-sm-2 {
  padding: 6px;
}
#app .row--sm .col-3,
#app .row--sm .col-sm-3 {
  padding: 6px;
}
#app .row--sm .col-4,
#app .row--sm .col-sm-4 {
  padding: 6px;
}
#app .row--sm .col-5,
#app .row--sm .col-sm-5 {
  padding: 6px;
}
#app .row--sm .col-6,
#app .row--sm .col-sm-6 {
  padding: 6px;
}
#app .row--sm .col-7,
#app .row--sm .col-sm-7 {
  padding: 6px;
}
#app .row--sm .col-8,
#app .row--sm .col-sm-8 {
  padding: 6px;
}
#app .row--sm .col-9,
#app .row--sm .col-sm-9 {
  padding: 6px;
}
#app .row--sm .col-10,
#app .row--sm .col-sm-10 {
  padding: 6px;
}
#app .row--sm .col-11,
#app .row--sm .col-sm-11 {
  padding: 6px;
}
#app .row--sm .col-12,
#app .row--sm .col-sm-12 {
  padding: 6px;
}
#app .row--sm .col-1,
#app .row--sm .col-md-1 {
  padding: 6px;
}
#app .row--sm .col-2,
#app .row--sm .col-md-2 {
  padding: 6px;
}
#app .row--sm .col-3,
#app .row--sm .col-md-3 {
  padding: 6px;
}
#app .row--sm .col-4,
#app .row--sm .col-md-4 {
  padding: 6px;
}
#app .row--sm .col-5,
#app .row--sm .col-md-5 {
  padding: 6px;
}
#app .row--sm .col-6,
#app .row--sm .col-md-6 {
  padding: 6px;
}
#app .row--sm .col-7,
#app .row--sm .col-md-7 {
  padding: 6px;
}
#app .row--sm .col-8,
#app .row--sm .col-md-8 {
  padding: 6px;
}
#app .row--sm .col-9,
#app .row--sm .col-md-9 {
  padding: 6px;
}
#app .row--sm .col-10,
#app .row--sm .col-md-10 {
  padding: 6px;
}
#app .row--sm .col-11,
#app .row--sm .col-md-11 {
  padding: 6px;
}
#app .row--sm .col-12,
#app .row--sm .col-md-12 {
  padding: 6px;
}
#app .row--sm .col-1,
#app .row--sm .col-lg-1 {
  padding: 6px;
}
#app .row--sm .col-2,
#app .row--sm .col-lg-2 {
  padding: 6px;
}
#app .row--sm .col-3,
#app .row--sm .col-lg-3 {
  padding: 6px;
}
#app .row--sm .col-4,
#app .row--sm .col-lg-4 {
  padding: 6px;
}
#app .row--sm .col-5,
#app .row--sm .col-lg-5 {
  padding: 6px;
}
#app .row--sm .col-6,
#app .row--sm .col-lg-6 {
  padding: 6px;
}
#app .row--sm .col-7,
#app .row--sm .col-lg-7 {
  padding: 6px;
}
#app .row--sm .col-8,
#app .row--sm .col-lg-8 {
  padding: 6px;
}
#app .row--sm .col-9,
#app .row--sm .col-lg-9 {
  padding: 6px;
}
#app .row--sm .col-10,
#app .row--sm .col-lg-10 {
  padding: 6px;
}
#app .row--sm .col-11,
#app .row--sm .col-lg-11 {
  padding: 6px;
}
#app .row--sm .col-12,
#app .row--sm .col-lg-12 {
  padding: 6px;
}
#app .row--sm .col-1,
#app .row--sm .col-xl-1 {
  padding: 6px;
}
#app .row--sm .col-2,
#app .row--sm .col-xl-2 {
  padding: 6px;
}
#app .row--sm .col-3,
#app .row--sm .col-xl-3 {
  padding: 6px;
}
#app .row--sm .col-4,
#app .row--sm .col-xl-4 {
  padding: 6px;
}
#app .row--sm .col-5,
#app .row--sm .col-xl-5 {
  padding: 6px;
}
#app .row--sm .col-6,
#app .row--sm .col-xl-6 {
  padding: 6px;
}
#app .row--sm .col-7,
#app .row--sm .col-xl-7 {
  padding: 6px;
}
#app .row--sm .col-8,
#app .row--sm .col-xl-8 {
  padding: 6px;
}
#app .row--sm .col-9,
#app .row--sm .col-xl-9 {
  padding: 6px;
}
#app .row--sm .col-10,
#app .row--sm .col-xl-10 {
  padding: 6px;
}
#app .row--sm .col-11,
#app .row--sm .col-xl-11 {
  padding: 6px;
}
#app .row--sm .col-12,
#app .row--sm .col-xl-12 {
  padding: 6px;
}
#app .row--sm .col-1,
#app .row--sm .col-2xl-1 {
  padding: 6px;
}
#app .row--sm .col-2,
#app .row--sm .col-2xl-2 {
  padding: 6px;
}
#app .row--sm .col-3,
#app .row--sm .col-2xl-3 {
  padding: 6px;
}
#app .row--sm .col-4,
#app .row--sm .col-2xl-4 {
  padding: 6px;
}
#app .row--sm .col-5,
#app .row--sm .col-2xl-5 {
  padding: 6px;
}
#app .row--sm .col-6,
#app .row--sm .col-2xl-6 {
  padding: 6px;
}
#app .row--sm .col-7,
#app .row--sm .col-2xl-7 {
  padding: 6px;
}
#app .row--sm .col-8,
#app .row--sm .col-2xl-8 {
  padding: 6px;
}
#app .row--sm .col-9,
#app .row--sm .col-2xl-9 {
  padding: 6px;
}
#app .row--sm .col-10,
#app .row--sm .col-2xl-10 {
  padding: 6px;
}
#app .row--sm .col-11,
#app .row--sm .col-2xl-11 {
  padding: 6px;
}
#app .row--sm .col-12,
#app .row--sm .col-2xl-12 {
  padding: 6px;
}
#app .row--sm .col-1,
#app .row--sm .col-3xl-1 {
  padding: 6px;
}
#app .row--sm .col-2,
#app .row--sm .col-3xl-2 {
  padding: 6px;
}
#app .row--sm .col-3,
#app .row--sm .col-3xl-3 {
  padding: 6px;
}
#app .row--sm .col-4,
#app .row--sm .col-3xl-4 {
  padding: 6px;
}
#app .row--sm .col-5,
#app .row--sm .col-3xl-5 {
  padding: 6px;
}
#app .row--sm .col-6,
#app .row--sm .col-3xl-6 {
  padding: 6px;
}
#app .row--sm .col-7,
#app .row--sm .col-3xl-7 {
  padding: 6px;
}
#app .row--sm .col-8,
#app .row--sm .col-3xl-8 {
  padding: 6px;
}
#app .row--sm .col-9,
#app .row--sm .col-3xl-9 {
  padding: 6px;
}
#app .row--sm .col-10,
#app .row--sm .col-3xl-10 {
  padding: 6px;
}
#app .row--sm .col-11,
#app .row--sm .col-3xl-11 {
  padding: 6px;
}
#app .row--sm .col-12,
#app .row--sm .col-3xl-12 {
  padding: 6px;
}
#app .row--xs {
  margin: -4px;
}
#app .row--xs .col {
  padding: 4px;
}
#app .row--xs .col-1,
#app .row--xs .col-xs-1 {
  padding: 4px;
}
#app .row--xs .col-2,
#app .row--xs .col-xs-2 {
  padding: 4px;
}
#app .row--xs .col-3,
#app .row--xs .col-xs-3 {
  padding: 4px;
}
#app .row--xs .col-4,
#app .row--xs .col-xs-4 {
  padding: 4px;
}
#app .row--xs .col-5,
#app .row--xs .col-xs-5 {
  padding: 4px;
}
#app .row--xs .col-6,
#app .row--xs .col-xs-6 {
  padding: 4px;
}
#app .row--xs .col-7,
#app .row--xs .col-xs-7 {
  padding: 4px;
}
#app .row--xs .col-8,
#app .row--xs .col-xs-8 {
  padding: 4px;
}
#app .row--xs .col-9,
#app .row--xs .col-xs-9 {
  padding: 4px;
}
#app .row--xs .col-10,
#app .row--xs .col-xs-10 {
  padding: 4px;
}
#app .row--xs .col-11,
#app .row--xs .col-xs-11 {
  padding: 4px;
}
#app .row--xs .col-12,
#app .row--xs .col-xs-12 {
  padding: 4px;
}
#app .row--xs .col-1,
#app .row--xs .col-sm-1 {
  padding: 4px;
}
#app .row--xs .col-2,
#app .row--xs .col-sm-2 {
  padding: 4px;
}
#app .row--xs .col-3,
#app .row--xs .col-sm-3 {
  padding: 4px;
}
#app .row--xs .col-4,
#app .row--xs .col-sm-4 {
  padding: 4px;
}
#app .row--xs .col-5,
#app .row--xs .col-sm-5 {
  padding: 4px;
}
#app .row--xs .col-6,
#app .row--xs .col-sm-6 {
  padding: 4px;
}
#app .row--xs .col-7,
#app .row--xs .col-sm-7 {
  padding: 4px;
}
#app .row--xs .col-8,
#app .row--xs .col-sm-8 {
  padding: 4px;
}
#app .row--xs .col-9,
#app .row--xs .col-sm-9 {
  padding: 4px;
}
#app .row--xs .col-10,
#app .row--xs .col-sm-10 {
  padding: 4px;
}
#app .row--xs .col-11,
#app .row--xs .col-sm-11 {
  padding: 4px;
}
#app .row--xs .col-12,
#app .row--xs .col-sm-12 {
  padding: 4px;
}
#app .row--xs .col-1,
#app .row--xs .col-md-1 {
  padding: 4px;
}
#app .row--xs .col-2,
#app .row--xs .col-md-2 {
  padding: 4px;
}
#app .row--xs .col-3,
#app .row--xs .col-md-3 {
  padding: 4px;
}
#app .row--xs .col-4,
#app .row--xs .col-md-4 {
  padding: 4px;
}
#app .row--xs .col-5,
#app .row--xs .col-md-5 {
  padding: 4px;
}
#app .row--xs .col-6,
#app .row--xs .col-md-6 {
  padding: 4px;
}
#app .row--xs .col-7,
#app .row--xs .col-md-7 {
  padding: 4px;
}
#app .row--xs .col-8,
#app .row--xs .col-md-8 {
  padding: 4px;
}
#app .row--xs .col-9,
#app .row--xs .col-md-9 {
  padding: 4px;
}
#app .row--xs .col-10,
#app .row--xs .col-md-10 {
  padding: 4px;
}
#app .row--xs .col-11,
#app .row--xs .col-md-11 {
  padding: 4px;
}
#app .row--xs .col-12,
#app .row--xs .col-md-12 {
  padding: 4px;
}
#app .row--xs .col-1,
#app .row--xs .col-lg-1 {
  padding: 4px;
}
#app .row--xs .col-2,
#app .row--xs .col-lg-2 {
  padding: 4px;
}
#app .row--xs .col-3,
#app .row--xs .col-lg-3 {
  padding: 4px;
}
#app .row--xs .col-4,
#app .row--xs .col-lg-4 {
  padding: 4px;
}
#app .row--xs .col-5,
#app .row--xs .col-lg-5 {
  padding: 4px;
}
#app .row--xs .col-6,
#app .row--xs .col-lg-6 {
  padding: 4px;
}
#app .row--xs .col-7,
#app .row--xs .col-lg-7 {
  padding: 4px;
}
#app .row--xs .col-8,
#app .row--xs .col-lg-8 {
  padding: 4px;
}
#app .row--xs .col-9,
#app .row--xs .col-lg-9 {
  padding: 4px;
}
#app .row--xs .col-10,
#app .row--xs .col-lg-10 {
  padding: 4px;
}
#app .row--xs .col-11,
#app .row--xs .col-lg-11 {
  padding: 4px;
}
#app .row--xs .col-12,
#app .row--xs .col-lg-12 {
  padding: 4px;
}
#app .row--xs .col-1,
#app .row--xs .col-xl-1 {
  padding: 4px;
}
#app .row--xs .col-2,
#app .row--xs .col-xl-2 {
  padding: 4px;
}
#app .row--xs .col-3,
#app .row--xs .col-xl-3 {
  padding: 4px;
}
#app .row--xs .col-4,
#app .row--xs .col-xl-4 {
  padding: 4px;
}
#app .row--xs .col-5,
#app .row--xs .col-xl-5 {
  padding: 4px;
}
#app .row--xs .col-6,
#app .row--xs .col-xl-6 {
  padding: 4px;
}
#app .row--xs .col-7,
#app .row--xs .col-xl-7 {
  padding: 4px;
}
#app .row--xs .col-8,
#app .row--xs .col-xl-8 {
  padding: 4px;
}
#app .row--xs .col-9,
#app .row--xs .col-xl-9 {
  padding: 4px;
}
#app .row--xs .col-10,
#app .row--xs .col-xl-10 {
  padding: 4px;
}
#app .row--xs .col-11,
#app .row--xs .col-xl-11 {
  padding: 4px;
}
#app .row--xs .col-12,
#app .row--xs .col-xl-12 {
  padding: 4px;
}
#app .row--xs .col-1,
#app .row--xs .col-2xl-1 {
  padding: 4px;
}
#app .row--xs .col-2,
#app .row--xs .col-2xl-2 {
  padding: 4px;
}
#app .row--xs .col-3,
#app .row--xs .col-2xl-3 {
  padding: 4px;
}
#app .row--xs .col-4,
#app .row--xs .col-2xl-4 {
  padding: 4px;
}
#app .row--xs .col-5,
#app .row--xs .col-2xl-5 {
  padding: 4px;
}
#app .row--xs .col-6,
#app .row--xs .col-2xl-6 {
  padding: 4px;
}
#app .row--xs .col-7,
#app .row--xs .col-2xl-7 {
  padding: 4px;
}
#app .row--xs .col-8,
#app .row--xs .col-2xl-8 {
  padding: 4px;
}
#app .row--xs .col-9,
#app .row--xs .col-2xl-9 {
  padding: 4px;
}
#app .row--xs .col-10,
#app .row--xs .col-2xl-10 {
  padding: 4px;
}
#app .row--xs .col-11,
#app .row--xs .col-2xl-11 {
  padding: 4px;
}
#app .row--xs .col-12,
#app .row--xs .col-2xl-12 {
  padding: 4px;
}
#app .row--xs .col-1,
#app .row--xs .col-3xl-1 {
  padding: 4px;
}
#app .row--xs .col-2,
#app .row--xs .col-3xl-2 {
  padding: 4px;
}
#app .row--xs .col-3,
#app .row--xs .col-3xl-3 {
  padding: 4px;
}
#app .row--xs .col-4,
#app .row--xs .col-3xl-4 {
  padding: 4px;
}
#app .row--xs .col-5,
#app .row--xs .col-3xl-5 {
  padding: 4px;
}
#app .row--xs .col-6,
#app .row--xs .col-3xl-6 {
  padding: 4px;
}
#app .row--xs .col-7,
#app .row--xs .col-3xl-7 {
  padding: 4px;
}
#app .row--xs .col-8,
#app .row--xs .col-3xl-8 {
  padding: 4px;
}
#app .row--xs .col-9,
#app .row--xs .col-3xl-9 {
  padding: 4px;
}
#app .row--xs .col-10,
#app .row--xs .col-3xl-10 {
  padding: 4px;
}
#app .row--xs .col-11,
#app .row--xs .col-3xl-11 {
  padding: 4px;
}
#app .row--xs .col-12,
#app .row--xs .col-3xl-12 {
  padding: 4px;
}
#app .gc-1 {
  column-gap: 0.25rem;
}
#app .gc-2 {
  column-gap: 0.5rem;
}
#app .gc-3 {
  column-gap: 0.75rem;
}
#app .gc-4 {
  column-gap: 1rem;
}
#app .gr-6 {
  row-gap: 1.5rem;
}
#app .v-input {
  transition: 0.3s ease-in-out;
}
#app .v-input.search .v-input__control .v-input__slot fieldset {
  border: 1px solid #ffffff !important;
}
#app .v-input input {
  max-height: initial;
  color: #181c1e;
}
#app .v-input textarea {
  color: #181c1e;
  margin-top: 1rem;
}
#app .v-input textarea::placeholder {
  line-height: 20px;
  font-size: 0.875rem;
  color: #858585;
}
#app .v-input .v-select__selection {
  color: #181c1e;
  font-size: 0.875rem;
}
#app .v-input.v-file-input .v-input__prepend-outer {
  margin-top: 12px;
}
#app .v-input.v-file-input .v-input__icon--prepend .v-icon,
#app .v-input.v-file-input .v-input__icon--append .v-icon {
  color: #526bc7;
}
@media screen and (max-width: 960px) {
  #app .v-input.v-text-field:not(.v-textarea):not(.v-select--chips) .v-input__slot {
    min-height: 44px !important;
    height: 44px !important;
  }
}
#app .v-input .v-input__slot {
  min-height: 42px !important;
  border-radius: 0.5rem !important;
}
#app .v-input .v-input__slot:hover fieldset {
  border: 1px solid #526bc7 !important;
}
#app .v-input .v-input__slot .v-text-field__slot {
  font-size: 0.875rem !important;
}
#app .v-input .v-input__slot .v-text-field:not(.v-textarea--auto-grow) textarea {
  max-height: 88px;
}
#app .v-input .v-input__slot .v-text-field__suffix {
  color: #181c1e;
  font-size: 0.875rem;
  font-weight: 400;
}
#app .v-input .v-input__slot input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#app .v-input .v-input__slot input:placeholder-shown {
  width: calc(100% - 20px);
}
#app .v-input .v-input__slot .v-label {
  color: #858585;
}
#app .v-input .v-input__slot :not(.v-input--checkbox):not(.v-radio) > .v-label {
  font-size: 0.875rem !important;
  top: 50%;
  transform: translateY(-50%);
}
#app .v-input .v-input__slot :not(.v-input--checkbox):not(.v-radio) > .v-label.v-label--active {
  top: 0;
  transform: translateY(-7px) scale(0.75);
}
#app .v-input:not(.v-input--is-focused):not(.v-input--has-state):not(:hover) fieldset {
  border: 1px solid #bdbdbd !important;
}
#app .v-input.v-input--is-focused fieldset {
  border: 1px solid #526bc7 !important;
}
#app .v-input.v-input--is-focused .v-messages {
  color: #858585 !important;
}
#app .v-input.v-input--is-focused:not(.v-file-input) .v-icon {
  color: rgba(0, 0, 0, 0.5411764706) !important;
}
#app .v-input .v-label {
  font-size: 0.875rem !important;
  top: 50%;
  transform: translateY(-50%);
}
#app .v-input .v-label.v-label--active {
  top: 0;
  transform: translateY(-7px) scale(0.75);
}
#app .v-input .v-input__prepend-inner,
#app .v-input .v-input__append-inner {
  margin-top: 0;
  display: flex;
  align-items: center;
  height: 100%;
}
#app .v-input--checkbox.v-input--is-label-active label {
  color: #526bc7 !important;
}
#app .v-input--checkbox.v-input--is-label-active .v-input--selection-controls__input .v-icon::before {
  color: #526bc7;
}
#app .v-input--checkbox .v-input__slot {
  align-items: center;
  min-height: 40px !important;
}
#app .v-input--checkbox .v-input--selection-controls__input {
  width: 20px;
  height: 20px;
}
#app .v-input--checkbox .v-input__control .v-input__slot .v-label {
  color: #757575;
}
#app .v-input--checkbox .v-label {
  transform: inherit;
  color: #757575;
}
#app .v-input .v-radio.v-item--active label, #app .v-input .v-radio:hover label {
  color: #526bc7 !important;
}
#app .v-input .v-radio.v-item--active .v-input--selection-controls__input .v-icon::before, #app .v-input .v-radio:hover .v-input--selection-controls__input .v-icon::before {
  color: #526bc7;
}
#app .v-input .v-radio.v-item--active:hover label {
  color: #526bc7 !important;
}
#app .v-input .v-radio .v-input__slot {
  align-items: center;
  min-height: 40px !important;
}
#app .v-input .v-radio .v-input--selection-controls__input {
  width: 20px;
  height: 20px;
}
#app .v-input .v-radio .v-input--selection-controls__input .v-icon {
  transform: scale(0.8);
}
#app .v-input .v-radio .v-label {
  transform: inherit;
  color: #757575;
}
#app .v-input__details {
  display: flex;
  gap: 15px;
  margin-left: 12px;
  margin-top: 10px;
  font-size: 0.75rem;
  color: #858585;
}
#app .v-input__details--title {
  color: #181c1e;
}
@media screen and (max-width: 960px) {
  #app .v-input__details {
    flex-wrap: wrap;
  }
}
#app .v-input--is-disabled fieldset {
  background-color: #ededed;
}
#app .v-input--is-disabled .v-select__selection {
  color: #181c1e;
}
#app .v-input.v-textarea .v-text-field__details {
  display: none;
}
#app .v-input.v-textarea textarea {
  line-height: inherit;
}
#app .v-input .v-input__prepend-inner,
#app .v-input .v-input__append-inner {
  margin-top: 0;
  display: flex;
  align-items: center;
  height: 100%;
}
#app .v-input.filter-search:not(.v-input--is-focused):not(.v-input--has-state) fieldset {
  border: 0 !important;
}
#app .v-input--selection-controls__ripple::before {
  display: none;
}
#app .v-data-table .v-input--selection-controls__input {
  width: 20px;
  height: 20px;
}
#app .v-data-table .v-input--selection-controls__input .v-icon {
  font-size: 20px;
  color: #000000;
}
#app .upload .drop-zone {
  padding: 24px 41px;
  text-align: center;
  border-radius: 8px;
  background-color: rgba(82, 107, 199, 0.1);
}
#app .upload .drop-zone__icon .v-icon {
  font-size: 54px;
  color: #526bc7;
  opacity: 0.1;
}
#app .upload .drop-zone__title {
  font-size: 1rem;
  color: #424242;
  font-weight: 600;
  pointer-events: none;
}
#app .upload .drop-zone__format {
  margin-top: 8px;
  font-size: 0.875rem;
  color: #858585;
  font-weight: 400;
  pointer-events: none;
}
#app .upload .drop-zone__or {
  margin: 16px 0;
  font-size: 1rem;
  color: #424242;
  font-weight: 600;
  pointer-events: none;
}
#app .upload .drop-zone__button button {
  margin-left: -5px !important;
}
#app .upload .drop-zone p {
  margin: 0;
}
#app .upload--selected {
  padding: 0;
  margin: 24px 0 0 0;
}
#app .upload--selected li {
  margin: 24px 0;
  display: block;
}
#app .upload--selected__title {
  font-size: 1rem;
  font-weight: 400;
  color: #181c1e;
}
#app .upload--selected__size {
  font-size: 0.875rem;
  font-weight: 400;
  color: #858585;
}
#app .upload--selected__format {
  font-size: 0.875rem;
  font-weight: 400;
  color: #858585;
}
#app .upload--selected .delete-btn .vue-icon svg {
  width: 20px;
  height: 20px;
}
#app .upload .image-zone--image {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}
#app .upload .image-zone--image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#app .upload .image-zone--title {
  font-size: 1rem;
  font-weight: 600;
  color: #424242;
}
#app .upload .image-zone--size {
  font-size: 0.875rem;
  color: #858585;
}
#app .v-text-field--outlined.v-input--has-state fieldset,
#app .v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid;
}
@media screen and (max-width: 600px) {
  #app .v-input--checkbox .v-input__slot {
    align-items: flex-start;
  }
}
#app .item-border {
  position: relative;
  padding: 20px 15px 20px 15px;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
}
#app .item-border__title {
  top: -1px;
  left: 10px;
  position: absolute;
  color: #858585;
  font-size: 0.875rem !important;
  transform-origin: top left;
  transform: translateY(-7px) scale(0.75);
  background-color: #ffffff;
  padding: 0 4px;
}
#app .item-border:has(.v-radio.theme--light.v-item--active):hover {
  background-color: rgba(82, 107, 199, 0.1);
}
#app .item-border:has(.v-radio.theme--light.v-item--active) {
  border-color: #526bc7;
}
#app .item-border:has(.v-radio.theme--light):hover {
  border-color: #526bc7;
}
#app .v-menu-switch-language {
  padding: 8px 0;
  cursor: pointer;
}
#app .v-menu-switch-language__arrow {
  width: 1.5rem;
  height: 1.5rem;
  padding: 6px;
  display: flex;
  align-items: center;
}
#app .v-menu__content {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 8px;
  margin-top: 10px;
}
#app .v-menu__content:not(.v-menu__content--language) .v-list {
  background-color: transparent;
  padding: 0;
}
#app .v-menu__content:not(.v-menu__content--language) .v-list .v-list-item {
  min-height: initial;
}
#app .v-menu__content--account-menu, #app .v-menu__content--language, #app .v-menu__content--user-select {
  margin-top: 0.7rem;
}
#app .v-menu__content--account-menu .v-list, #app .v-menu__content--language .v-list, #app .v-menu__content--user-select .v-list {
  padding: 0;
}
#app .v-menu__content--account-menu .v-list .v-list-item, #app .v-menu__content--language .v-list .v-list-item, #app .v-menu__content--user-select .v-list .v-list-item {
  margin-top: 4px;
  overflow: hidden;
  border-radius: 8px;
}
#app .v-menu__content--account-menu .v-list .v-list-item__title, #app .v-menu__content--language .v-list .v-list-item__title, #app .v-menu__content--user-select .v-list .v-list-item__title {
  font-size: 0.875rem;
}
#app .v-menu__content--account-menu .v-list .v-list-item:first-child, #app .v-menu__content--language .v-list .v-list-item:first-child, #app .v-menu__content--user-select .v-list .v-list-item:first-child {
  margin-top: 0;
}
#app .v-menu__content--account-menu .v-list .v-list-item .v-list-item__icon, #app .v-menu__content--language .v-list .v-list-item .v-list-item__icon, #app .v-menu__content--user-select .v-list .v-list-item .v-list-item__icon {
  margin-right: 0.5rem;
}
#app .v-menu__content--account-menu .v-list .v-list-item--link:hover:before, #app .v-menu__content--account-menu .v-list .v-list-item--link:focus:before, #app .v-menu__content--language .v-list .v-list-item--link:hover:before, #app .v-menu__content--language .v-list .v-list-item--link:focus:before, #app .v-menu__content--user-select .v-list .v-list-item--link:hover:before, #app .v-menu__content--user-select .v-list .v-list-item--link:focus:before {
  opacity: 0.12;
  border-radius: 8px;
  background-color: #526bc7;
}
#app .v-menu__content--data-table .v-list .v-list-item {
  padding: 0.81rem 1rem;
  min-height: initial;
}
#app .v-menu__content--tree-selection-menu {
  padding: 10px 0;
}
#app .v-menu__content--tree-selection-menu .v-select-list {
  padding: 0;
  box-shadow: none;
}
#app .v-menu__content--tree-selection-menu .v-treeview .v-treeview-node__level {
  width: 20px;
}
#app .v-menu__content--user-select {
  min-width: 204px !important;
}
#app .v-menu__content--user-select .v-list-item {
  padding: 16px 12px;
  margin-bottom: 8px;
}
#app .v-menu__content--user-select .v-list-item.selected-item {
  position: relative;
}
#app .v-menu__content--user-select .v-list-item.selected-item::after {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.24998 13.0633L4.64748 10.4608C4.35498 10.1683 3.88248 10.1683 3.58998 10.4608C3.29748 10.7533 3.29748 11.2258 3.58998 11.5183L6.72498 14.6533C7.01748 14.9458 7.48998 14.9458 7.78248 14.6533L15.7175 6.71828C16.01 6.42578 16.01 5.95328 15.7175 5.66078C15.425 5.36828 14.9525 5.36828 14.66 5.66078L7.24998 13.0633Z' fill='%23757575' style='fill:%23757575;fill:color(display-p3 0.4588 0.4588 0.4588);fill-opacity:1;'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
#app .v-menu__content--user-select .remove {
  cursor: pointer;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
}
#app .v-menu__content--user-select .remove:hover {
  background-color: #FEE3E7;
}
#app .v-menu__switch {
  height: 24px;
}
#app .v-menu__switch .vue-icon path,
#app .v-menu__switch .vue-icon svg {
  fill: #181c1e !important;
}
#app .data-table-paginator .v-pagination {
  gap: 9.6px;
}
#app .data-table-paginator .v-pagination .v-pagination__item,
#app .data-table-paginator .v-pagination .v-pagination__navigation {
  background: transparent;
  box-shadow: none;
}
#app .data-table-paginator .v-pagination .v-pagination__navigation {
  margin: 0;
}
#app .data-table-paginator .v-pagination .v-pagination__navigation:not(.v-pagination__navigation--disabled) i.v-icon {
  color: #526bc7;
}
#app .data-table-paginator .v-pagination .v-pagination__item {
  padding: 0;
  margin: 0;
}
#app .data-table-paginator .v-pagination .v-pagination__item:hover, #app .data-table-paginator .v-pagination .v-pagination__item.v-pagination__item--active {
  color: #ffffff;
  background: #526bc7;
}
#app .v-select.v-select--input {
  max-width: 100px;
}
#app .v-select.v-select--input .v-input__slot {
  padding-right: 0;
  padding-left: 5px;
}
#app .v-select.v-select--input .v-input__slot:before, #app .v-select.v-select--input .v-input__slot:after {
  content: none;
}
#app .v-select__slot .v-input__append-inner button {
  pointer-events: none;
}
#app .v-tree-selection__select-inner .v-input__icon--prepend-inner,
#app .v-tree-selection__select-inner .v-input__icon--clear {
  margin-top: 15px;
}
@media screen and (max-width: 960px) {
  #app .v-tree-selection__select-inner .v-input__icon--prepend-inner,
  #app .v-tree-selection__select-inner .v-input__icon--clear {
    margin-top: 5px;
  }
}
#app .v-tree-selection__select-inner .v-select.v-tree-selection .v-select-list {
  padding: 0;
}
#app .v-tree-selection__select-inner .v-select.v-tree-selection .v-select__slot .v-input__append-inner {
  align-items: flex-start;
}
@media screen and (max-width: 960px) {
  #app .v-tree-selection__select-inner .v-select.v-tree-selection .v-select__slot .v-input__append-inner {
    align-items: center;
  }
}
#app .v-tree-selection__select-inner .v-select.v-tree-selection .v-input__slot {
  min-height: 52px !important;
}
#app .v-tree-selection__select-inner .v-select.v-tree-selection .v-input__slot .v-chip--select {
  background-color: #f3f5fb;
  margin-top: 2px;
  margin-bottom: 2px;
}
#app .v-tree-selection__select-inner .v-select.v-tree-selection .v-select--input .v-select__slot .v-input__append-inner {
  align-items: center;
}
#app .v-tree-selection__select-inner .v-select.v-tree-selection .v-select--input .v-select__slot .v-select__selections input {
  min-width: initial;
}
#app .side-navigation {
  background-color: #223974;
  height: calc(100vh - 64px);
  width: 270px !important;
  position: fixed;
  top: 64px !important;
  overflow-y: scroll;
  z-index: 4;
}
@media screen and (max-width: 960px) {
  #app .side-navigation {
    transform: translateX(-100%);
    height: calc(100vh - 87px);
    top: 87px !important;
  }
}
@media screen and (max-width: 960px) {
  #app .side-navigation.open {
    width: 100% !important;
    transform: translateX(0);
    z-index: 600;
  }
}
#app .side-navigation__inner {
  padding: 1.25rem;
  height: 98%;
}
#app .side-navigation__inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #223974;
}
#app .side-navigation__inner::-webkit-scrollbar {
  width: 6px;
  background-color: #223974;
}
#app .side-navigation__inner::-webkit-scrollbar-thumb {
  background-color: #223974;
}
#app .side-navigation__header {
  font-weight: 600;
}
#app .side-navigation .v-icon,
#app .side-navigation .vue-icon {
  font-size: 20px;
}
#app .side-navigation .v-treeview-node__root {
  position: relative;
  padding: 0;
}
#app .side-navigation .v-treeview-node__root::before {
  display: none;
}
#app .side-navigation .v-treeview-node__toggle {
  position: absolute;
  right: 0;
  transform: none;
}
#app .side-navigation .v-treeview-node__toggle:focus::after {
  display: none;
}
#app .side-navigation .v-treeview-node__toggle--open {
  transform: rotate(-180deg);
}
#app .side-navigation .v-treeview-node__content {
  margin: 0;
  padding: 8px;
  transition: 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 6;
}
#app .side-navigation .v-treeview-node__prepend div {
  display: flex;
}
#app .side-navigation .v-treeview-node__prepend:has(.no-icon) {
  display: none;
}
#app .side-navigation .v-treeview-node__label {
  max-width: 170px;
}
#app .side-navigation .v-treeview-node__level {
  width: 10px;
  height: 50%;
  position: absolute;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 0;
  top: 2px;
  left: -1px;
  border-right: 0 !important;
}
#app .side-navigation .v-treeview-node__level::after {
  content: "";
  height: calc(50% + 1px);
  width: 10px;
  min-height: unset;
  position: absolute;
  top: -2px;
  left: 0;
  border-bottom-left-radius: 4px;
  border-right: 0;
  border-top: 0;
}
#app .side-navigation .v-treeview-node__level:not(:first-of-type) {
  display: none;
}
#app .side-navigation .v-treeview-node__children .v-treeview-node {
  margin-left: 17px;
}
#app .side-navigation .v-treeview-node__children .v-treeview-node__content {
  font-weight: 400;
  margin-left: 8px;
}
#app .side-navigation .v-treeview-node__children .v-treeview-node__content .v-icon,
#app .side-navigation .v-treeview-node__children .v-treeview-node__content .vue-icon {
  font-size: 18px;
}
#app .side-navigation .v-treeview-node__children .v-treeview-node__root {
  padding: 0;
}
#app .side-navigation .v-treeview-node__children .v-treeview-node__children .v-treeview-node__level {
  left: -0.8px;
}
#app .side-navigation .v-treeview > .v-treeview-node > .v-treeview-node__children > .v-treeview-node > .v-treeview-node__children > .v-treeview-node:last-child .v-treeview-node__level {
  left: 0;
  top: 0;
}
#app .side-navigation.blue {
  color: #a2b0e1;
}
#app .side-navigation.blue .v-icon,
#app .side-navigation.blue .vue-icon {
  color: #a2b0e1;
}
#app .side-navigation.blue .header-icon {
  color: #ffffff;
}
#app .side-navigation.blue .v-btn.v-btn--icon:not(.v-treeview-node__toggle):hover {
  color: #ffffff !important;
  background-color: #3d5396 !important;
}
#app .side-navigation.blue .v-btn.v-btn--icon:not(.v-treeview-node__toggle):hover ~ small {
  transition: 0.4s ease-in-out;
  color: #ffffff;
}
#app .side-navigation.blue .v-btn.v-btn--icon:not(.v-treeview-node__toggle).btn-active {
  color: #ffffff !important;
  background-color: #3d5396 !important;
  border: 1px solid #ffffff;
}
#app .side-navigation.blue .v-btn.v-btn--icon:not(.v-treeview-node__toggle).btn-active svg,
#app .side-navigation.blue .v-btn.v-btn--icon:not(.v-treeview-node__toggle).btn-active path {
  fill: #ffffff !important;
}
#app .side-navigation.blue .v-btn.v-btn--icon:not(.v-treeview-node__toggle).btn-active ~ small {
  transition: 0.4s ease-in-out;
  color: #ffffff;
}
#app .side-navigation.blue .v-input .v-icon,
#app .side-navigation.blue .v-input .vue-icon {
  color: #a2b0e1 !important;
}
#app .side-navigation.blue .v-input .v-text-field__slot input {
  color: #ffffff !important;
}
#app .side-navigation.blue .v-input .v-text-field__slot input::placeholder {
  color: #5165ae !important;
}
#app .side-navigation.blue .v-input:not(.v-input--is-focused):not(.v-input--has-state) fieldset {
  border: 1px solid #a2b0e1 !important;
}
#app .side-navigation.blue .v-treeview-node__root:has(.v-treeview-node__toggle--open) .v-treeview-node__content,
#app .side-navigation.blue .v-treeview-node__root:has(.v-treeview-node__toggle--open) .v-icon,
#app .side-navigation.blue .v-treeview-node__root:has(.v-treeview-node__toggle--open) .vue-icon {
  color: #ffffff;
}
#app .side-navigation.blue .v-treeview-node__content {
  color: #a2b0e1;
}
#app .side-navigation.blue .v-treeview-node__content:hover {
  background-color: rgba(82, 107, 199, 0.1);
  color: #ffffff;
  transition: 0.3s ease-in-out;
}
#app .side-navigation.blue .v-treeview-node__children .v-treeview-node__level {
  border: 1px solid #a2b0e1;
}
#app .side-navigation.blue .v-treeview-node__children .v-treeview-node__level::after {
  background-color: #223974;
}
#app .side-navigation.blue .v-treeview-node__children .v-treeview-node__level:not(:first-of-type) {
  display: none;
}
#app .side-navigation.blue .v-treeview > .v-treeview-node:not(:last-child) > .v-treeview-node__children > .v-treeview-node {
  border-left: 1px solid #a2b0e1;
}
#app .side-navigation.blue .v-treeview > .v-treeview-node:not(:last-child) > .v-treeview-node__children > .v-treeview-node .v-treeview-node__children .v-treeview-node:not(:last-child) {
  border-left: 1px solid #a2b0e1;
}
#app .side-navigation.white {
  color: #858585;
}
#app .side-navigation.white .v-icon:not(.header-icon),
#app .side-navigation.white .vue-icon:not(.header-icon) {
  color: #858585;
}
#app .side-navigation.white .header-icon {
  color: #526bc7;
}
#app .side-navigation.white .sidebar-head .v-btn .vue-icon {
  color: #858585 !important;
}
#app .side-navigation.white .sidebar-head .v-btn:hover .vue-icon {
  color: #526bc7 !important;
}
#app .side-navigation.white .sidebar-head .text-center .v-btn:hover + small {
  color: #526bc7 !important;
}
#app .side-navigation.white .vue-icon:not(.header-icon),
#app .side-navigation.white .v-icon:not(.header-icon) {
  color: #858585;
}
#app .side-navigation.white .header-icon {
  color: #526bc7;
}
#app .side-navigation.white .v-btn.v-btn--icon:not(.v-treeview-node__toggle):hover {
  color: #526bc7 !important;
  background-color: rgba(82, 107, 199, 0.1) !important;
  border: 1px solid #526bc7;
}
#app .side-navigation.white .v-input .v-icon,
#app .side-navigation.white .v-input .vue-icon {
  color: #858585 !important;
}
#app .side-navigation.white .v-input .v-text-field__slot input {
  color: #526bc7 !important;
}
#app .side-navigation.white .v-input .v-text-field__slot input::placeholder {
  color: #bdbdbd !important;
}
#app .side-navigation.white .v-input:not(.v-input--is-focused):not(.v-input--has-state) fieldset {
  border: 1px solid #526bc7 !important;
}
#app .side-navigation.white .v-input:not(.v-input--is-focused):not(.v-input--has-state) input::placeholder {
  color: #526bc7 !important;
}
#app .side-navigation.white .v-input:not(.v-input--is-focused):not(.v-input--has-state) .v-icon,
#app .side-navigation.white .v-input:not(.v-input--is-focused):not(.v-input--has-state) .vue-icon {
  color: #526bc7 !important;
}
#app .side-navigation.white .v-treeview-node__root {
  font-weight: 600;
  color: #858585;
}
#app .side-navigation.white .v-treeview-node__root:has(.v-treeview-node__toggle--open) {
  color: #526bc7;
}
#app .side-navigation.white .v-treeview-node__root:has(.v-treeview-node__toggle--open):not(.v-input--is-focused):not(.v-input--has-state) fieldset {
  border: 1px solid #526bc7 !important;
}
#app .side-navigation.white .v-treeview-node__root:has(.v-treeview-node__toggle--open):not(.v-input--is-focused):not(.v-input--has-state) input::placeholder {
  color: #526bc7 !important;
}
#app .side-navigation.white .v-treeview-node__root:has(.v-treeview-node__toggle--open):not(.v-input--is-focused):not(.v-input--has-state) .v-icon,
#app .side-navigation.white .v-treeview-node__root:has(.v-treeview-node__toggle--open):not(.v-input--is-focused):not(.v-input--has-state) .vue-icon {
  color: #526bc7 !important;
}
#app .side-navigation.white .v-treeview-node__root__content {
  color: #858585;
}
#app .side-navigation.white .v-treeview-node__root__content:hover {
  background-color: rgba(82, 107, 199, 0.1);
  color: #526bc7;
  transition: 0.3s ease-in-out;
}
#app .side-navigation.white .v-treeview-node__root__level {
  border: 1px solid #858585;
}
#app .side-navigation.white .v-treeview-node__root__level::after {
  background-color: #ffffff;
}
#app .side-navigation.white .v-treeview-node__root__level:not(:first-of-type) {
  display: none;
}
#app .side-navigation.white .v-treeview-node__children .v-treeview-node__level {
  border: 1px solid #858585;
}
#app .side-navigation.white .v-treeview-node__children .v-treeview-node__level::after {
  background-color: #ffffff;
}
#app .side-navigation.white .v-treeview-node__children .v-treeview-node__level:not(:first-of-type) {
  display: none;
}
#app .side-navigation.white .v-treeview-node--active {
  color: #526bc7;
  font-weight: 600;
}
#app .side-navigation.white .v-treeview-node--active .v-icon,
#app .side-navigation.white .v-treeview-node--active .vue-icon {
  color: #526bc7 !important;
}
#app .side-navigation.white .v-treeview-node .v-treeview > .v-treeview-node:not(:last-child) > .v-treeview-node__children > .v-treeview-node {
  border-left: 1px solid #526bc7;
}
#app .side-navigation.white .v-treeview-node .v-treeview > .v-treeview-node:not(:last-child) > .v-treeview-node__children > .v-treeview-node .v-treeview-node__children .v-treeview-node:not(:last-child) {
  border-left: 1px solid #526bc7;
}
#app .side-navigation.white .v-treeview > .v-treeview-node:not(:last-child) > .v-treeview-node__children > .v-treeview-node {
  border-left: 1px solid #858585;
}
#app .side-navigation.white .v-treeview > .v-treeview-node:not(:last-child) > .v-treeview-node__children > .v-treeview-node .v-treeview-node__children .v-treeview-node:not(:last-child) {
  border-left: 1px solid #858585;
}
#app .side-navigation .sidebar-head {
  margin-bottom: 15px;
}
#app .side-navigation .sidebar-body--tools {
  display: flex;
  justify-content: space-between;
}
#app .side-navigation .sidebar-body--tools .v-treeview-node__label {
  width: 100%;
  padding-right: 15px;
}
#app .side-navigation .sidebar-body--tools .sidebar-body--menu {
  width: 100%;
  max-width: 207px;
}
#app .side-navigation .sidebar-body--tools--buttons {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 18px;
  width: 28px;
  margin-right: -12px;
}
#app .side-navigation .sidebar-body--tools--buttons .v-btn {
  width: 28px;
  height: 28px !important;
  min-width: 28px;
  padding: 0;
}
#app .side-navigation .sidebar-body--tools--buttons .v-btn .vue-icon {
  width: 20px;
}
@media screen and (max-width: 960px) {
  #app .side-navigation.open {
    width: 100% !important;
    transform: translateX(0);
    z-index: 600;
  }
  #app .side-navigation.open .sidebar-body--menu {
    max-width: inherit;
  }
}
#app .side-navigation.active-tools .sidebar-body--tools--buttons .v-btn .vue-icon {
  color: #ffffff !important;
}
#app .side-navigation.active-tools .sidebar-body--tools--buttons .v-btn:hover {
  border: 1px solid #ffffff;
}
#app .v-overlay {
  z-index: 997;
}
#app .v-navigation-drawer {
  z-index: 998;
}
#app .v-navigation-drawer__content {
  overflow: visible !important;
}
@media screen and (max-width: 420px) {
  #app .sidebar-head--buttons .text-center {
    max-width: 80px;
  }
}
@media screen and (max-width: 960px) {
  #app .sidebar-head--buttons {
    margin-bottom: 20px;
  }
}
#app .v-input--switch.v-input .v-label {
  transform: inherit !important;
}
#app .vue-switch {
  position: relative;
  column-gap: 0.5rem;
}
@media screen and (max-width: 600px) {
  #app .vue-switch {
    width: min-content;
  }
}
#app .vue-switch .label {
  align-self: center;
}
#app .vue-switch .v-input--switch {
  margin-top: 0;
  padding-top: 0;
}
#app .vue-switch .v-input--switch .v-input--selection-controls__input {
  margin-right: 0;
}
#app .vue-switch.reverse {
  flex-direction: row-reverse;
}
#app .vue-switch.expand {
  width: 100%;
  justify-content: space-between;
}
#app .vue-switch.reverse-expand {
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse;
}
#app .vue-switch .v-messages {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
#app .v-tabs {
  overflow-x: scroll;
}
#app .v-tabs.v-tabs--md .v-tab {
  padding-left: 19px;
  padding-right: 23px;
}
#app .v-tabs .v-tabs-bar {
  background: transparent;
  height: 40px;
}
#app .v-tabs .v-tab {
  letter-spacing: 0.035px;
}
#app .v-tabs .v-slide-group__wrapper .v-tab {
  text-transform: initial;
}
#app .v-tabs:not(.v-tabs--vertical).v-tabs--right > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next, #app .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none;
}
#app .v-tabs .v-slide-group__prev.v-slide-group__prev--disabled,
#app .v-tabs .v-slide-group__next.v-slide-group__next--disabled {
  display: none;
}
#app .v-tabs--bold .tabs--title {
  font-weight: 700;
}
#app .v-tabs .v-tabs-bar.v-tabs-bar--show-arrows .v-slide-group__next,
#app .v-tabs .v-tabs-bar.v-tabs-bar--show-arrows .v-slide-group__prev {
  min-width: 40px;
  color: #181c1e;
}
#app .v-tabs .v-tabs-bar.v-tabs-bar--show-arrows .v-slide-group__next .v-icon,
#app .v-tabs .v-tabs-bar.v-tabs-bar--show-arrows .v-slide-group__prev .v-icon {
  color: inherit;
}
#app .v-tabs .v-tabs-bar.v-tabs-bar--show-arrows .v-slide-group__next.v-slide-group__prev--disabled, #app .v-tabs .v-tabs-bar.v-tabs-bar--show-arrows .v-slide-group__next.v-slide-group__next--disabled,
#app .v-tabs .v-tabs-bar.v-tabs-bar--show-arrows .v-slide-group__prev.v-slide-group__prev--disabled,
#app .v-tabs .v-tabs-bar.v-tabs-bar--show-arrows .v-slide-group__prev.v-slide-group__next--disabled {
  color: #858585;
  display: none;
}
#app .v-tabs::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}
#app .v-tabs::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}
#app .v-tabs::-webkit-scrollbar-thumb {
  background-color: transparent;
}
#app .v-tooltip__content--pop-hover {
  pointer-events: initial;
  opacity: 1;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  color: #181c1e;
}
@media screen and (max-width: 600px) {
  #app .v-tooltip__content--pop-hover {
    width: calc(100% - 64px);
    left: 32px !important;
  }
}
#app .v-tooltip__content--pop-hover .pop-hover--information {
  min-width: 26.25rem;
  cursor: default;
}
@media screen and (max-width: 600px) {
  #app .v-tooltip__content--pop-hover .pop-hover--information {
    min-width: unset;
  }
}
#app .v-tooltip__content--pop-hover .pop-hover--information__header__title {
  max-width: 26.1rem;
}
#app .v-tooltip__content--pop-hover .pop-hover--information__header__close {
  cursor: pointer;
}
#app .v-tooltip__content--pop-hover .pop-hover--information__content {
  max-width: 26.1rem;
}
#app .v-tooltip__content--pop-hover .pop-hover--information__details-list__item {
  display: flex;
  align-items: center;
}
#app .v-tooltip__content--pop-hover .pop-hover--information__details-list__item .v-icon {
  color: #424242;
  margin-top: 2px;
}
#app .variable-group-tree {
  color: #858585;
  min-width: 270px;
}
#app .variable-group-tree .v-treeview-node__root {
  position: relative;
  border-radius: 8px;
  padding: 0 6px !important;
  min-height: 36px !important;
  margin: 4px 0;
  color: #526bc7;
  cursor: pointer;
}
#app .variable-group-tree .v-treeview-node__root:has(.v-treeview-node__toggle--open) .v-treeview-node__content,
#app .variable-group-tree .v-treeview-node__root:has(.v-treeview-node__toggle--open) .v-icon {
  color: #526bc7;
}
#app .variable-group-tree .v-treeview-node__root::before {
  display: none;
}
#app .variable-group-tree .v-treeview-node__toggle {
  position: absolute;
  right: 0;
  transform: none;
}
#app .variable-group-tree .v-treeview-node__toggle:focus::after {
  display: none;
}
#app .variable-group-tree .v-treeview-node__toggle--open {
  transform: rotate(-180deg);
}
#app .variable-group-tree .v-treeview-node__prepend {
  display: flex;
  margin-right: 0;
}
#app .variable-group-tree .v-treeview-node__prepend:has(.no-icon) {
  display: none;
}
#app .variable-group-tree .v-treeview-node__label {
  max-width: 170px;
}
#app .variable-group-tree .v-treeview-node__level {
  width: 10px;
  height: 50%;
  position: absolute;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 0;
  top: 2px;
  left: -16px;
  /* border: 0.8px solid $font-color-grey; */
  border-right: 0 !important;
}
#app .variable-group-tree .v-treeview-node__level::after {
  content: "";
  height: calc(50% + 1px);
  width: 10px;
  min-height: unset;
  position: absolute;
  top: -2px;
  left: 0;
  border-bottom-left-radius: 4px;
  border-right: 0;
  border-top: 0;
  background-color: #ffffff;
}
#app .variable-group-tree .v-treeview-node__level:not(:first-of-type) {
  display: none;
}
#app .variable-group-tree .v-treeview-node__children {
  margin-left: 4px;
}
#app .variable-group-tree .v-treeview-node__children .v-treeview-node {
  margin-left: 17px;
}
#app .variable-group-tree .v-treeview-node__children .v-treeview-node__content {
  font-weight: 400;
  margin-left: 8px;
}
#app .variable-group-tree .v-treeview-node__children .v-treeview-node__content .v-icon {
  font-size: 18px;
}
#app .variable-group-tree .v-treeview-node__children .v-treeview-node__root {
  position: relative;
  padding: 0;
  margin-left: 15px;
}
#app .variable-group-tree .v-treeview-node__children .v-treeview-node__level {
  border: 1px solid #858585;
}
#app .variable-group-tree .v-treeview-node__children .v-treeview-node__children {
  margin-left: 21px;
}
#app .variable-group-tree .v-treeview-node__root {
  position: relative;
  color: #858585;
}
#app .variable-group-tree .v-treeview-node__root:hover {
  background-color: rgba(82, 107, 199, 0.1);
  color: #526bc7;
  transition: 0.3s ease-in-out;
}
#app .variable-group-tree .v-treeview > .v-treeview-node > .v-treeview-node__children > .v-treeview-node:not(:last-child) {
  border-left: 1px solid #858585;
}
#app .variable-group-tree .v-treeview > .v-treeview-node > .v-treeview-node__children > .v-treeview-node:not(:last-child) .v-treeview-node__children .v-treeview-node:not(:last-child) {
  border-left: 1px solid #858585;
}
#app .variable-group-tree .v-treeview-node__children > .v-treeview-node:last-child .v-treeview-node__level {
  left: -15px;
}
#app .variable-group-tree .v-treeview-node {
  position: relative;
}
#app .variable-group-tree .v-treeview > .v-treeview-node > .v-treeview-node__children > .v-treeview-node:not(:last-child):after {
  content: "";
  background: #858585;
  width: 1px;
  height: 120%;
  position: absolute;
  left: -1px;
  top: 0;
}
#app .variable-group-tree .v-treeview > .v-treeview-node > .v-treeview-node__children > .v-treeview-node:not(:last-child) .v-treeview-node__children .v-treeview-node:not(:last-child)::after {
  content: "";
  background: #858585;
  width: 1px;
  height: 120%;
  position: absolute;
  left: -1px;
  top: 0;
}
#app .variable-group-tree .v-treeview-node__children .v-treeview-node__children:last-child .v-treeview-node:not(:only-child):last-child::after {
  background: #858585;
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  top: -30px;
}
@media screen and (max-width: 599px) {
  #app .container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 600px) {
  #app .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
#app .v-main__wrap {
  padding: 0 0 2rem;
}
@media screen and (max-width: 960px) {
  #app .v-main__wrap {
    padding: 0px 8px 18px;
    margin-top: 24px;
  }
}
#app .v-main:not(.none-wrapper) .v-main__wrap {
  margin: 0 auto;
  padding: 0 2rem 2rem;
}
@media screen and (max-width: 960px) {
  #app .v-main:not(.none-wrapper) .v-main__wrap {
    padding: 0px 8px 18px;
  }
}
#app .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #858585;
}
#app .v-list-item--mark .v-list-item--active::after {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.24998 13.0633L4.64748 10.4608C4.35498 10.1683 3.88248 10.1683 3.58998 10.4608C3.29748 10.7533 3.29748 11.2258 3.58998 11.5183L6.72498 14.6533C7.01748 14.9458 7.48998 14.9458 7.78248 14.6533L15.7175 6.71828C16.01 6.42578 16.01 5.95328 15.7175 5.66078C15.425 5.36828 14.9525 5.36828 14.66 5.66078L7.24998 13.0633Z' fill='%23526bc7' style='fill:color(display-p3 0.321569 0.419608 0.780392);fill-opacity:1;'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
#app .v-menu__content--tree-selection-menu {
  margin-top: 3px;
}
#app .v-menu__content--tree-selection-menu .v-treeview .v-treeview-node__level:first-child {
  width: 0px;
}
#app .v-menu__content--tree-selection-menu .v-treeview-node__label {
  color: #858585;
  font-size: 1rem;
}
#app .v-menu__content--tree-selection-menu .v-treeview > .v-treeview-node > .v-treeview-node__root .v-treeview-node__label {
  font-weight: 600;
  color: #181c1e;
}
#app .v-picker {
  padding: unset;
}
#app .v-picker--date {
  box-shadow: none;
}
#app .v-picker--date .v-btn {
  min-width: unset;
}
#app .v-picker--date .v-date-picker-table {
  height: auto;
  padding: 0;
}
#app .v-progress-linear__background {
  left: 0 !important;
  border-radius: 1rem;
  width: 100% !important;
}
#app .v-progress-linear__determinate {
  border-radius: 1rem !important;
}
#app .login-form {
  max-width: 390px;
  margin: 0 auto;
  text-align: center;
  height: 100%;
}
#app .login-form__content {
  width: 100%;
}
#app .login-form .text-forgot-password {
  font-weight: 500;
  font-size: 0.875rem;
}
#app .login-form h1 {
  color: #424242;
}
#app .login-form .v-input__password .v-icon {
  color: rgba(133, 133, 133, 0.5216);
}
#app .dialog-login.alert {
  padding: 1rem !important;
  line-height: 1.4;
  right: 7px;
}
@media screen and (max-width: 395px) {
  #app .dialog-login.alert {
    min-width: calc(100% - 16px);
    left: 8px !important;
    margin: 0 !important;
    transform-origin: center;
  }
}
#app #collections .vue-tabs {
  margin-bottom: -6px; /* Counter the 6px height of the tabs scrollbar */
}
#app #collections .collections__list .v-data-table__wrapper {
  position: relative;
}
#app #collections .collections__list .v-data-table__wrapper table {
  border-collapse: separate;
  border-spacing: 0 4px;
}
#app #collections .collections__list .v-data-table__wrapper table thead th {
  padding-top: 0;
  padding-bottom: 0;
}
#app #collections .collections__list .v-data-table__wrapper table thead th:first-child {
  width: 20px;
  min-width: 20px;
  margin: 0;
  padding: 0 8px 0 24px;
}
#app #collections .collections__list .v-data-table__wrapper table thead th:nth-child(2) {
  padding: 0 16px 0 0;
}
#app #collections .collections__list .v-data-table__wrapper table thead th:last-child {
  width: 20px;
  min-width: 20px;
  margin: 0;
  padding: 0 24px 0 8px;
}
#app #collections .collections__list .v-data-table__wrapper table tbody tr td {
  padding-top: 14px;
  padding-bottom: 13px;
  min-height: 68px;
  height: 100%;
}
#app #collections .collections__list .v-data-table__wrapper table tbody tr td:first-child {
  width: 20px;
  min-width: 20px;
  padding: 0 8px 0 24px;
}
#app #collections .collections__list .v-data-table__wrapper table tbody tr td:nth-child(2) {
  padding-left: 0;
}
#app #collections .collections__list .v-data-table__wrapper table tbody tr td:last-child {
  width: 20px;
  min-width: 20px;
  padding: 0 24px 0 8px;
}
#app #collections .collections__list .v-data-table__wrapper table tbody tr.v-data-table__selected:not(:hover) {
  background-color: unset;
}
#app #collections .collections__list .v-data-table--item__name {
  display: flex;
  align-items: center;
  gap: 16px;
}
#app #collections .collections__list .v-data-table--item__name__icon {
  background-color: rgba(82, 107, 199, 0.1);
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#app #collections .collections__list .v-data-table--item__name__title {
  color: #161c1e;
  font-size: 0.875rem;
}
#app #collections .collections__list .v-data-table--item__name__subtitle {
  color: #858585;
  font-size: 0.75rem;
}
#app #collections .collection-options-menu {
  margin: 0;
  display: flex;
  gap: 10px;
  box-shadow: none;
}
#app #collections .collection-options-menu .vue-icon {
  line-height: 20px;
  padding: 5px;
  border-radius: 8px;
}
#app #collections .collection-options-menu .vue-icon:hover {
  background-color: #dce3f7;
  cursor: pointer;
}
@media screen and (max-width: 960px) {
  #app #collections .page-header {
    padding: 18px 0 0;
    margin-bottom: 8px;
  }
  #app #collections .page-header section {
    position: absolute;
    top: 27px;
  }
  #app #collections .page-header__title {
    margin-top: 13px;
  }
}
#app .data .v-data-table tbody tr td:first-child {
  min-width: unset;
}
#app .data .v-data-table tbody tr td:nth-child(4), #app .data .v-data-table tbody tr td:nth-child(8), #app .data .v-data-table tbody tr td:nth-child(9) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
#app .document-description .v-data-table table {
  padding: 0.75rem 0;
}
#app .document-description .v-data-table table tbody .text-start {
  padding: 18px 20px;
}
#app .document-description .v-data-table table thead .text-start {
  padding: 0.625rem 1.25rem 1.25rem;
}
@media screen and (max-width: 960px) {
  #app .document-description .w-33 {
    width: 100%;
  }
}
#app #editor .page-header {
  padding: 32px 0 24px;
}
#app #editor .project-editor--export__content {
  padding: 100px 0;
}
#app #editor .project-editor--export__content .export-file__description {
  line-height: 20px;
}
#app #editor .project-editor--export__content .export-file__icon {
  height: 112px;
}
#app #editor .project-editor--import__content {
  padding: 20px 20px 20px 20px;
}
#app #editor .project-editor--import__content .upload--selected {
  margin: 0 !important;
}
#app #editor .project-editor--import__content .upload .drop-zone__format {
  padding: 0 calc((100% - 341px) / 2);
}
#app #editor .project-editor--import__info--title {
  font-size: 1rem;
  font-weight: 600;
  color: #424242;
}
#app #editor .project-editor--import__info--content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  font-size: 0.875rem;
  color: #858585;
}
#app #editor .project-editor--import__info--content .v-input--selection-controls {
  margin-top: initial;
  padding-top: initial;
}
#app #editor .project-editor--import__info--content .v-input__slot {
  margin-bottom: 0;
}
#app #editor .project-editor--import__info--content .v-input__slot .v-label {
  transform: none;
}
#app #editor .project-editor--import__info--content .v-input__slot .v-input--selection-controls__input {
  margin-right: 12px;
}
#app #editor .project-editor--import__info--content .v-messages {
  display: none;
}
#app #editor .project-editor--import__info--content v-icon:after {
  width: auto;
}
#app #editor .project-editor--import__info--content .mdi:before {
  line-height: 20px;
  font: normal normal normal 20px/1 "Material Design Icons";
}
#app #editor .project-editor .has-checkbox .upload--selected li:last-child {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(118, 123, 134, 0.1019607843);
}
#app #editor .dialog-button-group {
  gap: 12px;
}
@media screen and (max-width: 960px) {
  #app #editor .page-header {
    padding: 18px 0;
  }
  #app #editor .page-header section {
    position: absolute;
    top: 27px;
  }
  #app #editor .page-header__title {
    margin-top: 13px;
  }
}
#app #project .user-list {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 10px;
  position: relative;
  font-size: 1rem;
}
#app #project .user-list .v-input.v-select--input {
  max-width: 90px;
  border-color: transparent;
}
#app #project .user-list .v-input.v-select--input .v-input__slot {
  max-height: 36px !important;
  min-height: initial !important;
}
#app #project .user-list .v-input.v-select--input .v-input__slot:before {
  display: none;
}
#app #project .user-list .v-input.v-select--input .v-text-field__details {
  display: none;
}
#app #project .user-list .v-input.v-select--input .v-select__selection {
  color: #181c1e;
}
#app #project .app-filter {
  position: relative;
}
#app #project .app-filter__side-bar {
  left: -2rem;
}
#app #project .navigation-tabs {
  background-color: #f3f5fb;
  position: sticky;
  top: 140px;
  z-index: 510;
}
#app #project .navigation-tabs::before, #app #project .navigation-tabs::after {
  content: "";
  position: absolute;
  top: 0;
  width: 2rem;
  height: 100%;
  background-color: #f3f5fb;
  z-index: 400;
}
#app #project .navigation-tabs::before {
  left: -2rem;
}
#app #project .navigation-tabs::after {
  right: -2rem;
}
#app #project .navigation-tabs--filters {
  top: 75px;
}
#app #project .navigation-tabs--filters::before {
  display: none;
}
#app #project .filter-container {
  display: flex;
  gap: 12px;
}
@media screen and (max-width: 960px) {
  #app #project .filter-container {
    flex-wrap: wrap;
  }
}
#app #project .filter-input {
  width: 100%;
}
@media screen and (max-width: 960px) {
  #app #project .filter-input {
    order: 2;
    width: 100%;
  }
}
#app #project .filter-button {
  width: 120px;
}
@media screen and (max-width: 960px) {
  #app #project .filter-button .v-btn--filter {
    height: 44px;
  }
}
#app #project .filter-dropdown .v-input:not(.v-input--is-focused):not(.v-input--has-state) fieldset {
  border: 0 !important;
}
#app #project .filter-dropdown .v-input__slot {
  width: 150px;
}
@media screen and (max-width: 960px) {
  #app #project .filter-dropdown .v-input__slot {
    width: calc(100vw - 166px);
  }
}
@media screen and (max-width: 960px) and (hover: none) and (pointer: coarse) {
  #app #project .filter-dropdown .v-input__slot {
    width: calc(100vw - 160px);
  }
}
#app #project .filter-chip .v-chip {
  padding: 8px 8px 8px 12px !important;
  border-radius: 48px;
}
#app #project .filter-chip .v-chip .v-chip__content {
  font-size: 0.875rem;
  line-height: 17px;
}
@media screen and (max-width: 960px) {
  #app #project .container {
    padding: 0;
    width: calc(100% - 12px) !important;
  }
}
#app #project .v-data-table--selectable thead tr th {
  padding: 20px 8px;
}
#app #project .v-data-table--selectable thead tr th:first-child,
#app #project .v-data-table--selectable tbody tr td:first-child {
  max-width: 41px;
}
#app #project .v-data-table--selectable thead tr th:first-child {
  padding-left: 16px;
}
@media screen and (max-width: 960px) {
  #app #project .page-header {
    padding: 18px 5px 0px;
  }
  #app #project .page-header section {
    position: absolute;
    top: 27px;
  }
  #app #project .page-header__title {
    margin-top: 13px;
  }
  #app #project .navigation-tabs {
    top: 155px;
  }
}
#app .project-description h1 {
  color: #161c1e;
}
#app .project-description .file_modal {
  margin: 0;
  justify-content: flex-end;
  position: absolute;
  bottom: 13px;
  right: 13px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  z-index: 100;
  cursor: pointer;
}
#app .project-description .file_modal .v-input__control {
  display: none;
}
#app .project-description .file_modal .v-input__prepend-outer {
  margin: 0;
}
#app .project-description .file_modal .v-input__icon--prepend .v-icon {
  color: white;
  font-size: 2rem;
}
#app .project-description .information-image {
  position: relative;
  width: 238px;
  margin-right: 24px;
}
@media screen and (max-width: 1264px) {
  #app .project-description .information-image {
    margin: 0 0 24px;
  }
}
@media screen and (max-width: 390px) {
  #app .project-description .information-image {
    width: 100%;
  }
  #app .project-description .information-image .v-image {
    width: 100% !important;
  }
}
#app .project-description .information-content {
  width: calc(100% - 262px);
}
@media screen and (max-width: 1264px) {
  #app .project-description .information-content {
    width: 100%;
  }
}
#app .project-description .information-box {
  background-color: #f1f4fc;
  padding: 8px 12px;
  border-radius: 8px;
}
#app .project-description .user-list {
  max-height: 200px;
  overflow-y: scroll;
  padding-right: 18px;
  margin-right: -4px;
  position: relative;
}
#app .project-description .user-list::after {
  content: "";
  width: 100%;
  height: 75px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  position: absolute;
  bottom: 0;
  pointer-events: none;
}
#app .project-description .user-list.hide-overlay::after {
  display: none;
}
#app .project-description .user-list .role-selector {
  max-width: 85px;
  max-height: 40px;
  border-color: transparent;
}
#app .project-description .user-list .role-selector .v-input__slot {
  max-height: 36px !important;
}
#app .project-description .user-list .role-selector .v-input__slot:before {
  display: none;
}
#app .project-description .user-list .role-selector .v-text-field__details {
  display: none;
}
#app .project-description .collection-card {
  height: calc(100% - 300px);
}
@media screen and (max-width: 1264px) {
  #app .project-description .collection-card {
    height: auto;
  }
}
#app .project-description .collection-list {
  gap: 4px;
}
#app .project-description h1 {
  max-width: 636px;
}
#app .project-description .alert-box {
  min-height: 40px;
}
@media screen and (max-width: 390px) {
  #app .project-description .page-header__title,
  #app .project-description h4.main-information__title {
    text-align: center;
  }
}
#app .publish .v-text-field {
  color: #181c1e;
}
#app .publish .border-lighter-blue {
  border-color: #9fb1e5;
}
#app .publish .resources-list {
  max-height: 284px;
  overflow-y: scroll;
}
#app .publish .resources-list .v-input--checkbox {
  margin-top: 0;
  padding-top: 0;
}
#app .publish .resources-list .v-input--checkbox .v-input--selection-controls__input {
  margin-right: 0;
}
#app .publish .resources-list .document {
  min-width: 300px;
  margin-left: auto;
}
@media screen and (max-width: 1080px) {
  #app .publish .resources-list .document {
    min-width: 150px;
  }
}
@media screen and (max-width: 500px) {
  #app .publish .resources-list .w-100 {
    min-width: 200px;
    max-width: 200px;
  }
}
@media screen and (max-width: 960px) {
  #app .publish .w-50 {
    width: 100%;
  }
}
#app #resources .external-resources .v-select .v-messages,
#app #resources .external-resources .v-select .v-text-field__details,
#app #resources .external-resources .v-input .v-messages,
#app #resources .external-resources .v-input .v-text-field__details {
  display: none;
}
#app #resources .external-resources .v-select--selection-controls,
#app #resources .external-resources .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
#app #resources .external-resources__content .v-data-table--item__name--title {
  color: #181c1e;
  font-size: 0.875rem;
  width: 595px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#app #resources .external-resources__content .v-data-table--item__name--description {
  color: #858585;
  font-size: 0.75rem;
  width: 595px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#app #resources .external-resources__content .v-data-table--item__type {
  white-space: nowrap;
}
#app #resources .external-resources__content .v-data-table tbody .text-start {
  padding: 10px 16px;
}
#app #resources .external-resources__content .v-data-table .v-btn:hover {
  border-radius: 8px !important;
}
#app #resources .external-resources--attachment {
  border-top: 1px solid #ededed;
  margin-top: 14px;
  padding-top: 14px;
}
#app #resources .external-resources--attachment--grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}
#app #resources .external-resources--attachment .item-border {
  padding: 12px 16px;
}
#app #resources .external-resources--attachment .item-border.v-active {
  border-color: #7589d2;
}
#app #resources .external-resources--attachment .upload--selected {
  margin: 0 !important;
}
#app #settings .settings__list__content--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 960px) {
  #app #settings .settings__list__content--row {
    flex-direction: column;
    gap: 13px;
  }
}
#app #settings .settings__list__content__title, #app #settings .settings__list__content__form {
  width: 100%;
}
#app #settings .settings__list__content__title .v-btn.v-size--default, #app #settings .settings__list__content__form .v-btn.v-size--default {
  padding-left: 19px;
  padding-right: 19px;
  letter-spacing: 0.035px;
}
@media screen and (max-width: 960px) {
  #app #settings .settings__list__content__title .v-btn.v-size--default, #app #settings .settings__list__content__form .v-btn.v-size--default {
    width: 100%;
  }
}
#app #settings .settings__list__content__title--title {
  color: #161c1e;
}
#app #settings .settings__list__content__title--subtitle {
  font-size: 0.75rem;
  color: #858585;
  max-width: 250px;
}
#app #settings .settings__list__content .v-input input {
  color: #161c1e;
}
#app #settings .settings__list__content .v-input .v-text-field__details {
  display: none;
}
#app #settings .settings__list__content .v-input__slot {
  margin: 0;
}
#app #settings .settings__list__content .v-input--radio-group {
  margin: 0;
}
#app #settings .settings__list__content .v-input--radio-group .v-messages {
  display: none;
}
#app #settings .settings__list__content--grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 13px;
}
#app #settings .settings__list__content .item-border {
  padding: 12px 16px;
}
#app #settings .settings__list__content__part-title {
  font-size: 20px;
  line-height: 170%; /* 34px */
  font-weight: 500;
  color: #161c1e;
  max-width: 420px;
}
@media screen and (max-width: 960px) {
  #app #settings .settings__list .site-login-settings .settings__list__content--grid {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
  #app #settings .settings__list .site-login-settings .settings__list__content--grid .v-input--selection-controls__input {
    margin-right: 12px;
  }
}
@media screen and (max-width: 960px) {
  #app #settings .translations .page-header {
    top: 50px;
  }
}
#app #settings .translations .v-card {
  padding: 16px;
}
#app #settings .translations__file-information {
  font-size: 0.75rem;
  line-height: 125%; /* 15px */
}
#app #settings .translations__file-information--text {
  margin: 1px 0 2px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: baseline;
}
#app #settings .translations__content .v-data-table {
  padding: 0;
}
#app #settings .translations__content .v-data-table thead th {
  padding: 0 16px 10px;
}
#app #settings .translations__list .v-data-table {
  padding: 16px;
}
#app #settings .translations__list .v-data-table--title {
  color: #161c1e;
  font-size: 20px;
  font-weight: 500;
  line-height: 170%; /* 34 px */
  letter-spacing: 0.05px;
}
#app #settings .translations__list .v-data-table thead {
  display: none;
}
#app #settings .translations__list .v-data-table--item__language {
  color: #181c1e;
}
#app #settings .translations__list .v-data-table td:last-child {
  width: 50px;
}
#app #settings .translations .v-icon {
  font-size: 18px;
  color: #526bc7;
  transition: none !important;
}
#app #settings .translations__no-data--icon {
  width: 108px;
  height: 108px;
}
#app #settings .translations__no-data--icon .v-icon {
  font-size: 108px;
  opacity: 0.1;
}
#app #settings .translations__no-data--title {
  font-size: 24px;
  color: #161c1e;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.06px;
}
#app #settings .translations__no-data--subtitle {
  font-size: 0.875rem;
  color: #858585;
  font-weight: 400;
  line-height: 20px;
}
#app #settings .translations .v-data-table {
  box-shadow: none;
}
#app #settings .translations .v-data-table thead th:nth-child(3) {
  min-width: 250px;
}
#app #settings .translations .v-data-table tbody tr:first-child td {
  padding-top: 24px;
}
#app #settings .translations .v-data-table tbody tr:last-child td {
  padding-bottom: 24px;
}
#app #settings .translations .v-data-table tbody tr {
  margin: 12px;
}
#app #settings .translations .v-data-table tbody tr td {
  padding: 12px 16px 11px;
  vertical-align: initial;
  color: #161c1e;
}
#app #settings .translations .v-data-table tbody tr:hover {
  background-color: transparent;
  cursor: default;
}
#app #settings .translations .v-data-table .v-input .v-text-field__details {
  display: none;
}
#app #settings .translations .v-data-table .v-input__slot {
  margin: 0;
}
#app #settings .translations .v-data-table--title {
  width: 315px;
}
#app #settings .translations .v-data-table--translation {
  width: 365px;
}
#app #settings .translations .translation-category-selector {
  display: none;
}
#app #settings .v-expansion-panel-header {
  padding: 16px;
  color: #161c1e;
  line-height: 24px;
}
#app #settings .v-expansion-panel-header--active {
  padding: 0 16px;
}
#app #settings .v-expansion-panel-content__wrap {
  margin: 0 16px 16px;
}
@media screen and (max-width: 599px) {
  #app #settings .v-expansion-panel-content__wrap {
    margin: 0;
  }
}
#app #settings .v-expansion-panel-content__wrap .item-border {
  padding: 13px 16px;
}
#app #settings .v-expansion-panel-content__wrap .v-input--selection-controls {
  padding-top: 0;
}
#app #settings .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 49px;
  padding-top: 16px;
  padding-bottom: 9px;
}
#app #settings .v-card--app-filter {
  border-radius: 0 8px 8px 0 !important;
  width: 240px !important;
  min-width: 240px;
  padding: 0 !important;
  overflow: hidden;
}
#app #settings .v-card--app-filter__title {
  color: #161c1e;
  font-weight: 600;
  margin: 10px 0 8px 0;
}
#app #settings .v-card--app-filter .v-select {
  height: 40px;
}
#app #settings .v-card--app-filter .v-select .v-messages,
#app #settings .v-card--app-filter .v-select .v-text-field__details {
  display: none;
}
#app #settings .v-card--app-filter .v-select .v-input__control,
#app #settings .v-card--app-filter .v-select .v-input__control button {
  max-height: 40px;
  height: 40px;
}
#app #settings .v-card--app-filter .v-list {
  padding: 0 24px;
}
#app #settings .v-card--app-filter .v-list-item {
  height: 40px !important;
}
#app #settings .v-card--app-filter .v-list-item__title {
  color: #858585;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}
#app #settings .v-card--app-filter .v-list-item:hover .v-list-item__title {
  color: #526bc7;
}
#app #settings .v-card--app-filter .v-list .v-item--active .v-list-item__title {
  color: #526bc7;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-group__header {
  padding: 8px;
  min-height: initial;
  border-radius: 8px;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-group__header .v-list-item__icon .v-icon {
  font-size: 25px;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-group__header:hover {
  color: #526bc7;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-group__header:hover:before {
  background-color: #526bc7;
  border-radius: 8px;
  opacity: 0.12;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-group__header:hover .v-icon {
  color: #526bc7;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-group__header .v-list-item__icon:first-child {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.75rem;
}
#app #settings .v-card--app-filter .v-list-item-group.v-list-group--active .v-list-group__header:hover:before {
  opacity: 0;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-item {
  padding: 8px 6px 8px 8px;
  height: 46px;
  border-radius: 8px;
  min-height: initial;
  margin: 4px 0;
  color: #858585;
  flex-wrap: wrap;
  align-content: center;
  font-weight: 600;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-item:last-child {
  margin-bottom: 10px;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-item:before {
  border-radius: 8px;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-item:hover {
  color: #526bc7;
  background-color: rgba(82, 107, 199, 0.1);
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-item:hover:before {
  background-color: #526bc7;
  opacity: 0.12;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-item:hover .v-icon {
  color: #526bc7;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-item__title {
  font-size: 0.875rem;
  line-height: normal;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-item__content {
  padding: 0;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-item__icon {
  margin-right: 3px;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-item .v-input .v-input__slot {
  min-height: initial !important;
}
#app #settings .v-card--app-filter .v-list-item-group .v-list-item-active {
  color: #526bc7;
}
#app #settings .v-card--app-filter .v-list-group__items {
  padding-left: 1rem;
}
#app #settings .v-card--app-filter .v-list-group__items .v-list-item__action {
  margin: 0 0.75rem 0 0;
  min-width: initial;
}
#app #settings .v-card--app-filter .language-selector {
  justify-content: space-between;
}
@media screen and (max-width: 960px) {
  #app #settings .page-header {
    padding: 16px 0;
  }
  #app #settings .page-header__title {
    margin-top: 3px;
  }
  #app #settings .page-header__title h1 {
    text-align: center;
    width: 100%;
  }
  #app #settings .settings__list {
    margin-top: 12px;
  }
  #app #settings .translation-page-content .translations {
    flex-direction: column;
  }
  #app #settings .translation-page-content .translations .translation-category-selector {
    display: inline-flex;
    margin: 0 12px;
    width: calc(100% - 24px);
  }
  #app #settings .translation-page-content .v-card--app-filter {
    display: none;
  }
  #app #settings .translations-edit .translations .v-card {
    padding: 1.25rem 0;
  }
  #app #settings .translations .v-card {
    padding: 1.25rem 1rem;
  }
  #app #settings .translations__file-information {
    padding: 0 12px;
  }
}
@media screen and (max-width: 599px) {
  #app #settings .translations-edit .page-header {
    padding: 18px 5px 0px;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  #app #settings .translations-edit .page-header {
    padding: 18px 32px 0px;
  }
}
@media screen and (min-width: 961px) {
  #app #settings .translations-edit .page-header {
    padding-left: 272px;
  }
}
#app #settings .dialog-category-selector {
  height: 100%;
  max-height: calc(100vh - 88px) !important;
  padding: 14px 0;
  margin: 88px 0 0 !important;
  border-radius: 0;
}
#app #settings .dialog-category-selector .v-list-item {
  padding: 0 8px;
  min-height: 40px;
}
#app #settings .dialog-category-selector .v-list-item:hover {
  background-color: #dce3f7;
  border-radius: 8px;
}
#app #settings .dialog-category-selector .v-list-item .v-list-item__title {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: normal;
}
#app #settings .dialog-category-selector .v-btn {
  position: fixed;
  bottom: 0;
  width: calc(100% - 32px);
  margin: 10px 16px;
}
#app #templates .side-content {
  padding-bottom: 1rem;
  padding-left: 1rem !important;
}
#app #templates .templates--tabs {
  background-color: #f3f5fb;
  position: sticky;
  top: 142px;
  z-index: 9;
  padding-bottom: 10px;
}
#app #templates .templates--tabs::before, #app #templates .templates--tabs::after {
  content: "";
  position: absolute;
  top: 0;
  width: 2rem;
  height: 100%;
  background-color: #f3f5fb;
  z-index: 400;
}
#app #templates .templates--tabs::before {
  left: -2rem;
}
#app #templates .templates--tabs::after {
  right: -2rem;
}
@media screen and (max-width: 960px) {
  #app #templates .templates--tabs {
    top: 173px !important;
  }
}
#app #templates .templates--mobile__type {
  width: 100% !important;
  height: calc(100vh - 87px);
  position: fixed;
  top: 87px;
  left: 0;
  z-index: 600;
  background-color: #f3f5fb;
  padding: 16px 12px;
}
#app #templates .templates--mobile__type--list {
  height: calc(100vh - 87px - 80px);
  overflow: auto;
}
#app #templates .templates--mobile__type--list .v-list {
  background-color: #f3f5fb;
}
#app #templates .templates--mobile__type--list .v-list-item__title {
  color: #858585;
  font-weight: 600;
}
#app #templates .templates--mobile__type--list .v-list-item:hover .v-list-item__title {
  color: #526bc7;
}
#app #templates .templates--mobile__type--list .v-list-item-active .v-list-item__title, #app #templates .templates--mobile__type--list .v-list-item--active .v-list-item__title {
  color: #526bc7;
}
#app #templates .templates--mobile__type--list .v-list-item-active .v-list-item__icon i, #app #templates .templates--mobile__type--list .v-list-item--active .v-list-item__icon i {
  color: #526bc7;
}
#app #templates .templates--mobile__type--list .v-list-item-active::after, #app #templates .templates--mobile__type--list .v-list-item--active::after {
  display: none;
}
#app #templates .templates--mobile__type--buttons {
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 12px;
}
#app #templates .v-card--app-filter {
  border-radius: 0 8px 8px 0 !important;
  width: 300px !important;
  padding: 0 !important;
  overflow: hidden;
  margin-left: -2rem;
}
#app #templates .v-card--app-filter__title {
  color: #424242;
  font-weight: 700;
  height: 40px;
  padding: 0 32px 0 32px !important;
  margin-top: 10px;
}
#app #templates .v-card--app-filter .v-list {
  padding: 0;
  padding: 0 24px;
}
#app #templates .v-card--app-filter .v-list-item__title {
  color: #858585;
  font-weight: 600;
}
#app #templates .v-card--app-filter .v-list-item:hover .v-list-item__title {
  color: #526bc7;
}
#app #templates .v-card--app-filter .v-list-item-active .v-list-item__title, #app #templates .v-card--app-filter .v-list-item--active .v-list-item__title {
  color: #526bc7;
}
#app #templates .v-card--app-filter .v-list-item-active .v-list-item__icon i, #app #templates .v-card--app-filter .v-list-item--active .v-list-item__icon i {
  color: #526bc7;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-group__header {
  padding: 8px;
  min-height: initial;
  border-radius: 8px;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-group__header .v-list-item__icon .v-icon {
  font-size: 25px;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-group__header:hover {
  color: #526bc7;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-group__header:hover:before {
  background-color: #526bc7;
  border-radius: 8px;
  opacity: 0.12;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-group__header:hover .v-icon {
  color: #526bc7;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-group__header .v-list-item__icon:first-child {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.75rem;
}
#app #templates .v-card--app-filter .v-list-item-group.v-list-group--active .v-list-group__header:hover:before {
  opacity: 0;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-item {
  padding: 8px;
  height: 46px;
  border-radius: 8px;
  min-height: initial;
  margin: 4px 0;
  color: #858585;
  flex-wrap: wrap;
  align-content: center;
  font-weight: 600;
  /* FIXME:: if it already have css classed in vuetify, please use class from vuetify2 and remove this classes */
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-item:last-child {
  margin-bottom: 10px;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-item:before {
  border-radius: 8px;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-item:hover {
  color: #526bc7;
  background-color: rgba(82, 107, 199, 0.1);
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-item:hover:before {
  background-color: #526bc7;
  opacity: 0.12;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-item:hover .v-icon {
  color: #526bc7;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-item__title {
  font-size: 0.875rem;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-item__content {
  padding: 0;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-item__icon {
  margin-right: 3px;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-item .v-input .v-input__slot {
  min-height: initial !important;
}
#app #templates .v-card--app-filter .v-list-item-group .v-list-item-active {
  color: #526bc7;
}
#app #templates .v-card--app-filter .v-list-group__items {
  padding-left: 1rem;
}
#app #templates .v-card--app-filter .v-list-group__items .v-list-item__action {
  margin: 0 0.75rem 0 0;
  min-width: initial;
}
#app #templates .v-data-table {
  padding: 16px 12px 20px 12px;
}
#app #templates .v-data-table--title {
  color: #181c1e;
  font-size: 20px;
  font-weight: 500;
}
#app #templates .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-color: #a2b0e1;
}
#app #templates .v-data-table th {
  padding: 10px 16px 20px 16px;
}
#app #templates .v-data-table td {
  padding: 10px 16px;
}
#app #templates .v-data-table--item {
  color: #424242;
}
#app #templates .v-data-table--item__default {
  /* FIXME:: if it already have css classed in vuetify, please use class from vuetify2 */
  display: flex;
}
#app #templates .v-data-table--item__default .v-input {
  margin: 0 auto;
}
#app #templates .v-data-table--item__default .v-messages {
  display: none;
}
#app #templates .v-data-table--item__type {
  color: #424242;
  font-size: 0.875rem;
}
#app #templates .v-data-table--item__title {
  /* FIXME:: @benjamin if it already have css classed in vuetify, please use class from vuetify2 */
  color: #181c1e;
  font-size: 0.875rem;
}
#app #templates .v-data-table--item__code {
  /* FIXME:: @benjamin if it already have css classed in vuetify, please use class from vuetify2 */
  color: #858585;
  font-size: 0.75rem;
}
#app #templates .v-data-table--item__language, #app #templates .v-data-table--item__version, #app #templates .v-data-table--item__updated {
  /* FIXME:: @benjamin if it already have css classed in vuetify, please use class from vuetify2 */
  color: #858585;
  font-size: 0.875rem;
}
#app #templates .v-data-table--item__language {
  /* FIXME:: @benjamin if it already have css classed in vuetify, please use class from vuetify2 */
  text-transform: uppercase;
}
#app #templates .container {
  padding-right: 0;
}
@media screen and (max-width: 960px) {
  #app #templates .container {
    padding: 0;
  }
  #app #templates .v-card--app-filter {
    display: none;
  }
  #app #templates .mobile__btn {
    position: absolute;
    top: 27px;
    right: 6px;
  }
}
#app #templates-page .v-input--switch label {
  color: rgba(24, 28, 30, 0.6);
  font-size: 1rem !important;
}
#app #templates-page .v-input--switch.v-input--is-label-active label {
  color: #181c1e;
}
#app #templates-page .show-information .templates-elements--information {
  margin-top: -44px;
}
#app #templates-page .show-information .templates-elements--information .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-color: #a2b0e1 !important;
}
#app #templates-page .v-input--selection-controls.v-input--switch {
  margin-top: 0;
  padding-top: 0;
}
#app #templates-page .v-input--selection-controls.v-input--switch .v-input__slot {
  min-height: initial !important;
  margin-bottom: 0;
}
#app #templates-page .v-input--selection-controls.v-input--switch .v-messages {
  display: none;
}
@media screen and (max-width: 960px) {
  #app #templates-page .page-header__title H1 {
    width: 100%;
    text-align: center;
  }
}
#app #templates-field .tabs--badge--full {
  background-color: #526bc7;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin-right: 5px;
}
#app #templates-field .tabs--badge--bordered {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #526bc7;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin-right: 5px;
}
#app #templates-field .tabs--badge--bordered::after {
  position: absolute;
  content: "";
  background-color: #526bc7;
  border: 1px solid #ffffff;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#app #templates-field .card__actions textarea {
  max-height: inherit;
}
#app #templates-field .v-data-table__checkbox.v-simple-checkbox {
  display: none !important;
}
#app #templates-field .v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
#app #templates-field .v-input--checkbox {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
#app #templates-field .v-input--checkbox .v-input--selection-controls__input .v-icon::before {
  color: #181c1e;
}
#app #templates-field .v-input--checkbox.v-input--is-label-active .v-input--selection-controls__input .v-icon::before {
  color: #526bc7;
}
#app #templates-field .v-input--checkbox .v-messages {
  display: none;
}
#app #templates-field .v-input--checkbox .v-label {
  color: #181c1e;
}
#app #templates-field .v-input--checkbox .v-input__slot {
  padding: 8px;
  margin: 4px 0;
}
#app #templates-field .options {
  gap: 24px;
  margin-left: -10px;
}
@media screen and (max-width: 960px) {
  #app #templates-field .options {
    margin-left: 0;
  }
}
#app #templates-field .controlled-vocabulary .v-data-table .v-data-table__icon-move {
  margin-right: 21px !important;
}
#app #templates-field table thead {
  height: auto !important;
}
#app #templates-field table thead tr {
  height: auto !important;
}
#app #templates-field table thead th {
  height: auto !important;
  padding: 10px 10px 10px 0;
}
#app #templates-field table tbody tr td {
  padding: 12px 0;
}
#app #templates-field table tbody tr td:first-child {
  min-width: inherit !important;
  padding: 0;
  width: 25px;
}
#app #templates-field table tbody tr td:last-child {
  min-width: inherit !important;
  padding: 0;
  width: 25px;
}
@media screen and (max-width: 960px) {
  #app #templates-field table tbody tr td {
    min-width: 180px;
  }
}
#app #templates-field .rules {
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  padding: 24px 10px 0 10px;
}
#app #templates-field .rules__checkbox {
  max-width: 820px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 55px;
  row-gap: 12px;
}
#app #templates-field .rules__checkbox .v-input--checkbox {
  width: 120px;
}
#app #templates-field .rules__checkbox .v-input--checkbox .v-input__slot {
  margin: 2px 0;
}
#app #templates-field .rules__checkbox .v-input--checkbox .v-input--selection-controls__input .v-icon::before {
  color: #757575;
}
#app #templates-field .rules__checkbox .v-input--checkbox.v-input--is-label-active .v-input--selection-controls__input .v-icon::before {
  color: #526bc7;
}
#app #templates-field .rules__title {
  color: #526bc7;
}
#app #templates-field .rules__description {
  font-size: 0.75rem;
  color: #858585;
}
#app #templates-field .rules--true {
  color: #181c1e;
}
#app #templates-field .rules table td {
  padding: 12px 0 !important;
}
#app #templates-field .rules table tr:hover {
  background: transparent !important;
  cursor: default;
}
#app #templates-field .rules table td.text-start,
#app #templates-field .rules table th.text-start {
  display: none;
}
#app #templates-field .rules .v-input__slot {
  align-items: center;
}
#app #templates-field .rules .v-input .v-label {
  transform: inherit;
  color: #757575;
}
#app #templates-field .rules .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
  height: 40px;
}
#app #templates-field .rules .v-input--is-label-active label {
  color: #526bc7 !important;
}
#app #templates-field .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-color: #a2b0e1 !important;
}
#app #templates-field .btn-add-row.v-btn {
  width: calc(100% - 129px) !important;
  margin-left: -16px;
}
@media screen and (max-width: 960px) {
  #app #templates-field .btn-add-row.v-btn {
    width: 100% !important;
    margin-left: 0;
  }
}
#app #templates-field .metadata-producers__content__container {
  display: flex;
  gap: 16px;
}
#app #templates-field .metadata-producers__content__choices {
  width: 274px !important;
}
#app #templates-field .metadata-producers__content__choices .v-list {
  width: 274px;
}
#app #templates-field .metadata-producers__content__choices .v-list .v-item-group {
  margin: 0 12px;
}
#app #templates-field .metadata-producers__content__choices .v-list .v-item-group .v-list-item {
  height: 40px;
  margin: 4px 0;
  border-radius: 8px;
  padding: 8px 8px 8px 14px;
  overflow: hidden;
  transition: 0.4s all;
}
#app #templates-field .metadata-producers__content__choices .v-list .v-item-group .v-list-item__icon {
  color: #858585;
  margin-right: 12px;
  min-width: 20px;
  display: initial;
  align-self: initial;
}
#app #templates-field .metadata-producers__content__choices .v-list .v-item-group .v-list-item__icon i {
  font-size: 20px;
}
#app #templates-field .metadata-producers__content__choices .v-list .v-item-group .v-list-item__title {
  font-size: 0.875rem;
  color: #858585;
}
#app #templates-field .metadata-producers__content__choices .v-list .v-item-group .v-list-item--active, #app #templates-field .metadata-producers__content__choices .v-list .v-item-group .v-list-item:hover {
  color: #526bc7 !important;
  background-color: rgba(82, 107, 199, 0.1);
  transition: 0.4s all;
}
#app #templates-field .metadata-producers__content__choices .v-list .v-item-group .v-list-item--active .v-list-item__title,
#app #templates-field .metadata-producers__content__choices .v-list .v-item-group .v-list-item--active .v-list-item__icon,
#app #templates-field .metadata-producers__content__choices .v-list .v-item-group .v-list-item--active .v-btn.v-btn--icon, #app #templates-field .metadata-producers__content__choices .v-list .v-item-group .v-list-item:hover .v-list-item__title,
#app #templates-field .metadata-producers__content__choices .v-list .v-item-group .v-list-item:hover .v-list-item__icon,
#app #templates-field .metadata-producers__content__choices .v-list .v-item-group .v-list-item:hover .v-btn.v-btn--icon {
  color: #526bc7 !important;
}
#app #templates-field .metadata-producers__content__choices .v-list-item--active:after {
  content: none !important;
}
#app #templates-field .metadata-producers__content__data {
  width: 100%;
}
#app #templates-field .metadata-producers__content__data .tabs {
  min-width: 100%;
  max-width: 0;
}
#app #templates-field .metadata-producers__content__available-items {
  margin: 0 12px;
  padding: 16px 12px;
  color: #526bc7;
}
#app #templates-field .metadata-producers__options table tbody tr td {
  padding-right: 16px;
}
@media screen and (max-width: 960px) {
  #app #templates-field .metadata-producers__content__container {
    flex-direction: column;
  }
  #app #templates-field .metadata-producers__content__choices {
    width: 100% !important;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(189, 189, 189, 0.3);
  }
  #app #templates-field .metadata-producers__content__choices .v-list {
    width: 100%;
  }
  #app #templates-field .metadata-producers__content__choices .v-list .v-item-group {
    margin: 0;
  }
  #app #templates-field .metadata-producers__content__data {
    padding-top: 8px;
  }
  #app #templates-field .metadata-producers__content__available-items {
    margin: 0;
    padding: 11px 12px;
  }
}
@media screen and (max-width: 960px) {
  #app #templates-field .page-header__title H1 {
    width: 100%;
    text-align: center;
  }
}
#app #users .page-header {
  padding: 32px 0 24px;
}
@media screen and (max-width: 960px) {
  #app #users .page-header {
    top: 64px;
  }
}
#app #users .users__list__search .v-message,
#app #users .users__list__search .v-text-field__details {
  display: none;
}
#app #users .users__list__search label {
  color: #858585;
}
#app #users .users__list__search fieldset:not(:hover) {
  border: 0 !important;
}
#app #users .users__list__search .v-input__slot:hover fieldset {
  border: 1px solid #526bc7 !important;
}
#app #users .users__list .v-data-table table {
  border-collapse: separate;
  border-spacing: 0 4px;
}
#app #users .users__list .v-data-table table thead th:last-child {
  width: 20px;
  min-width: 20px;
  margin: 0;
  padding: 8px;
}
#app #users .users__list .v-data-table table tbody tr td {
  line-height: 20px;
}
#app #users .users__list .v-data-table table tbody tr td:last-child {
  width: 20px;
  min-width: 20px;
  margin: 0;
  padding: 8px;
  height: 56px;
}
#app #users .users__list .v-data-table__checkbox .v-icon.mdi-checkbox-marked {
  color: #526bc7;
}
#app #users .users__list .v-data-table--item__username, #app #users .users__list .v-data-table--item__role {
  color: #181c1e;
}
#app #users .users__list .v-data-table--item__role {
  text-transform: capitalize;
}
#app #users .users__list .v-data-table--item__status {
  color: #858585;
  position: relative;
  text-transform: capitalize;
}
#app #users .users__list .v-data-table--item__status::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 100%;
  background-color: #858585;
}
#app #users .users__list .v-data-table--item__status.status--blocked::before {
  background-color: #fa586c;
}
#app #users .users__list .v-data-table--item__status.status--active::before {
  background-color: #00c853;
}
#app #users .users__create {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
#app #users .users__create__content {
  width: 800px;
}
#app #users .users__create__title {
  color: #181c1e;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 16px;
}
#app #users .users__create__form--grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
}
#app #users .users__create__form .v-select .v-messages,
#app #users .users__create__form .v-select .v-text-field__details {
  display: none;
}
#app #users .users__create__form .v-input--checkbox {
  margin: 0;
  padding: 0;
}
#app #users .users__create__form .v-input--checkbox .v-input__slot {
  max-height: 32px;
  margin-bottom: 0;
}
#app #users .users__create__form .v-input--checkbox .v-input__slot .v-label {
  transform: none;
}
#app #users .users__create__form .v-input--checkbox .v-messages {
  display: none;
}
#app #users .users__create__form .item-border {
  padding: 12px 16px;
}
#app #users .v-dialog .v-textarea .v-input-slot {
  height: 100px !important;
}
#app #users .v-dialog .v-btn {
  letter-spacing: 0.035px;
}
#app #users .dialog-button-group {
  gap: 12px;
}
#app #users .v-tabs {
  overflow-x: auto;
}
@media screen and (max-width: 960px) {
  #app #users .page-header {
    padding: 18px 5px 0px;
  }
  #app #users .page-header section {
    position: absolute;
    top: 27px;
  }
  #app #users .page-header__title {
    margin-top: 13px;
  }
}
#app #variable-groups .main {
  align-items: flex-start;
  gap: var(--16px, 16px);
}
#app #variable-groups .variable-tree {
  align-self: stretch;
  flex-direction: column;
  width: 336px;
  padding: 0 24px;
  background: #ffffff;
  box-shadow: none;
}
#app #variable-groups .variable-form {
  width: calc(100% - 400px);
  min-width: 300px;
  border-radius: var(--12px, 12px);
  background: #ffffff;
}
#app #variable-groups .variable-form label,
#app #variable-groups .variable-form .item-border__title {
  font-size: 0.75rem !important;
}
#app #variable-groups .variable-form .v-input.v-text-field {
  margin: 24px 0;
}
#app #variable-groups .variable-form .subtitle-1 {
  line-height: 24px;
}
#app #variable-groups .variable-form table {
  table-layout: fixed;
}
#app #variable-groups .variable-form table thead tr th {
  padding-bottom: 10px;
  padding-top: 0;
}
#app #variable-groups .variable-form table thead tr th:first-child,
#app #variable-groups .variable-form table thead tr th:last-child {
  width: 18px;
}
#app #variable-groups .variable-form table tbody tr {
  margin-bottom: 18px;
}
#app #variable-groups .variable-form table tbody tr td:not(:first-child) {
  padding: 8px 4px;
}
#app #variable-groups .variable-form table tbody tr td .v-input.v-text-field {
  margin: 0;
}
#app #variable-groups .variable-form table tbody tr td {
  padding: 32px 4px 8px 4px;
}
#app #variable-groups .variable-form .variables-field {
  position: relative;
  height: 162px;
}
#app #variable-groups .v-data-table__checkbox.v-simple-checkbox {
  display: none !important;
}
#app #variable-groups .variable-card {
  padding: 0;
  overflow-y: scroll;
  box-shadow: none;
  right: 7px;
}
#app #variable-groups #variables-available-table table {
  table-layout: fixed;
}
#app #variable-groups #variables-available-table table thead {
  display: none;
}
#app #variable-groups #variables-available-table table tbody tr td {
  padding: 12px 0;
  text-overflow: ellipsis;
  line-height: 20px;
}
#app #variable-groups #variables-available-table table tbody tr td .v-input {
  margin: 0;
  padding: 0;
}
#app #variable-groups #variables-available-table table tbody tr td .no-wrap {
  white-space: nowrap;
}
#app #variable-groups #variables-available-table table tbody tr td:first-child {
  width: 32px;
}
#app #variable-groups #variables-available-table table tbody tr td:nth-child(3) {
  width: 36px;
}
#app #variable-groups #variables-available-table table tbody tr td:has(> .primary--text) + td > div {
  color: #526bc7 !important;
}
#app #variable-groups #variables-available-table .dark-grey-overrideable-text {
  color: #757575;
}
#app #variable-groups #variables-available-table .darker-grey--text {
  color: #616161 !important;
}
#app #variable-groups #variables-available-table .variable-name {
  width: 420px;
}
#app #variable-groups #variables-available-table .variable-group {
  width: 32px;
}
#app #variable-groups #variables-available-table .v-input--checkbox .v-input__slot {
  min-height: 24px !important;
}
#app #variable-groups .v-dialog {
  padding: 0;
}
#app #variable-groups .v-dialog .v-data-table {
  padding: 0 24px;
}
#app #variable-groups .dialog-header {
  height: 60px;
  padding: 14px 16px 10px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
#app #variable-groups .dialog-header h2 {
  width: 497px;
}
#app #variable-groups .dialog-footer {
  padding: 20px 16px 8px 16px;
}
#app #variable-groups .dialog-footer button .v-icon {
  height: 24px;
  width: 24px;
}
#app #variable-groups .rotate {
  transform: rotate(90deg);
}
#app .data-variable h2 {
  line-height: unset;
}
@media screen and (max-width: 960px) {
  #app .data-variable .page-header__title {
    text-align: center;
  }
  #app .data-variable .page-header__title h1 {
    width: 100%;
  }
  #app .data-variable .page-header__title .page-header__subtitle {
    max-width: 100%;
  }
}
#app .data-variable .vue-icon.xx-small {
  height: 15px;
  width: 15px;
}
#app .data-variable .vue-icon.xx-small svg,
#app .data-variable .vue-icon.xx-small path {
  fill: #181c1e !important;
}
#app .data-variable .v-input.search {
  max-width: fit-content;
}
#app .data-variable .v-input.search .v-input__control {
  max-width: fit-content;
}
#app .data-variable .v-input.search .v-input__control .v-input__slot {
  width: auto;
  max-width: 300px;
  min-width: 130px;
}
#app .data-variable .v-input.search .v-input__control .v-input__slot fieldset {
  border: 1px solid #bdbdbd !important;
}
#app .data-variable .v-input.search:hover .v-input__control .v-input__slot fieldset {
  border-color: #526bc7 !important;
}
#app .data-variable .v-input.search.v-input--is-focused .v-input__control .v-input__slot fieldset {
  border-color: #526bc7 !important;
}
#app .data-variable .splitpanes-container {
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
}
#app .data-variable .splitpanes-container .variable-list {
  height: calc(100% - 54px);
}
@media screen and (max-width: 1350px) and (min-width: 960px) {
  #app .data-variable .splitpanes-container .variable-list {
    height: calc(100% - 92px);
  }
}
#app .data-variable .splitpanes-container .variable-list.get-data tbody tr {
  cursor: pointer;
}
#app .data-variable .splitpanes-container .categories-list {
  height: calc(100% - 46px);
}
#app .data-variable .splitpanes-container .categories-list thead th:nth-child(2) {
  padding-left: 0;
}
#app .data-variable .splitpanes-container .categories-list thead th:last-child {
  min-width: 52px;
}
#app .data-variable .splitpanes-container .categories-list tbody td .v-data-table__icon-move {
  margin-right: 0 !important;
}
#app .data-variable .splitpanes-container .categories-list tbody td:nth-child(2) {
  padding-left: 0;
}
#app .data-variable .splitpanes-container .categories-list tbody td:not(:first-child):not(:last-child) {
  min-width: 150px;
}
#app .data-variable .splitpanes-container .weights-content tbody td {
  min-width: 150px;
}
#app .data-variable .splitpanes-container .weights-content tbody td:last-child {
  padding: 0;
  min-width: unset;
}
#app .data-variable .splitpanes-container .frequencies-content tbody td {
  min-width: 150px;
}
#app .data-variable .splitpanes-container .variable-information {
  height: calc(100% - 45px);
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#app .data-variable .splitpanes-container .variable-information::-webkit-scrollbar {
  display: none;
}
#app .data-variable .splitpanes-container .pane-content {
  border-radius: 8px;
  border: 1px solid rgba(82, 107, 199, 0.2);
  padding: 12px 12px 0;
  background-color: #ffffff;
}
#app .data-variable .splitpanes-container .pane-content .v-window {
  height: calc(100% - 92px);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#app .data-variable .splitpanes-container .pane-content .v-window::-webkit-scrollbar {
  display: none;
}
#app .data-variable .splitpanes-container .v-tab {
  padding: 0 24px;
}
@media screen and (max-width: 600px) {
  #app .data-variable .splitpanes-container .v-tabs-items .w-33 {
    width: 50%;
  }
}
#app .data-variable .splitpanes-container .v-data-table table tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}
#app .data-variable .splitpanes-container .v-data-table table thead [aria-label=actions] {
  min-width: 50px;
}
#app .data-variable .splitpanes-container .v-input.v-input--checkbox .v-input__slot .v-label {
  transform: unset;
}
@media screen and (max-width: 1350px) and (min-width: 960px) {
  #app .data-variable .slot-top.has-search {
    flex-wrap: wrap;
  }
  #app .data-variable .slot-top.has-search > div:nth-child(2) {
    order: 3;
    max-width: 100%;
    width: 100%;
    margin-left: 0 !important;
    margin-top: 8px;
  }
  #app .data-variable .slot-top.has-search > div:nth-child(2) .v-input__control {
    max-width: 100%;
    width: 100%;
  }
  #app .data-variable .slot-top.has-search > div:nth-child(2) .v-input__control .v-input__slot {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
  #app .data-variable .slot-top.has-search > div:nth-child(3) {
    order: 2;
  }
}
@media screen and (max-width: 600px) {
  #app .data-variable .slot-top.has-search {
    flex-wrap: wrap;
  }
  #app .data-variable .slot-top.has-search > div:nth-child(2) {
    order: 3;
    max-width: 100%;
    width: 100%;
    margin-left: 0 !important;
    margin-top: 8px;
  }
  #app .data-variable .slot-top.has-search > div:nth-child(2) .v-input__control {
    max-width: 100%;
    width: 100%;
  }
  #app .data-variable .slot-top.has-search > div:nth-child(2) .v-input__control .v-input__slot {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
  #app .data-variable .slot-top.has-search > div:nth-child(3) {
    order: 2;
  }
}
#app .data-variable .slot-top .v-btn--icon {
  width: 28px !important;
  height: 28px !important;
}
#app .data-variable .slot-top .v-btn--icon .vue-icon {
  width: 20px;
  height: 20px;
}
#app .data-variable .slot-top .v-btn--icon .vue-icon-trash-dark:after {
  width: 28px;
  height: 28px;
}
#app .data-variable .v-data-table tbody tr {
  cursor: default;
}
#app .data-variable .v-data-table tbody tr td {
  min-width: unset;
}
@media screen and (max-width: 960px) {
  #app .data-variable .v-data-table tbody tr td:not(:first-child):not(:last-child) {
    min-width: 150px;
  }
}
#app .data-variable .v-expansion-panel .v-expansion-panel-header {
  min-height: unset;
}
#app .data-variable .v-expansion-panel.no-shadow::before {
  box-shadow: none;
}
#app .data-variable .v-expansion-panel .v-expansion-panel-content__wrap {
  background-color: unset;
  border-radius: unset;
  padding: unset;
  margin: unset;
}
#app .data-variable .missing-table tbody td:last-child {
  width: 5%;
  padding-left: 0;
}
#app #variables .variable-groups__content--form {
  width: 100%;
}
#app #variables .variable-groups .v-select .v-messages,
#app #variables .variable-groups .v-select .v-text-field__details,
#app #variables .variable-groups .v-input .v-messages,
#app #variables .variable-groups .v-input .v-text-field__details {
  display: none;
}
#app #variables .variable-groups .v-select--selection-controls,
#app #variables .variable-groups .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
#app #variables .variable-groups--concepts {
  border-top: 1px solid #ededed;
  margin-top: 14px;
  padding-top: 14px;
}
#app #variables .variable-groups--concepts .v-data-table:not(.v-data-table--selectable) tbody .v-data-table__icon-move {
  margin-right: 0 !important;
}
#app #variables .variable-groups--concepts .btn-add-row {
  padding-left: 31px !important;
  padding-right: 46px !important;
}
#app #variables .variable-groups--concepts .v-data-table__checkbox.v-simple-checkbox {
  display: none !important;
}
#app #variables .variable-groups--concepts table thead {
  height: auto !important;
}
#app #variables .variable-groups--concepts table thead tr {
  height: auto !important;
}
#app #variables .variable-groups--concepts table thead th {
  height: auto !important;
  padding: 10px 4px 20px;
}
#app #variables .variable-groups--concepts table thead th[aria-label=sortable]:before {
  top: 40%;
}
#app #variables .variable-groups--concepts table tbody tr td {
  padding-right: 4px;
  padding-left: 4px;
}
#app #variables .variable-groups--concepts table tbody tr td:first-child {
  min-width: 27px !important;
  padding: 0;
  width: 27px;
}
#app #variables .variable-groups--concepts table tbody tr td:last-child {
  min-width: 42px !important;
  padding: 0;
  width: 42px;
}
#app #variables .variable-groups--dialog--selection {
  max-height: 300px;
  overflow-y: auto;
}
#app #variables .variable-groups--dialog--selection--group {
  color: #424242;
  font-size: 0.875rem;
  font-weight: 700;
}
#app #variables .variable-groups--dialog--selection .v-input--selection-controls {
  margin: 0;
  padding: 0;
  height: 48px;
}
#app #variables .variable-groups--dialog--selection .v-messages {
  display: none;
}
#app #variables .variable-groups--dialog--selection .v-input {
  width: calc(100% - 60px);
}
#app #variables .variable-groups--dialog--selection .v-input__slot {
  min-height: 48px !important;
}
#app #variables .variable-groups--dialog--selection .v-input label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#app #variables .variable-groups .btn-add-row {
  padding-left: calc(5% + 4px);
  padding-right: calc(5% + 4px);
}
#app #variables .variable-groups .btn-add-row button {
  background-color: #ffffff !important;
}
#app .identification__ident thead .text-start:nth-child(2) {
  padding-left: 20px !important;
}
#app .identification__ident tbody td:nth-child(2) {
  padding-left: 20px !important;
}
#app .identification__ident .btn-add-row {
  padding-left: 63px !important;
  padding-right: 57px !important;
}
#app .identification .v-data-table tbody tr {
  cursor: default;
}
#app .identification .v-data-table tbody tr td {
  min-width: unset;
  padding: 6px 8px;
}
#app .identification .v-data-table tbody tr td:first-child {
  padding-right: 0;
}
@media screen and (max-width: 960px) {
  #app .identification .v-data-table tbody tr td:not(:first-child):not(:last-child) {
    min-width: 150px;
  }
}
#app .identification .v-data-table tbody tr td .v-data-table__icon-move {
  margin: 0 !important;
}
#app .identification .v-data-table thead .text-start {
  padding-left: 8px;
}
#app .identification .v-data-table thead .text-start[aria-label=actions] {
  min-width: 50px;
}
#app .identification .v-data-table thead [aria-label=sortable]:before {
  left: 13px;
}
#app .identification .v-data-table .btn-add-row {
  padding-left: calc(5% + 0.5rem);
  padding-right: calc(5% + 0.5rem);
}
#app .identification .v-menu__content {
  margin-top: 10px;
}
#app .identification .v-menu__content.v-menu--top {
  margin-top: -15px;
}
#app .identification div[role=dialog] .v-dialog {
  width: auto;
}
#app .identification div[role=dialog] .v-dialog .v-date-picker-header button {
  width: auto;
}
#app .identification div[role=dialog] .v-dialog .v-picker__actions button {
  width: auto;
}
#app .data-files .v-data-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#app #dashboard .dashboard__title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: #424242;
}
#app #dashboard .dashboard__section__chart {
  display: flex;
  gap: 24px;
}
#app #dashboard .dashboard__section__chart--publications {
  width: 300px;
}
#app #dashboard .dashboard__section__chart--publications #custom-text {
  transform: translate(-50%, -90%) !important;
}
#app #dashboard .dashboard__section__chart--publications .apexcharts-legend {
  display: flex;
  justify-content: space-between;
}
#app #dashboard .dashboard__section__chart--publications .apexcharts-legend-marker {
  width: 8px !important;
  height: 8px !important;
  margin-right: 8px;
}
#app #dashboard .dashboard__section__chart--publications .apexcharts-legend-text {
  color: #424242 !important;
  font-size: 1rem !important;
}
#app #dashboard .dashboard__section__chart--projects {
  width: calc(100% - 300px);
  padding-right: 60px;
}
#app #dashboard .dashboard__section__chart--projects .chart-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
#app #dashboard .dashboard__section__chart--projects #chart {
  transform: translateX(-60px);
}
#app #dashboard .dashboard__section__chart--projects #chart .apexcharts-legend {
  display: none;
}
#app #dashboard .dashboard__section__chart--projects #legend .apexcharts-legend {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  top: 40px;
}
#app #dashboard .dashboard__section__chart--projects #legend .apexcharts-legend-series {
  width: 180px;
  display: flex;
  gap: 8px;
  align-items: center;
}
#app #dashboard .dashboard__section__chart--projects .legend-item-label {
  display: flex;
  gap: 8px;
  align-items: center;
}
#app #dashboard .dashboard__section__chart--projects .legend-item-value {
  color: #bdbdbd;
  font-size: 1rem;
}
#app #dashboard .dashboard__section__chart--projects .apexcharts-legend {
  display: flex;
  justify-content: space-between;
}
#app #dashboard .dashboard__section__chart--projects .apexcharts-legend-marker {
  width: 8px !important;
  height: 8px !important;
  margin-right: 8px;
}
#app #dashboard .dashboard__section__chart--projects .apexcharts-legend-text {
  color: #858585 !important;
  font-size: 0.875rem !important;
}
#app #dashboard #legend {
  width: 100%;
}
#app #dashboard #legend .apexcharts-pie {
  display: none;
}
#app #dashboard #legend .apexcharts-legend {
  position: inherit;
}
#app #chart {
  position: relative;
}
#app #custom-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
#app .chart-title {
  color: #424242;
  font-size: 0.75rem;
}
#app .chart-value {
  color: #424242;
  font-size: 1rem;
}
#app h1,
#app .h1 {
  font-family: "Roboto", sans-serif;
  font-size: 34px;
  line-height: 40px;
  font-weight: 500;
  color: #424242;
}
#app h2,
#app .h2 {
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
  color: #181c1e;
}
#app h3,
#app .h3 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #424242;
}
#app h4,
#app .h4 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  color: #161c1e;
}
#app h5,
#app .h5 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: #161c1e;
}
#app h6,
#app .h6 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  color: #161c1e;
}
#app small,
#app .small {
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  line-height: 1.4;
}
#app a {
  text-decoration: none;
}
#app p {
  line-height: 1.3;
}
#app .subtitle-1 {
  font-weight: 600;
}
#app .subtitle-2 {
  font-weight: 600;
}
#app .text-detail {
  font-size: 0.75rem;
  line-height: 1.25;
  color: #858585;
}
#app .font-weight-semibold {
  font-weight: 600;
}
#app .font-size-subtitle {
  font-size: 1rem;
}
#app .light-grey--text {
  color: rgba(0, 0, 0, 0.37);
}
#app .onyx-light--text {
  color: rgba(0, 0, 0, 0.87);
}
#app .hr {
  border-top: 2px solid #ededed;
}
#app .w-100 {
  width: 100%;
}
#app .w-75 {
  width: 75%;
}
#app .w-50 {
  width: 50%;
}
#app .w-25 {
  width: 25%;
}
#app .w-33 {
  width: 33.33%;
}
#app .w-auto {
  width: auto;
}
#app .w-fit {
  width: fit-content;
}
#app .w-fill {
  width: -webkit-fill-available;
}
#app .h-fit {
  height: fit-content;
}
#app .h-auto {
  height: auto;
}
#app .h-100 {
  height: 100%;
}
#app .h-fill {
  height: -webkit-fill-available;
}
#app .app-header {
  z-index: 1000;
}
@media screen and (max-width: 960px) {
  #app .app-header {
    padding-top: 24px;
    background-color: #011B53;
  }
}
#app .app-header .v-app-bar.v-app-bar--fixed {
  z-index: 1000;
}
#app .app-header .v-toolbar__content {
  background-color: #0c1a4d;
  color: #f3f5fb;
  justify-content: space-between;
  padding: 0 2rem;
}
@media screen and (max-width: 959px) {
  #app .app-header .v-toolbar__content {
    justify-content: center;
    padding: 0 1rem;
  }
}
#app .app-header .v-toolbar__content .page-link {
  border-right: 1px solid #f3f5fb;
}
#app .app-header .v-toolbar__content .page-link a {
  font-size: 1rem;
  color: #ffffff !important;
}
#app .app-header a {
  color: #ffffff;
}
#app .app-header .v-btn.v-btn--icon:hover {
  background-color: #2E55A0 !important;
}
#app .app-header .mobile-header-menu {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #011B53;
  padding: 0px 8px 24px;
  margin-top: 24px;
}
#app .app-header .mobile-header-menu__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  overflow-y: auto;
}
#app .app-header .mobile-header-menu__body .v-list {
  background-color: #011B53;
}
#app .app-header .mobile-header-menu__body .v-list-item {
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 8px 16px;
}
#app .app-header .mobile-header-menu__body .v-list-item:hover, #app .app-header .mobile-header-menu__body .v-list-item.active {
  background-color: rgba(243, 245, 251, 0.15);
}
#app .app-header .mobile-header-menu__body .v-list-item__icon {
  margin: 14px 8px 0 0;
  min-width: 18px;
}
#app .app-header .mobile-header-menu__body .v-list-item__icon svg {
  width: 18px;
  height: 18px;
}
#app .app-header .mobile-header-menu__body .v-list-item__icon svg path {
  fill-opacity: 1 !important;
}
#app .app-header .mobile-header-menu__body .v-list-item__title,
#app .app-header .mobile-header-menu__body .v-list-item span {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400 !important;
}
#app .app-header .mobile-header-menu__body .v-list-item .language-flag {
  width: 18px !important;
  height: 18px !important;
  border-radius: 100%;
  margin-right: 8px;
}
#app .app-header .mobile-header__btn, #app .app-header .mobile-header__btn--navigation, #app .app-header .mobile-header__btn--setting {
  position: absolute;
  transition: none;
}
#app .app-header .mobile-header__btn i, #app .app-header .mobile-header__btn--navigation i, #app .app-header .mobile-header__btn--setting i {
  color: #ffffff !important;
}
#app .app-header .mobile-header__btn--setting {
  right: 13px;
}
#app .app-header .mobile-header__btn--setting.close {
  z-index: 1005;
}
#app .app-header .mobile-header__btn--navigation {
  left: 16px;
}
#app .app-header .mobile-header__btn.close i, #app .app-header .close.mobile-header__btn--setting i, #app .app-header .close.mobile-header__btn--navigation i {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.875 7.63594C22.3875 7.14844 21.6 7.14844 21.1125 7.63594L15 13.7359L8.88746 7.62344C8.39996 7.13594 7.61246 7.13594 7.12496 7.62344C6.63746 8.11094 6.63746 8.89844 7.12496 9.38594L13.2375 15.4984L7.12496 21.6109C6.63746 22.0984 6.63746 22.8859 7.12496 23.3734C7.61246 23.8609 8.39996 23.8609 8.88746 23.3734L15 17.2609L21.1125 23.3734C21.6 23.8609 22.3875 23.8609 22.875 23.3734C23.3625 22.8859 23.3625 22.0984 22.875 21.6109L16.7625 15.4984L22.875 9.38594C23.35 8.91094 23.35 8.11094 22.875 7.63594Z' fill='%23F3F5FB' style='fill:%23F3F5FB;fill:color(display-p3 0.9547 0.9612 0.9853);fill-opacity:1;'/%3E%3C/svg%3E");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
}
#app .app-header .mobile-header__btn.close svg, #app .app-header .close.mobile-header__btn--setting svg, #app .app-header .close.mobile-header__btn--navigation svg {
  display: none;
}
#app .app-filter {
  position: relative;
}
#app .app-filter__side-bar {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 960px) {
  #app .app-filter .v-card--app-filter {
    width: 100% !important;
    height: calc(100vh - 87px);
    position: fixed;
    top: 87px;
    left: 0;
    z-index: 600;
    opacity: 0;
  }
  #app .app-filter .v-card--app-filter--visible {
    opacity: 1;
    animation: opacity-up 0.3s ease-in-out forwards;
  }
}
#app .app-filter .v-card--app-filter__button-group {
  display: none;
}
@media screen and (max-width: 960px) {
  #app .app-filter .v-card--app-filter__button-group {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0;
    padding: 10px;
    gap: 12px;
    background-color: #F9F9F9;
  }
}
#app .app-filter .v-card--app-filter__button-group .v-btn.primary {
  width: calc(100% - 52px);
}
@keyframes opacity-up {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.LayoutDefault {
  min-height: 100%;
}

.side-content {
  width: calc(100% - 270px);
  padding: 2rem 0;
  margin-left: 270px;
  background-color: #f3f5fb;
}
@media screen and (max-width: 960px) {
  .side-content {
    width: 100%;
    margin: 24px 0 0;
  }
}

.page-header {
  position: sticky;
  top: 50px;
  padding: 32px 0 24px;
  margin: 0;
  background-color: #f3f5fb;
  z-index: 500;
}
.page-header h1 {
  letter-spacing: 0.085px;
}
.page-header::before, .page-header::after {
  content: "";
  position: absolute;
  top: 0;
  width: 2rem;
  height: 100%;
  background-color: #f3f5fb;
  z-index: 400;
}
.page-header.has-alert {
  padding: 32px 0 12px;
}
.page-header::before {
  left: -2rem;
}
.page-header::after {
  right: -2rem;
}
@media screen and (max-width: 960px) {
  .page-header {
    padding: 18px 5px;
    top: 77px;
    gap: 12px;
  }
  .page-header h1 {
    text-align: center;
  }
  .page-header__subtitle {
    text-align: center;
  }
}
.page-header__title {
  font-size: 2rem;
  font-weight: bold;
  width: calc(100% - 185px);
  max-width: 636px;
}
@media screen and (max-width: 960px) {
  .page-header__title {
    min-width: 100%;
  }
}
.page-header__subtitle {
  max-width: 372px;
}
.page-header.scrolling .page-header__title h1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}
.page-header.scrolling .page-header__subtitle {
  max-width: 636px;
}
.page-header .mobile__btn {
  background-color: #526bc7;
}
.page-header .mobile__btn .vue-icon {
  color: #ffffff;
}

.full-page-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  justify-content: center;
  align-items: center;
  display: none;
  transition: all 0.5s linear;
}
.full-page-loading__spinner img {
  object-fit: cover;
}
.full-page-loading--active {
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}

.page-header-menu.v-menu__content {
  position: fixed;
  top: 135px !important;
}