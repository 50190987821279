.v-input--switch {
	&.v-input {
		.v-label {
			transform: inherit !important;
		}
	}
}

.vue-switch {
	position: relative;
	column-gap: 0.5rem;

	@media screen and (max-width: 600px) {
		width: min-content;
	}

	.label {
		align-self: center;
	}

	.v-input--switch {
		margin-top: 0;
		padding-top: 0;

		.v-input--selection-controls__input {
			margin-right: 0;
		}
	}

	&.reverse {
		flex-direction: row-reverse;
	}

	&.expand {
		width: 100%;
		justify-content: space-between;
	}

	&.reverse-expand {
		width: 100%;
		justify-content: space-between;
		flex-direction: row-reverse;
	}

	.v-messages {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
	}
}