.document-description {
  .v-data-table {
    table {
      padding: 0.75rem 0;

      tbody {
        .text-start {
          padding: 18px 20px;
        }
      }

      thead {
        .text-start {
          padding: 0.625rem 1.25rem 1.25rem;
        }
      }
    }
  }

  .w-33 {
    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
}