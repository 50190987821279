.v-menu__content--tree-selection-menu {
  margin-top: 3px;

  .v-treeview .v-treeview-node__level {
    &:first-child {
      width: 0px;
    }
  }

  .v-treeview-node__label {
    color: $grey;
    font-size: $font-size-subtitle;
  }

  .v-treeview>.v-treeview-node>.v-treeview-node__root {
    .v-treeview-node__label {
      font-weight: 600;
      color: $black;
    }
  }
}