.side-navigation {
	background-color: $blue;
	height: calc(100vh - 64px);
	width: 270px !important;
	position: fixed;
	top: 64px !important;
	overflow-y: scroll;
	z-index: 4;

	@media screen and (max-width: 960px) {
		transform: translateX(-100%);
		height: calc(100vh - 87px);
		top: 87px !important;
	}

	&.open {
		@media screen and (max-width: 960px) {
			width: 100% !important;
			transform: translateX(0);
			z-index: 600;
		}
	}

	&__inner {
		padding: 1.25rem;
		height: 98%;

		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			background-color: $blue;
		}

		&::-webkit-scrollbar {
			width: 6px;
			background-color: $blue;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $blue;
		}
	}

	&__header {
		font-weight: $font-semibold;
	}

	.v-icon,
	.vue-icon {
		font-size: 20px;
	}

	.v-treeview-node {
		&__root {
			position: relative;
			padding: 0;

			&::before {
				display: none;
			}
		}

		&__toggle {
			position: absolute;
			right: 0;
			transform: none;

			&:focus {
				&::after {
					display: none;
				}
			}

			&--open {
				transform: rotate(-180deg);
			}
		}

		&__content {
			// font-weight: $font-semibold;
			margin: 0;
			padding: 8px;
			transition: 0.3s ease-in-out;
			border-radius: 8px;
			z-index: 6;
		}

		&__prepend {
			div {
				display: flex;
			}

			&:has(.no-icon) {
				display: none;
			}
		}

		&__label {
			max-width: 170px;
		}

		&__level {
			width: 10px;
			height: 50%;
			position: absolute;
			border-bottom-left-radius: 5px;
			border-top-left-radius: 0;
			top: 2px;
			left: -1px;
			border-right: 0 !important;

			&::after {
				content: "";
				height: calc(50% + 1px);
				width: 10px;
				min-height: unset;
				position: absolute;
				top: -2px;
				left: 0;
				border-bottom-left-radius: 4px;
				border-right: 0;
				border-top: 0;
			}

			&:not(:first-of-type) {
				display: none;
			}
		}

		&__children {
			.v-treeview-node {
				margin-left: 17px;

				&__content {
					font-weight: 400;
					margin-left: 8px;

					.v-icon,
					.vue-icon {
						font-size: 18px;
					}
				}

				&__root {
					padding: 0;
				}

				&__children {
					.v-treeview-node__level {
						left: -0.8px;
					}
				}
			}
		}
	}

	.v-treeview>.v-treeview-node>.v-treeview-node__children>.v-treeview-node>.v-treeview-node__children>.v-treeview-node:last-child {
		.v-treeview-node__level {
			left: 0;
			top: 0;
		}
	}

	//Theme
	&.blue {
		$font-color-blue: $primary-lighter;
		$font-color-active: $white;
		color: $font-color-blue;

		.v-icon,
		.vue-icon {
			color: $font-color-blue;
		}

		.header-icon {
			color: $font-color-active;
		}

		//Button Group
		.v-btn.v-btn--icon:not(.v-treeview-node__toggle) {
			&:hover {
				color: $font-color-active !important;
				background-color: $chinese-blue !important;

				~small {
					transition: 0.4s ease-in-out;
					color: $font-color-active;
				}
			}

			&.btn-active {
				color: $font-color-active !important;
				background-color: $chinese-blue !important;
				border: 1px solid $font-color-active;

				svg,
				path {
					fill: $font-color-active !important;
				}

				~small {
					transition: 0.4s ease-in-out;
					color: $font-color-active;
				}
			}
		}

		.v-input {

			.v-icon,
			.vue-icon {
				color: $font-color-blue !important;
			}

			.v-text-field__slot input {
				color: $font-color-active !important;

				&::placeholder {
					color: #5165ae !important;
				}
			}

			&:not(.v-input--is-focused):not(.v-input--has-state) {
				fieldset {
					border: 1px solid $font-color-blue !important;
				}
			}
		}

		.v-treeview-node {
			&__root {
				&:has(.v-treeview-node__toggle--open) {

					.v-treeview-node__content,
					.v-icon,
					.vue-icon {
						color: $font-color-active;
					}
				}
			}

			&__content {
				color: $font-color-blue;

				&:hover {
					background-color: $secondary;
					color: $font-color-active;
					transition: 0.3s ease-in-out;
				}
			}

			&__children {
				.v-treeview-node {
					&__level {
						// border: 0.8px solid $font-color-blue;
						border: 1px solid $font-color-blue;

						&::after {
							background-color: $blue;
						}

						&:not(:first-of-type) {
							display: none;
						}
					}
				}
			}
		}

		.v-treeview>.v-treeview-node:not(:last-child)>.v-treeview-node__children>.v-treeview-node {
			border-left: 1px solid $font-color-blue;

			.v-treeview-node__children {
				.v-treeview-node:not(:last-child) {
					border-left: 1px solid $font-color-blue;
				}
			}
		}
	}

	&.white {
		$font-color-grey: $grey;
		$font-color-active-primary: $primary;
		color: $font-color-grey;

		.v-icon:not(.header-icon),
		.vue-icon:not(.header-icon) {
			color: $font-color-grey;
		}

		.header-icon {
			color: $font-color-active-primary;
		}

		.sidebar-head {
			.v-btn {
				.vue-icon {
					color: $font-color-grey !important;
				}

				&:hover {
					.vue-icon {
						color: $font-color-active-primary !important;
					}
				}
			}

			.text-center .v-btn:hover+small {
				color: $primary !important;
			}

		}

		.vue-icon:not(.header-icon),
		.v-icon:not(.header-icon) {
			color: $font-color-grey;
		}

		.header-icon {
			color: $font-color-active-primary;
		}

		//Button Group
		.v-btn.v-btn--icon:not(.v-treeview-node__toggle) {
			&:hover {
				color: $primary !important;
				background-color: $secondary !important;
				border: 1px solid $primary;
			}
		}

		.v-input {

			.v-icon,
			.vue-icon {
				color: $font-color-grey !important;
			}

			.v-text-field__slot input {
				color: $primary !important;

				&::placeholder {
					color: $light-grey !important;
				}
			}

			&:not(.v-input--is-focused):not(.v-input--has-state) {
				fieldset {
					border: 1px solid $primary !important;
				}

				input::placeholder {
					color: $primary !important;
				}

				.v-icon,
				.vue-icon {
					color: $primary !important;
				}
			}
		}

		.v-treeview-node {
			// font-weight: $font-semibold;

			&__root {
				font-weight: $font-semibold;
				color: $grey;

				&:has(.v-treeview-node__toggle--open) {
					color: $primary;

					&:not(.v-input--is-focused):not(.v-input--has-state) {
						fieldset {
							border: 1px solid $primary !important;
						}

						input::placeholder {
							color: $primary !important;
						}

						.v-icon,
						.vue-icon {
							color: $primary !important;
						}
					}
				}

				&__content {
					color: $font-color-grey;

					&:hover {
						background-color: $secondary;
						color: $font-color-active-primary;
						transition: 0.3s ease-in-out;
					}
				}

				&__level {
					// border: 0.8px solid $font-color-grey;
					border: 1px solid $font-color-grey;

					&::after {
						background-color: $white;
					}

					&:not(:first-of-type) {
						display: none;
					}
				}
			}

			&__children {
				.v-treeview-node {
					&__level {
						border: 1px solid $grey;

						&::after {
							background-color: $white;
						}

						&:not(:first-of-type) {
							display: none;
						}
					}
				}
			}

			&--active {
				color: $primary;
				font-weight: $font-semibold;

				.v-icon,
				.vue-icon {
					color: $primary !important;
				}
			}

			.v-treeview>.v-treeview-node:not(:last-child)>.v-treeview-node__children>.v-treeview-node {
				border-left: 1px solid $primary;

				.v-treeview-node__children {
					.v-treeview-node:not(:last-child) {
						border-left: 1px solid $primary;
					}
				}
			}

		}

		.v-treeview>.v-treeview-node:not(:last-child)>.v-treeview-node__children>.v-treeview-node {
			border-left: 1px solid $font-color-grey;

			.v-treeview-node__children {
				.v-treeview-node:not(:last-child) {
					border-left: 1px solid $font-color-grey;
				}
			}
		}
	}

	.sidebar {
		&-head {
			// padding: 1.25rem;
			margin-bottom: 15px;
		}

		&-body {
			// padding: 0 1.25rem;

			&--tools {
				display: flex;
				justify-content: space-between;
				// gap: 12px;

				// padding: 0 0 0 1.25rem;

				.v-treeview-node__label {
					width: 100%;
					padding-right: 15px;
				}

				.sidebar-body--menu {
					width: 100%;
					max-width: 207px;
				}

				&--buttons {
					display: flex;
					flex-direction: column;
					gap: 4px;
					margin-top: 18px;
					// background-color: red;
					width: 28px;
					// margin-right: 6px;
					margin-right: -12px;

					.v-btn {
						width: 28px;
						height: 28px !important;
						min-width: 28px;
						padding: 0;

						.vue-icon {
							width: 20px;
						}
					}
				}
			}
		}
	}

	&.open {
		@media screen and (max-width: 960px) {
			width: 100% !important;
			transform: translateX(0);
			z-index: 600;

			.sidebar-body--menu {
				max-width: inherit;
			}
		}
	}
	&.active-tools {
		.sidebar-body--tools--buttons {
			.v-btn .vue-icon {
				color: $white !important;
			}
			.v-btn {
				&:hover {
					border: 1px solid $white;
				}
			}
		}
	}
}

.v-overlay {
	z-index: 997;
}

.v-navigation-drawer {
	z-index: 998;

	&__content {
		overflow: visible !important;
	}
}

@media screen and (max-width: 420px) {
	.sidebar-head {
		&--buttons {
			.text-center {
				max-width: 80px;
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.sidebar-head {
		&--buttons {
			margin-bottom: 20px;
		}
	}
}
