.v-dialog {
  padding: 1rem;
  background-color: $white;
  border-radius: 12px;

  button {
    &.v-icon {
      &:focus {
        &:after {
          content: none;
          // background-color: transparent;
        }
      }

      &.v-btn.primary {
        &:not(.v-btn--outlined) {
          i.v-icon {
            color: $white;
          }
        }
      }
    }

    &.v-btn {
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }

  &__content {
    z-index: 1000 !important;

    @media screen and (max-width: 960px) {
      z-index: 998 !important;
    }
  }

  .dialog-button-group {
    button {
      &:nth-child(2) {
        margin-left: 1.25rem;

        @media screen and (max-width: 600px) {
          margin-top: 1.25rem;
          margin-left: 0;
        }
      }
    }

    &.half-width {
      gap: 20px;

      button {
        &:nth-child(2) {
          margin-left: inherit !important;

          @media screen and (max-width: 600px) {
            margin-left: 0 !important;
            margin-top: 0 !important;
          }
        }

        @media screen and (max-width: 600px) {
          width: calc(50% - 10px);
        }
      }
    }
  }

  .vue-icon-close {
    cursor: pointer;
  }
}

.v-dialog.alert {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 14px 10px !important;
  border-radius: $border-radius-root;
  // background-color: rgba(82, 107, 199, 0.1);
  background-color: $light-blue;
  box-shadow: none;

  .v-card {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    border-radius: 0;

    .v-card__text {
      padding: 0;
    }
  }
}

.dialog-import-project {
  .button-list {
    .v-btn {
      justify-content: flex-start;
      height: 48px !important;
      padding: 8px 14px;

      * {
        font-weight: normal;
        color: $grey;
      }

      &--active {
        border: 1px solid $primary;

        * {
          color: $primary;
        }
      }
    }
  }
}

.dialog-login {
  &.v-dialog.alert {
    @media screen and (max-width: 600px) {
      max-width: calc(100% - 64px) !important;
      left: 32px;
      margin: 0;
    }
  }
}

.dialog-add-collection {
  &--select {
    margin-top: 16px;
    display: flex;
    gap: 8px;

    &--input {
      width: calc(100% - 120px) !important;
    }

    &--button {
      width: 120px !important;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;

      &--input {
        width: 100% !important;
      }

      &--button {
        width: 100% !important;
      }
    }

    @media screen and (min-width: 601px) and (max-width: 960px) {
      .v-btn.v-size--default {
        height: 52px !important;
      }
    }
  }

  &--menu {
    margin-top: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
}

.dialog-data-rile-error {
  &--content {
    &--item {
      padding: 2px 0;

      span {
        line-height: normal;
      }
    }
  }
}

.dialog-share-project {
  &__email {
    width: calc(100% - 100px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.v-overlay--active {
  z-index: 600 !important;
}

// .dialog-button-group {
//   gap: .75rem;
// }

@media screen and (max-width: 960px) {
  .v-dialog {
    max-height: calc(100vh - 220px) !important;
    margin-top: 110px !important;
  }

  .v-tree-selection__select-inner .v-select.v-tree-selection .v-input__slot {
    min-height: 44px !important;
  }

  .v-tree-selection__select-inner .v-input__icon--prepend-inner {
    margin-top: 0;
    height: 44px;
  }
}
