#dashboard {
  .dashboard {
    &__title {
      font-size: 20px;
      font-style: normal;
      font-weight: $font-medium;
      color: $grey-arsenic;
    }

    &__section {
      &__chart {
        display: flex;
        gap: 24px;

        &--publications {
          width: 300px;

          #custom-text {
            transform: translate(-50%, -90%) !important;
          }

          .apexcharts-legend {
            display: flex;
            justify-content: space-between;

            &-marker {
              width: 8px !important;
              height: 8px !important;
              margin-right: 8px;
            }

            &-text {
              color: $grey-arsenic !important;
              font-size: $font-size-subtitle !important;
            }
          }
        }

        &--projects {
          width: calc(100% - 300px);
          padding-right: 60px;

          .chart-container {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
          }

          #chart {
            transform: translateX(-60px);

            .apexcharts-legend {
              display: none;
            }
          }

          #legend {
            .apexcharts-legend {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 10px;
              top: 40px;

              &-series {
                width: 180px;
                display: flex;
                gap: 8px;
                align-items: center;
              }
            }
          }

          .legend-item-label {
            display: flex;
            gap: 8px;
            align-items: center;
          }

          .legend-item-value {
            color: $light-grey;
            font-size: $font-size-subtitle;
          }

          .apexcharts-legend {
            display: flex;
            justify-content: space-between;

            &-marker {
              width: 8px !important;
              height: 8px !important;
              margin-right: 8px;
            }

            &-text {
              color: $grey !important;
              font-size: $font-size-root !important;
            }
          }
        }
      }
    }
  }

  #legend {
    width: 100%;

    .apexcharts-pie {
      display: none;
    }

    .apexcharts-legend {
      position: inherit;
      // width: 100%;
    }
  }
}

#chart {
  position: relative;
}

#custom-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart-title {
  color: $grey-arsenic;
  font-size: $font-size-sm;
}

.chart-value {
  color: $grey-arsenic;
  font-size: $font-size-subtitle;
}