.w {
  &-100 {
    width: 100%;
  }

  &-75 {
    width: 75%;
  }

  &-50 {
    width: 50%;
  }

  &-25 {
    width: 25%;
  }

  &-33 {
    width: 33.33%;
  }

  &-auto {
    width: auto;
  }

  &-fit {
    width: fit-content;
  }

  &-fill {
    width: -webkit-fill-available;
  }
}

.h {
  &-fit {
    height: fit-content;
  }

  &-auto {
    height: auto;
  }

  &-100 {
    height: 100%;
  }

  &-fill {
    height: -webkit-fill-available;
  }
}