.app-filter {
  position: relative;

  &__side-bar {
    position: absolute;
    top: 0;
    left: 0;
  }

  .v-card--app-filter {
    @media screen and (max-width: 960px) {
      width: 100% !important;
      // height: calc(100% - 87px);
      height: calc(100vh - 87px);
      position: fixed;
      top: 87px;
      left: 0;
      z-index: 600;
      opacity: 0;

      &--visible {
        opacity: 1;
        animation: opacity-up 0.3s ease-in-out forwards;
      }
    }

    &__button-group {
      display: none;

      @media screen and (max-width: 960px) {
        display: flex;
        position: absolute;
        width: 100%;
        bottom: 0px;
        left: 0;
        padding: 10px;
        gap: 12px;
        background-color: $soft-grey;
      }

      .v-btn.primary {
        width: calc(100% - 52px);
      }
    }
  }
}

@keyframes opacity-up {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}