.login-form {
  max-width: 390px;
  margin: 0 auto;
  text-align: center;
  height: 100%;

  &__content {
    width: 100%;
  }

  .text-forgot-password {
    font-weight: 500;
    font-size: $font-size-root;
  }

  h1 {
    color: $grey-arsenic;
  }

  .v-input__password {
    .v-icon {
      color: rgb($grey, 0.5216);
    }
  }
}

.dialog-login.alert {
  padding: 1rem !important;
  line-height: 1.4;
  right: 7px;
}

@media screen and (max-width: 395px) {
  .dialog-login.alert {
    min-width: calc(100% - 16px);
    left: 8px !important;
    margin: 0 !important;
    transform-origin: center;
  }
}