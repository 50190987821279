#collections {
  .vue-tabs {
    margin-bottom: -6px; /* Counter the 6px height of the tabs scrollbar */
  }
  .collections {
    &__list {
      .v-data-table {
        &__wrapper {
          position: relative;
          table {
            border-collapse: separate;
            border-spacing: 0 4px;

            thead {
              th {
                padding-top: 0;
                padding-bottom: 0;

                &:first-child {
                  width: 20px;
                  min-width: 20px;
                  margin: 0;
                  padding: 0 8px 0 24px;
                }

                &:nth-child(2) {
                  padding: 0 16px 0 0;
                }

                &:last-child {
                  width: 20px;
                  min-width: 20px;
                  margin: 0;
                  padding: 0 24px 0 8px;
                }
              }
            }

            tbody {
              tr {
                td {
                  padding-top: 14px;
                  padding-bottom: 13px;
                  min-height: 68px;
                  height: 100%;

                  &:first-child {
                    width: 20px;
                    min-width: 20px;
                    padding: 0 8px 0 24px;
                  }

                  &:nth-child(2) {
                    padding-left: 0;
                  }

                  &:last-child {
                    width: 20px;
                    min-width: 20px;
                    padding: 0 24px 0 8px;
                  }
                }
              }

              tr.v-data-table__selected:not(:hover) {
                background-color: unset;
              }
            }
          }
        }

        &--item {
          &__name {
            display: flex;
            align-items: center;
            gap: 16px;

            &__icon {
              background-color: $secondary;
              width: 40px;
              min-width: 40px;
              height: 40px;
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &__title {
              color: $eerie-black;
              font-size: $font-size-root;
            }

            &__subtitle {
              color: $grey;
              font-size: $font-size-sm;
            }
          }
        }
      }
    }
  }
  .collection-options-menu {
    margin: 0;
    display: flex;
    gap: 10px;
    box-shadow: none;
    .vue-icon {
      line-height: 20px;
      padding: 5px;
      border-radius: 8px;
      &:hover {
        background-color: $light-blue;
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: 960px) {
    .page-header {
      padding: 18px 0 0;
      margin-bottom: 8px;
      section {
        position: absolute;
        top: 27px;
      }
      &__title {
        margin-top: 13px;
      }
    }
  }
}
