.v-tabs {
  // min-width: 532px;
  overflow-x: scroll;

  &.v-tabs--md {
    .v-tab {
      padding-left: 19px;
      padding-right: 23px;
    }
  }

  .v-tabs-bar {
    background: transparent;
    height: 40px;
  }

  .v-tab {
    letter-spacing: 0.035px;
  }

  .v-slide-group__wrapper .v-tab {
    text-transform: initial;
  }

  &:not(.v-tabs--vertical).v-tabs--right>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next,
  &:not(.v-tabs--vertical):not(.v-tabs--right)>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none;
  }

  .v-slide-group__prev.v-slide-group__prev--disabled,
  .v-slide-group__next.v-slide-group__next--disabled {
    display: none;
  }

  &--bold {
    .tabs--title {
      font-weight: $font-bold;
    }
  }

  .v-tabs-bar.v-tabs-bar--show-arrows {

    .v-slide-group__next,
    .v-slide-group__prev {
      min-width: 40px;
      color: $black;

      .v-icon {
        color: inherit;
      }

      &.v-slide-group__prev--disabled,
      &.v-slide-group__next--disabled {
        color: $grey;
        display: none;
        // opacity: 0;
      }
    }
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}