:root {
  --icon-cursor: pointer;
  --icon-size: 24px;
  --icon-size-small: 20px;
  --icon-size-x-small: 15px;
  --icon-margin: 0;
  --icon-color: transparent;
  --icon-stroke-width: 1px;
}

.vue-icon {
  display: inline-flex;
  align-items: center;
  // margin-top: -2px;
  // svg {
  //   width: inherit;
  //   height: inherit;
  // }

  $font-size-icons: 24px;
  $font-size-icons-small: 20px;
  $font-size-icons-x-small: 15px;
  $icon-stroke-width: 1px;

  &.small {
    height: var(--icon-size-small, #{$font-size-icons-small});
    width: var(--icon-size-small, #{$font-size-icons-small});

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &.x-small {
    height: var(--icon-size-x-small, #{$font-size-icons-x-small});
    width: var(--icon-size-x-small, #{$font-size-icons-x-small});

    svg {
      height: 15px;
      width: 15px;
    }
  }

  svg,
  path {
    transition: fill 0.1s;
    fill: currentColor !important;
  }

  &.vue-icon-source {
    rect {
      fill: transparent !important;
    }
  }

  &.vue-icon-trash-dark {
    position: relative;
    cursor: pointer;

    svg {
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      width: 180%;
      height: 180%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
      transition: backgroundColor 0.25s;
      border-radius: 8px;
      z-index: 0;
    }

    &:hover {

      svg,
      path {
        fill: $red !important;
      }

      &:after {
        background-color: $pink;
      }
    }
  }

  &.vue-icon-close {
    cursor: pointer;

    // svg,
    // path {
    // fill: $grey !important;
    // }
  }

  &.vue-icon-sync-alt {
    &.draggable {
      transform: rotate(90deg);

      svg,
      path {
        fill: $light-grey !important;
      }
    }
  }

  &.vue-icon-cloud-upload {

    svg,
    path {
      fill: $white !important;
    }
  }

  &.vue-icon-add {
    cursor: pointer;
  }
}