.data {
  .v-data-table {
    tbody {
      tr td {
        &:first-child {
          min-width: unset;
        }

        &:nth-child(4),
        &:nth-child(8),
        &:nth-child(9) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
      }
    }
  }
}