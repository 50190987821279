.v-progress-linear {
  &__background {
    left: 0 !important;
    border-radius: 1rem;
    width: 100% !important;
  }

  &__determinate {
    border-radius: 1rem !important;
  }
}