#editor {
  .page-header {
    padding: 32px 0 24px;
  }

  .project-editor {
    &--export {
      &__content {
        padding: 100px 0;
        .export-file {
          &__description {
            line-height: 20px;
          }
          &__icon {
            height: 112px;
          }
        }
      }
    }

    &--import {
      &__content {
        padding: 20px 20px 20px 20px;

        .upload {
          &--selected {
            margin: 0 !important;
          }
          .drop-zone {
            &__format {
              padding: 0 calc((100% - 341px) / 2);
            }
          }
        }
      }

      &__info {
        &--title {
          font-size: $font-size-subtitle;
          font-weight: $font-semibold;
          color: $grey-arsenic;
        }

        &--content {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          font-size: $font-size-root;
          color: $grey;

          .v-input--selection-controls {
            margin-top: initial;
            padding-top: initial;
          }

          .v-input__slot {
            margin-bottom: 0;
            .v-label {
              transform: none;
            }
            .v-input--selection-controls {
              &__input {
                margin-right: 12px;
              }
            }
          }

          .v-messages {
            display: none;
          }

          v-icon:after {
            width: auto;
          }

          .mdi:before {
            line-height: 20px;
            font: normal normal normal 20px / 1 "Material Design Icons";
          }
        }
      }
    }

    .has-checkbox {
      .upload--selected {
        li:last-child {
          padding-bottom: 24px;
          border-bottom: 1px solid $transparent-grey;
        }
      }
    }
  }
  .dialog-button-group {
    gap: 12px;
  }

  @media screen and (max-width: 960px) {
    .page-header {
      padding: 18px 0;
      section {
        position: absolute;
        top: 27px;
      }

      &__title {
        margin-top: 13px;
      }
    }
  }
}
